import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Checkbox,
  Button
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import _, { truncate } from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import { Link } from 'react-router-dom'
import { USER_PROFILE } from 'routes/routes'

import educationLevels from 'assets/education-level.json'
import schoolType from 'assets/type-of-school.json'
import schools from 'assets/schools.json'
import gradeLevels from 'assets/level-grade.json'
import yearLevels from 'assets/level-year.json'

import { OthersField } from 'components/profile/others'
import { InputText } from 'components/profile/inputText'

class SchoolsAcademic extends React.Component {
  render() {
    const {
      currentUser,
      handleChange,
      handleCheckboxChange,
      handleAutoCompleteChange,
      handleBlurInput,
      level,
      othersLevel,
      showOthersLevel,
      othersSchool,
      showOthersSchool,
      school,
      gradeLevel,
      yearLevel,
      typeOfSchool,
      othersTypeOfSchool,
      showOthersTypeOfSchool,
      studyMediumEng,
      studyMediumThai,
      studyingAtOtherSchool,
      studyingAtOtherSchoolName,
      gpaEngBox,
      gpaThaiBox,
      gpaMathsBox,
      gpaPhysBox,
      gpaChemBox,
      gpaBioBox,
      gpaGeoBox,
      gpaHistBox,
      gpaLitBox,
      gpaSSBox,
      gpaPolBox,
      gpaEcoBox,
      gpaArtBox,
      gpaFLBox,
      gpaOthersBox,
      gpaEng,
      gpaThai,
      gpaMaths,
      gpaPhys,
      gpaChem,
      gpaBio,
      gpaGeo,
      gpaHist,
      gpaLit,
      gpaSS,
      gpaPol,
      gpaEco,
      gpaArt,
      gpaFL,
      gpaOthersField,
      favSubjEngBox,
      favSubjThaiBox,
      favSubjMathsBox,
      favSubjPhysBox,
      favSubjChemBox,
      favSubjBioBox,
      favSubjGeoBox,
      favSubjHistBox,
      favSubjLitBox,
      favSubjSSBox,
      favSubjPolBox,
      favSubjEcoBox,
      favSubjArtBox,
      favSubjFLBox,
      favSubjOthersBox,
      favSubjOthersBoxField,
      weakestSubjEngBox,
      weakestSubjThaiBox,
      weakestSubjMathsBox,
      weakestSubjPhysBox,
      weakestSubjChemBox,
      weakestSubjBioBox,
      weakestSubjGeoBox,
      weakestSubjHistBox,
      weakestSubjLitBox,
      weakestSubjSSBox,
      weakestSubjPolBox,
      weakestSubjEcoBox,
      weakestSubjArtBox,
      weakestSubjFLBox,
      weakestSubjOthersBox,
      weakestSubjOthersBoxField,
      extExamSAT,
      extExamGRE,
      extExamIELTS,
      extExamTOEFL,
      extExamGMAT,
      extExamOthers,
      extExamOthersField,
      otherTCcourseSAT,
      otherTCcourseGRE,
      otherTCcourseIELTS,
      otherTCcourseTOEFL,
      otherTCcourseGMAT,
      otherTCcourseEnglish,
      otherTCcourseThai,
      otherTCcourseMath,
      otherTCcoursePhysics,
      otherTCcourseBiology,
      otherTCcourseChemistry,
      otherTCcourseGeography,
      otherTCcourseHistory,
      otherTCcourseLiterature,
      otherTCcourseSocialStudies,
      otherTCcoursePolitics,
      otherTCcourseEconomics,
      otherTCcourseArt,
      otherTCcourseForeignLanguage,
      otherTCcourseOthers,
      otherTCcourseOthersField,
      studyingAtEPA,
      coursesTakenAtEpaSAT,
      coursesTakenAtEpaGRE,
      coursesTakenAtEpaIELTS,
      coursesTakenAtEpaTOEFL,
      coursesTakenAtEpaGMAT,
      coursesTakenAtEpaEnglish,
      coursesTakenAtEpaThai,
      coursesTakenAtEpaMath,
      coursesTakenAtEpaPhysics,
      coursesTakenAtEpaBiology,
      coursesTakenAtEpaGeography,
      coursesTakenAtEpaChemistry,
      coursesTakenAtEpaHistory,
      coursesTakenAtEpaLiterature,
      coursesTakenAtEpaSocialStudies,
      coursesTakenAtEpaPolitics,
      coursesTakenAtEpaEconomics,
      coursesTakenAtEpaArt,
      coursesTakenAtEpaForeignLanguage,
      coursesTakenAtEpaOthers,
      coursesTakenAtEpaOthersField,
      submitChanges
    } = this.props

    const gradesField = [
      {
        check: gpaArtBox,
        nameBox: 'gpaArtBox',
        labelBox: 'Art',
        nameField: 'gpaArt',
        valueField: gpaArt,
        labelField: 'Art GPA'
      },
      {
        check: gpaBioBox,
        nameBox: 'gpaBioBox',
        labelBox: 'Biology',
        nameField: 'gpaBio',
        valueField: gpaBio,
        labelField: 'Biology GPA'
      },
      {
        check: gpaChemBox,
        nameBox: 'gpaChemBox',
        labelBox: 'Chemistry',
        nameField: 'gpaChem',
        valueField: gpaChem,
        labelField: 'Chemistry GPA'
      },
      {
        check: gpaEcoBox,
        nameBox: 'gpaEcoBox',
        labelBox: 'Economics',
        nameField: 'gpaEco',
        valueField: gpaEco,
        labelField: 'Economics GPA'
      },
      {
        check: gpaEngBox,
        nameBox: 'gpaEngBox',
        labelBox: 'English',
        nameField: 'gpaEng',
        valueField: gpaEng,
        labelField: 'English GPA'
      },
      {
        check: gpaFLBox,
        nameBox: 'gpaFLBox',
        labelBox: 'Foreign Language',
        nameField: 'gpaFL',
        valueField: gpaFL,
        labelField: 'Foreign Language GPA'
      },
      {
        check: gpaGeoBox,
        nameBox: 'gpaGeoBox',
        labelBox: 'Geography',
        nameField: 'gpaGeo',
        valueField: gpaGeo,
        labelField: 'Geography GPA'
      },
      {
        check: gpaHistBox,
        nameBox: 'gpaHistBox',
        labelBox: 'History',
        nameField: 'gpaHist',
        valueField: gpaHist,
        labelField: 'History GPA'
      },
      {
        check: gpaLitBox,
        nameBox: 'gpaLitBox',
        labelBox: 'Literature',
        nameField: 'gpaLit',
        valueField: gpaLit,
        labelField: 'Literature GPA'
      },
      {
        check: gpaMathsBox,
        nameBox: 'gpaMathsBox',
        labelBox: 'Math',
        nameField: 'gpaMaths',
        valueField: gpaMaths,
        labelField: 'Mathematics GPA'
      },
      {
        check: gpaPhysBox,
        nameBox: 'gpaPhysBox',
        labelBox: 'Physics',
        nameField: 'gpaPhys',
        valueField: gpaPhys,
        labelField: 'Physics GPA'
      },
      {
        check: gpaPolBox,
        nameBox: 'gpaPolBox',
        labelBox: 'Politics',
        nameField: 'gpaPol',
        valueField: gpaPol,
        labelField: 'Politics GPA'
      },
      {
        check: gpaSSBox,
        nameBox: 'gpaSSBox',
        labelBox: 'Social Studies',
        nameField: 'gpaSS',
        valueField: gpaSS,
        labelField: 'Social Studies GPA'
      },
      {
        check: gpaThaiBox,
        nameBox: 'gpaThaiBox',
        labelBox: 'Thai',
        nameField: 'gpaThai',
        valueField: gpaThai,
        labelField: 'Thai GPA'
      },
      {
        check: gpaOthersBox,
        nameBox: 'gpaOthersBox',
        labelBox: gpaOthersField !== '' ? gpaOthersField : 'Others',
        nameField: 'gpaOthersField',
        valueField: gpaOthersField,
        labelField: 'Please specify other grade GPA'
      }
    ]

    const externalExamsTaken = [
      { check: extExamSAT, name: 'extExamSAT', label: 'SAT' },
      { check: extExamGRE, name: 'extExamGRE', label: 'GRE' },
      { check: extExamIELTS, name: 'extExamIELTS', label: 'IELTS' },
      { check: extExamTOEFL, name: 'extExamTOEFL', label: 'TOEFL' },
      { check: extExamGMAT, name: 'extExamGMAT', label: 'GMAT' }
    ]

    const favoriteSubjects = [
      { check: favSubjArtBox, name: 'favSubjArtBox', label: 'Art' },
      { check: favSubjBioBox, name: 'favSubjBioBox', label: 'Biology' },
      { check: favSubjChemBox, name: 'favSubjChemBox', label: 'Chemistry' },
      { check: favSubjEcoBox, name: 'favSubjEcoBox', label: 'Economics' },
      { check: favSubjEngBox, name: 'favSubjEngBox', label: 'English' },
      { check: favSubjFLBox, name: 'favSubjFLBox', label: 'Foreign Language' },
      { check: favSubjGeoBox, name: 'favSubjGeoBox', label: 'Geography' },
      { check: favSubjHistBox, name: 'favSubjHistBox', label: 'History' },
      { check: favSubjLitBox, name: 'favSubjLitBox', label: 'Literature' },
      { check: favSubjMathsBox, name: 'favSubjMathsBox', label: 'Mathematics' },
      { check: favSubjPhysBox, name: 'favSubjPhysBox', label: 'Physics' },
      { check: favSubjPolBox, name: 'favSubjPolBox', label: 'Politics' },
      { check: favSubjSSBox, name: 'favSubjSSBox', label: 'Social Studies' },
      { check: favSubjThaiBox, name: 'favSubjThaiBox', label: 'Thai' }
    ]

    const weakestSubjects = [
      { check: weakestSubjArtBox, name: 'weakestSubjArtBox', label: 'Art' },
      { check: weakestSubjBioBox, name: 'weakestSubjBioBox', label: 'Biology' },
      { check: weakestSubjChemBox, name: 'weakestSubjChemBox', label: 'Chemistry' },
      { check: weakestSubjEcoBox, name: 'weakestSubjEcoBox', label: 'Economics' },
      { check: weakestSubjEngBox, name: 'weakestSubjEngBox', label: 'English' },
      { check: weakestSubjFLBox, name: 'weakestSubjFLBox', label: 'Foreign Language' },
      { check: weakestSubjGeoBox, name: 'weakestSubjGeoBox', label: 'Geography' },
      { check: weakestSubjHistBox, name: 'weakestSubjHistBox', label: 'History' },
      { check: weakestSubjLitBox, name: 'weakestSubjLitBox', label: 'Literature' },
      { check: weakestSubjMathsBox, name: 'weakestSubjMathsBox', label: 'Mathematics' },
      { check: weakestSubjPhysBox, name: 'weakestSubjPhysBox', label: 'Physics' },
      { check: weakestSubjPolBox, name: 'weakestSubjPolBox', label: 'Politics' },
      { check: weakestSubjSSBox, name: 'weakestSubjSSBox', label: 'Social Studies' },
      { check: weakestSubjThaiBox, name: 'weakestSubjThaiBox', label: 'Thai' }
    ]

    const coursesTakenAtOtherTuition = [
      { check: otherTCcourseSAT, name: 'otherTCcourseSAT', label: 'SAT' },
      { check: otherTCcourseGRE, name: 'otherTCcourseGRE', label: 'GRE' },
      { check: otherTCcourseIELTS, name: 'otherTCcourseIELTS', label: 'IELTS' },
      { check: otherTCcourseTOEFL, name: 'otherTCcourseTOEFL', label: 'TOEFL' },
      { check: otherTCcourseGMAT, name: 'otherTCcourseGMAT', label: 'GMAT' },
      { check: otherTCcourseArt, name: 'otherTCcourseArt', label: 'Art' },
      { check: otherTCcourseBiology, name: 'otherTCcourseBiology', label: 'Biology' },
      { check: otherTCcourseChemistry, name: 'otherTCcourseChemistry', label: 'Chemistry' },
      { check: otherTCcourseEconomics, name: 'otherTCcourseEconomics', label: 'Economics' },
      { check: otherTCcourseEnglish, name: 'otherTCcourseEnglish', label: 'English' },
      { check: otherTCcourseForeignLanguage, name: 'otherTCcourseForeignLanguage', label: 'Foreign Language' },
      { check: otherTCcourseGeography, name: 'otherTCcourseGeography', label: 'Geography' },
      { check: otherTCcourseHistory, name: 'otherTCcourseHistory', label: 'History' },
      { check: otherTCcourseLiterature, name: 'otherTCcourseLiterature', label: 'Literature' },
      { check: otherTCcourseMath, name: 'otherTCcourseMath', label: 'Mathematics' },
      { check: otherTCcoursePhysics, name: 'otherTCcoursePhysics', label: 'Physics' },
      { check: otherTCcoursePolitics, name: 'otherTCcoursePolitics', label: 'Politics' },
      { check: otherTCcourseSocialStudies, name: 'otherTCcourseSocialStudies', label: 'Social Studies' },
      { check: otherTCcourseThai, name: 'otherTCcourseThai', label: 'Thai' }
    ]

    const coursesTakenAtEPA = [
      { check: coursesTakenAtEpaSAT, name: 'coursesTakenAtEpaSAT', label: 'SAT' },
      { check: coursesTakenAtEpaGRE, name: 'coursesTakenAtEpaGRE', label: 'GRE' },
      { check: coursesTakenAtEpaIELTS, name: 'coursesTakenAtEpaIELTS', label: 'IELTS' },
      { check: coursesTakenAtEpaTOEFL, name: 'coursesTakenAtEpaTOEFL', label: 'TOEFL' },
      { check: coursesTakenAtEpaGMAT, name: 'coursesTakenAtEpaGMAT', label: 'GMAT' },
      { check: coursesTakenAtEpaArt, name: 'coursesTakenAtEpaArt', label: 'Art' },
      { check: coursesTakenAtEpaBiology, name: 'coursesTakenAtEpaBiology', label: 'Biology' },
      { check: coursesTakenAtEpaEconomics, name: 'coursesTakenAtEpaEconomics', label: 'Economics' },
      { check: coursesTakenAtEpaEnglish, name: 'coursesTakenAtEpaEnglish', label: 'English' },
      { check: coursesTakenAtEpaChemistry, name: 'coursesTakenAtEpaChemistry', label: 'Chemistry' },
      { check: coursesTakenAtEpaForeignLanguage, name: 'coursesTakenAtEpaForeignLanguage', label: 'Foreign Language' },
      { check: coursesTakenAtEpaGeography, name: 'coursesTakenAtEpaGeography', label: 'Geography' },
      { check: coursesTakenAtEpaHistory, name: 'coursesTakenAtEpaHistory', label: 'History' },
      { check: coursesTakenAtEpaLiterature, name: 'coursesTakenAtEpaLiterature', label: 'Literature' },
      { check: coursesTakenAtEpaMath, name: 'coursesTakenAtEpaMath', label: 'Mathematics' },
      { check: coursesTakenAtEpaPhysics, name: 'coursesTakenAtEpaPhysics', label: 'Physics' },
      { check: coursesTakenAtEpaPolitics, name: 'coursesTakenAtEpaPolitics', label: 'Politics' },
      { check: coursesTakenAtEpaSocialStudies, name: 'coursesTakenAtEpaSocialStudies', label: 'Social Studies' },
      { check: coursesTakenAtEpaThai, name: 'coursesTakenAtEpaThai', label: 'Thai' }
    ]

    return (
      <div className="row">
        <div className="col-md">
          <Paper elevation={2} className={'app-wrapper mb-3'}>
            <h1 id="school-academic">Schools & Academic</h1>
            <div className="row">
              <div className="col-12 col-md-6 mb-3 mt-1">
                <Autocomplete
                  id="combo-box-demo"
                  name="school"
                  options={schools}
                  onChange={(event, value) => {
                    if (value !== null) handleAutoCompleteChange(event, value)
                  }}
                  getOptionLabel={option => option?.name || ''}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderInput={params => <TextField {...params} label="Choose a school" />}
                />
              </div>

              <div className="col-12 col-md-6 my-3">
                {showOthersSchool && (
                  <InputText
                    name="othersSchool"
                    defaultValue={othersSchool}
                    className="ml-2 mb-4 mb-md-0"
                    placeholder="Please specify other school"
                    disabled={school === 'others' && true}
                    helperText="Current School"
                    onBlur={handleBlurInput.bind(this)}
                  />
                )}
                {school !== 'others' && (
                  <TextField
                    type="text"
                    fullWidth
                    name="othersSchool"
                    defaultValue={school}
                    className="ml-2 mb-4 mb-md-0"
                    helperText="Current School"
                    disabled={true}
                  />
                )}
              </div>

              <div className="col-6 mb-3">
                <div className="row">
                  <div className="col-auto">
                    <FormControl>
                      <FormLabel component="legend">Level</FormLabel>
                      <Select value={level} onChange={handleChange} name="level">
                        {educationLevels.map(level => {
                          return (
                            <MenuItem key={level.code} value={level.code}>
                              {level.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                      <FormHelperText>
                        Grade/ Year in School starting from January {moment().format('YYYY')}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div className="col-auto mt-4">
                    {level === 'grade' && (
                      <Select value={gradeLevel} onChange={handleChange} name="gradeLevel">
                        {gradeLevels.map(level => {
                          return (
                            <MenuItem key={level.code} value={level.code}>
                              {level.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                    {level === 'year' && (
                      <Select value={yearLevel} onChange={handleChange} name="yearLevel">
                        {yearLevels.map(level => {
                          return (
                            <MenuItem key={level.code} value={level.code}>
                              {level.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                    {showOthersLevel && (
                      <InputText
                        placeholder="Please specify other level"
                        name="othersLevel"
                        defaultValue={othersLevel}
                        className="ml-2"
                        onBlur={handleBlurInput.bind(this)}
                      />
                    )}{' '}
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3">
                {showOthersSchool && (
                  <>
                    <div className="row">
                      <div className="col-auto ml-2">
                        <FormControl>
                          <FormLabel component="legend">Type of School</FormLabel>
                          <Select value={typeOfSchool} onChange={handleChange} name="typeOfSchool">
                            {schoolType.map(typeOfSchool => {
                              return (
                                <MenuItem key={typeOfSchool.code} value={typeOfSchool.code}>
                                  {typeOfSchool.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                          <FormHelperText>Please specify Government or International or Other</FormHelperText>
                        </FormControl>
                      </div>
                      <div className="col-auto mt-4">
                        {showOthersTypeOfSchool && (
                          <InputText
                            placeholder="Please specify other type"
                            name="othersTypeOfSchool"
                            defaultValue={othersTypeOfSchool}
                            className="ml-2"
                            onBlur={handleBlurInput.bind(this)}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className={`${isMobile ? '' : 'row'} mt-3`}>
              <FormLabel className="ml-2" component="legend">
                Exam Grades
              </FormLabel>

              {gradesField.map((grades, index) => {
                return (
                  <Paper elevation={1} key={index} className="mx-1 my-1">
                    <div className="col-auto">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={grades.nameBox}
                              checked={grades.check}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={grades.labelBox}
                        />
                      </FormControl>
                    </div>
                    <div className="col-sm-auto col-12">
                      <InputText
                        placeholder={grades.labelField}
                        name={grades.nameField}
                        defaultValue={grades.valueField}
                        className="mt-0 mb-2"
                        inputProps={{ maxLength: grades.nameField === 'gpaOthersField' ? 20 : 4 }}
                        disabled={!grades.check}
                        onBlur={handleBlurInput.bind(this)}
                      />
                    </div>
                  </Paper>
                )
              })}
            </div>

            <div className="row">
              <div className="col-6 mt-3">
                <div className="row">
                  <FormLabel className="ml-2 mt-3" component="legend">
                    Favorite Subjects
                  </FormLabel>

                  {favoriteSubjects.map((subjects, index) => (
                    <Paper key={index} className="m-1">
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={subjects.check}
                                name={subjects.name}
                                color="primary"
                                onChange={handleCheckboxChange}
                                // onBlur={e => onBlur(e)}
                              />
                            }
                            label={subjects.label}
                          />
                        </FormControl>
                      </div>
                      <div className="col-auto" style={{ marginBottom: '-30px', marginRight: '5px' }}>
                        <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                      </div>
                    </Paper>
                  ))}
                  <OthersField
                    check={favSubjOthersBox}
                    nameBox={'favSubjOthersBox'}
                    placeholderField={'Please specify other favorite subject'}
                    nameField={'favSubjOthersBoxField'}
                    valueField={favSubjOthersBoxField}
                    checkBoxChange={handleCheckboxChange}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>

              <div className="col-6 mt-3">
                <div className="row">
                  <FormLabel className="ml-2 mt-3" component="legend">
                    Weakest Subjects
                  </FormLabel>
                  {weakestSubjects.map((subjects, index) => (
                    <Paper key={index} className="m-1">
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox checked={subjects.check} name={subjects.name} onChange={handleCheckboxChange} />
                            }
                            label={subjects.label}
                          />
                        </FormControl>
                      </div>
                      <div className="col-auto" style={{ marginBottom: '-30px', marginRight: '5px' }}>
                        <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                      </div>
                    </Paper>
                  ))}

                  <OthersField
                    check={weakestSubjOthersBox}
                    nameBox={'weakestSubjOthersBox'}
                    placeholderField={'Please specify other weakest subject'}
                    nameField={'weakestSubjOthersBoxField'}
                    valueField={weakestSubjOthersBoxField}
                    checkBoxChange={handleCheckboxChange}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 mt-3">
                <div className="row">
                  <FormLabel className="ml-2 mt-3" component="legend">
                    Type of external exam taken
                  </FormLabel>
                  {externalExamsTaken.map((exams, index) => (
                    <Paper key={index} className="m-1">
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={exams.check}
                                name={exams.name}
                                color="primary"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={exams.label}
                          />
                        </FormControl>
                      </div>
                      <div className="col-auto" style={{ marginBottom: '-30px', marginRight: '5px' }}>
                        <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                      </div>
                    </Paper>
                  ))}

                  <OthersField
                    check={extExamOthers}
                    nameBox={'extExamOthers'}
                    placeholderField={'Please specify other external exam'}
                    nameField={'extExamOthersField'}
                    valueField={extExamOthersField}
                    checkBoxChange={handleCheckboxChange}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>

              <div className="col-6 mt-3">
                <div className="row">
                  <FormLabel className="ml-2 mt-3" component="legend">
                    Type of courses taken at other tuition centre
                  </FormLabel>
                  {coursesTakenAtOtherTuition.map((course, index) => (
                    <Paper key={index} className="m-1">
                      <div className="col-auto">
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={course.check}
                                name={course.name}
                                color="primary"
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={course.label}
                          />
                        </FormControl>
                      </div>
                      <div className="col-auto" style={{ marginBottom: '-30px', marginRight: '5px' }}>
                        <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                      </div>
                    </Paper>
                  ))}
                  <OthersField
                    check={otherTCcourseOthers}
                    nameBox={'otherTCcourseOthers'}
                    placeholderField={'Please specify other tuition course'}
                    nameField={'otherTCcourseOthersField'}
                    valueField={otherTCcourseOthersField}
                    checkBoxChange={handleCheckboxChange}
                    onBlur={handleBlurInput.bind(this)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 mt-3">
                <div className="row">
                  <div className="col-sm-auto">
                    <FormLabel className={'mt-3'} component="legend">
                      Medium of study
                    </FormLabel>

                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={'studyMediumEng'}
                            checked={studyMediumEng}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="English"
                      />
                    </FormControl>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={'studyMediumThai'}
                            checked={studyMediumThai}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Thai"
                      />
                    </FormControl>
                  </div>

                  <div className="col-sm-auto mt-3">
                    <FormLabel className="ml-2" component="legend">
                      Currently Studying at EPA?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="studyingAtEPA"
                      className="ml-2"
                      name="studyingAtEPA"
                      value={studyingAtEPA}
                      onChange={handleChange}>
                      <FormControlLabel value="y" control={<Radio color="primary" />} label="Yes" />
                      <FormControlLabel value="n" control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                  </div>
                </div>
              </div>

              {studyingAtEPA === 'y' && (
                <div className="col-6 mt-3">
                  <div className="row">
                    <FormLabel className="ml-2" component="legend">
                      Courses taken at EPA
                    </FormLabel>
                    {coursesTakenAtEPA.map((course, index) => (
                      <Paper key={index} className="m-1">
                        <div className="col-auto">
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={course.check}
                                  name={course.name}
                                  color="primary"
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={course.label}
                            />
                          </FormControl>
                        </div>
                        <div className="col-auto" style={{ marginBottom: '-30px', marginRight: '5px' }}>
                          <TextField disabled={true} InputProps={{ disableUnderline: true }} />
                        </div>
                      </Paper>
                    ))}
                    <OthersField
                      check={coursesTakenAtEpaOthers}
                      nameBox={'coursesTakenAtEpaOthers'}
                      placeholderField={'Please specify other course at EPA'}
                      nameField={'coursesTakenAtEpaOthersField'}
                      valueField={coursesTakenAtEpaOthersField}
                      checkBoxChange={handleCheckboxChange}
                      onBlur={handleBlurInput.bind(this)}
                    />
                  </div>
                </div>
              )}
            </div>

            <FormLabel className={'mt-3'} component="legend">
              Currently studying with another tuition centre? (This is to facilitate better service from EPA)
            </FormLabel>
            <div className="row">
              <div className="col-6">
                <RadioGroup
                  row
                  aria-label="studyingAtOtherSchool"
                  className="ml-2"
                  name="studyingAtOtherSchool"
                  value={studyingAtOtherSchool}
                  onChange={handleChange}>
                  <FormControlLabel value="y" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="n" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
              </div>
              <div className="col-6">
                {studyingAtOtherSchool === 'y' && (
                  <div>
                    <InputText
                      placeholder="Name of school"
                      name="studyingAtOtherSchoolName"
                      defaultValue={studyingAtOtherSchoolName}
                      onBlur={handleBlurInput.bind(this)}
                      helperText="Other school name"
                    />
                  </div>
                )}
              </div>
            </div>

            <hr />

            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mt-3 mr-3">
              {currentUser._type === 'Parent' ? (
                <Link to={`/app`}>Go Back to Profile</Link>
              ) : (
                <Link to={USER_PROFILE}>Go Back to Profile</Link>
              )}
              <Button variant="contained" color="primary" className="ml-3" onClick={submitChanges}>
                Save Changes
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export default SchoolsAcademic
