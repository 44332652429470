import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import agent from 'agent'
import { CONTACT_US, GET_NEWS } from 'constants/actionTypes'
import { ALL_CLASSES, SERVICES, CONTACT, COOKIES_POLICY } from 'routes/routes'
import { checkWebpSupport } from 'utils/misc'
import VideoPlayerDialog from 'components/dialog/video-player'
import NewsAndEventsCard from 'components/public/NewsAndEventsCard'
import ProgramsCard from 'components/public/ProgramsCard'
import StudentDestinationCard from 'components/public/StudentDestinationCard'
import CTACard from 'components/public/CTACard'
import MUICookieConsent from 'material-ui-cookie-consent'

const mapStateToProps = state => ({
  contactInProgress: state.misc.contactInProgress
})

const mapDispatchToProps = dispatch => ({
  contactUs: reqPayload => dispatch({ type: CONTACT_US, payload: agent.Misc.contactUs(reqPayload) }),
  getNews: () => dispatch({ type: GET_NEWS, payload: agent.NewsAndEvents.getNews() })
})

class LandingSG extends React.Component {
  state = {
    isVideoDialogOpen: false,
    videoURL: '',
    webpSupport: true,
    name: '',
    phone: '',
    email: '',
    errorMsg: '',
    sent: false,
    showStatNumber: false,
    dataRes: '',
    isVideoPlaying: false
  }

  componentDidMount() {
    let component = this
    checkWebpSupport('lossy', function (feature, result) {
      component.setState({ webpSupport: result })
    })

    this.props.getNews()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contactInProgress && !this.props.contactInProgress) {
      this.setState({ successMsg: 'Thanks for contacting us. We will reach you soon.' })
    }
  }

  onChangeFormElement = e => {
    const type = e.target.name
    const value = e.target.value
    this.setState({ [type]: value, errorMsg: '' })
  }

  contactEPA = () => {
    const { name, email, phone } = this.state
    if (name && email && phone) {
      this.props.contactUs({
        message_type: 'contact',
        message: `${name} (${email}) (${phone ?? 'N/A'}) leave contact on our homepage`,
        intent: 'Home Page'
      })
      this.setState({ sent: true })
    } else {
      this.setState({
        errorMsg: 'Please provide your name, email and contact number.'
      })
    }
  }

  goToClassCatalogue = () => {
    this.props.history.push(ALL_CLASSES)
  }

  goToNextSection = () => {
    let elmnt = document.getElementById('class-experience')
    window.scrollTo({ top: elmnt.offsetTop - 70, behavior: 'smooth' })
  }

  toggleVideoDialog = () => {
    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen
    })
  }

  toggleDesktopVideo = () => {
    this.setState({
      isVideoPlaying: !this.state.isVideoPlaying
    })
  }

  contactSectionVisibilityChange = isVisible => {
    const { showStatNumber } = this.state
    if (isVisible && !showStatNumber) {
      this.setState({ showStatNumber: true })
    }
  }

  render() {
    const { isVideoDialogOpen, isVideoPlaying } = this.state

    return (
      <div>
        <Helmet>
          <title>International Academic Center | EPA</title>
          <meta name="description" content="At EPA, We bring success that matters." />
          <meta
            name="keywords"
            content="Online tuition,Math tuition,English tuition,Online tuition,Online learning,Online education,Education platform,Online tutor,revision,online revision,online study,online learning"
          />
        </Helmet>
        <section className="trial__banner-bg--main pt-4">
          <div className="trial__banner-bg--side d-md-block d-none " />
          <div
            className={`${isMobile ? 'container' : ''}`}
            style={{
              marginTop: isMobile ? '' : '16vh',
              marginLeft: isMobile ? '' : '16.43vh',
              marginRight: isMobile ? '' : '20.09242vh'
            }}>
            <div className="row align-items-center">
              <div className="col-lg-6 col-12">
                <div className="text-justify text-md-left">
                  <div className="section__header_landing_title">
                    <div className="title mb-4 text-left">Learning beyond education.</div>
                    <div className="paragraph mb-4 text-left col-12 col-lg-10" style={{ marginLeft: '-10px' }}>
                      Beyond tests, grades, and admissions, we are committed to empowering students to develop skills
                      that will last a lifetime.
                    </div>
                  </div>

                  <div className="pt-4 mb-5 mt-5">
                    <Link to={SERVICES}>
                      <span className="info-description-white--bold text-center orange-btn">Discover Our Programs</span>
                    </Link>
                  </div>
                </div>

                {/* <img src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/stockimage.png"></img> */}

                {/* <div className="text-justify text-md-left"> */}

                <div className="d-flex d-md-none d-block mobileImage">
                  {/* <div className="sonar-wrapper w-100" onClick={this.toggleVideoDialog}>
                      <i className="material-icons sonar-play">play_circle_filled</i>
                      <div className="sonar-emitter--sm">
                        <div className="sonar-wave"></div>
                      </div>
                    </div> */}
                </div>

                {/* </div> */}
              </div>
              {/* <div className="col-md-6 text-center d-md-block d-none" style={{ zIndex: 2 }}>
                <div className="sonar-wrapper" onClick={this.toggleVideoDialog}>
                  <i className="material-icons sonar-play">play_circle_filled</i>
                  <div className="sonar-emitter">
                    <div className="sonar-wave"></div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: '#F7EFEA' }}>
          <div className="container">
            <div className="section__header_landing_title mt-5 mb-5 mb-md-0">
              <div
                className="header ml-2 h"
                style={{
                  fontFamily: "'Libre Baskerville', sans-serif",
                  paddingBottom: isMobile ? '0' : '8vh'
                }}>
                Discover our programs
              </div>
            </div>
            <div className="row align-items-center justify-content-around mb-5">
              <div className="row">
                <ProgramsCard
                  title={'Tutoring'}
                  subtitle={'Academic & Test Preparation'}
                  image={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/tutoring.svg'}
                  description={
                    'Whether you need more pointers for school work or to prepare for an upcoming exam, we’ve got you covered. '
                  }
                  link={'tutoring'}
                  color={'#00BF12'}
                  hoverColor={'#008C0D'}
                />

                <ProgramsCard
                  title={'CollegeWise'}
                  subtitle={'University Admissions Counseling'}
                  image={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/collegwise.svg'}
                  description={
                    'Our private counseling program is a personalized coaching experience by a team of expert mentors who share your passion and ambition. '
                  }
                  link={'collegewise'}
                  color={'#007AFF'}
                  hoverColor={'#0062CC'}
                />

                <ProgramsCard
                  title={'Homeschool'}
                  subtitle={'Your Personalized Curriculum'}
                  image={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/homeschool.svg'}
                  description={
                    'Our personalized Homeschool program is designed by experienced experts, we are ready to help you plan and accomplish your dream curriculum.'
                  }
                  link={'homeschool'}
                  color={'#9B4DFF'}
                  homeschool={true}
                  hoverColor={'#7C3DCC'}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container">
            <div className="section__header_landing_title my-5">
              <div
                className="header text-center"
                style={{
                  fontFamily: "'Libre Baskerville', sans-serif"
                }}>
                Why study with us?
              </div>
            </div>
            <div className="row align-items-center justify-content-around">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-12 text-left p-4 my-lg-5">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          src={
                            'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/EPA_Web_TailoredEducation.jpg'
                          }
                          width={isMobile ? '100%' : '105%'}
                          alt="landing"
                          style={{ height: isMobile ? '24.1206vh' : '45.0251vh', borderRadius: '8px' }}></img>
                      </div>
                      <div
                        className="section__header_landing_title col-lg-7 justify-content-center ml-3 ml-md-0 ml-lg-0"
                        style={{ paddingLeft: isMobile ? '0px' : '10.03vh' }}>
                        <p className={`header--bold mt-4 mb-4 text-left`}>A tailored education.</p>
                        <p className={`paragraph text-justify mb-4 text-left`}>
                          We believe that every student is unique, so we make our best effort to sincerely understand
                          their specific needs and goals.
                        </p>
                        <Link
                          to={'/contact'}
                          className={`description text-justify mb-4 text-left orange-link--hover-same`}
                          style={{ textDecorationColor: '#FD6502', textDecorationThickness: '2px' }}>
                          {'Get in touch with us'}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-12 text-left p-4 my-lg-5">
                    {isMobile ? (
                      <div className="row align-items-center">
                        <div className="col-lg-6">
                          <img
                            src={
                              'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/EPA_Web_ExtensiveGuidance.jpg'
                            }
                            width="100%"
                            alt="pic"
                            style={{ height: '24.1206vh', borderRadius: '8px' }}></img>
                        </div>
                        <div className="section__header_landing_title col-lg-6 justify-content-center ml-lg-1">
                          <p className={`header--bold mt-4 text-left`}>Extensive guidance.</p>
                          <p className={`paragraph text-justify mb-4 text-left`}>
                            Our programs grow along with our students. Our extensive course and coverage, from an early
                            age to pre-college to life-ready.
                          </p>

                          <Link
                            to={'/services'}
                            className={`description text-justify mb-4 text-left orange-link--hover-same`}
                            style={{ textDecorationColor: '#FD6502', textDecorationThickness: '2px' }}>
                            {'View our Curriculums'}
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="row align-items-center section__header_landing_title">
                        <div className="col-lg-7 justify-content-center ">
                          <p className={`header--bold mt-4 text-left`}>Extensive guidance.</p>
                          <div className={`paragraph text-justify mb-4 text-left`} style={{ marginRight: '19.095vh' }}>
                            <p>
                              Our programs grow along with you. Our extensive course and coverage, from an early age to
                              pre-college to life-ready.
                            </p>
                          </div>

                          <Link
                            to={'/services'}
                            className={`description text-justify mb-4 text-left orange-link--hover-same`}
                            style={{ textDecorationColor: '#FD6502', textDecorationThickness: '2px' }}>
                            {'View our Curriculums'}
                          </Link>
                        </div>
                        <div className="col-lg-5">
                          <img
                            src={
                              'https://epa-assets.s3.ap-southeast-1.amazonaws.com/services/EPA_Web_ExtensiveGuidance.jpg'
                            }
                            width="105%"
                            alt="pic"
                            style={{
                              height: '45.0251vh',
                              borderRadius: '8px',
                              marginLeft: isMobile ? '0px' : '-5.5vh'
                            }}></img>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-lg-12 text-left p-4 my-lg-5">
                    <div className="row align-items-center">
                      <div className="col-lg-5">
                        <img
                          src={'https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/best.png'}
                          width={isMobile ? '100%' : '105%'}
                          alt="pic"
                          style={{ height: isMobile ? '15em' : '45.0251vh', borderRadius: '8px' }}></img>
                      </div>
                      <div
                        className="section__header_landing_title col-lg-7 justify-content-center ml-3 ml-md-0 ml-lg-0"
                        style={{ paddingLeft: isMobile ? '0px' : '10.03vh' }}>
                        <p className={`header--bold my-4 text-left`}>{'Learn from the best.'}</p>
                        <p className={`paragraph text-justify mb-4 text-left`}>
                          All of our teachers and counselors are handpicked by a rigorous process. But most importantly,
                          they are passionate and care deeply about their subjects.
                        </p>

                        <Link
                          to={'/team'}
                          style={{ textDecorationColor: '#FD6502', textDecorationThickness: '2px' }}
                          className={`description text-justify mb-4 text-left orange-link--hover-same`}>
                          {'Meet our Team'}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: '#F7EFEA' }}>
          <div className="container">
            <div className="section__header_landing_title mt-5 mb-5">
              <div
                className="header mb-2 h text-center"
                style={{
                  fontFamily: "'Libre Baskerville', sans-serif"
                }}>
                Our unique approach to education
              </div>
              <div className="paragraph mb-2 mt-3 text-center">
                Learn more about our approach from Kru A, the founder of EPA.
              </div>
            </div>
            <div className="row mb-5 justify-content-center align-items-center">
              <div className="mt-3 d-lg-block col-lg-10 video-player-img">
                {isVideoPlaying ? (
                  <video
                    autoPlay
                    className="w-100"
                    controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                    controlsList={'nodownload'}>
                    <source src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/EPA.mp4" type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/epa-video.png"
                    alt="video cover"
                    className="img-fluid"></img>
                )}
                {isVideoPlaying ? (
                  <div className="sonar-wrapper w-100 sonar-all" style={{ position: 'absolute' }}>
                    <i className="material-icons sonar-play--sm-stop" onClick={this.toggleDesktopVideo}>
                      stop_circle
                    </i>
                    <i
                      className={`${isVideoPlaying ? '' : 'fa fa-play fa-1x sonar-play--sm-triangle'}`}
                      onClick={this.toggleDesktopVideo}></i>
                    <div className="sonar-emitter--sm">
                      <div className="sonar-wave"></div>
                    </div>
                  </div>
                ) : (
                  <div className="sonar-wrapper w-100 sonar-all" style={{ position: 'absolute' }}>
                    <i className="material-icons sonar-play--sm" onClick={this.toggleDesktopVideo}>
                      play_circle
                    </i>
                    <i
                      className="fa fa-play fa-1x sonar-play--sm-triangle sonar-small"
                      onClick={this.toggleDesktopVideo}></i>
                    <div className="sonar-emitter--sm">
                      <div className="sonar-wave"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container">
            <div className="section__header_landing_title" style={{ marginTop: '6.80359vh', marginBottom: '8.7vh' }}>
              <div
                className="header mb-2 text-center"
                style={{
                  fontFamily: "'Libre Baskerville', sans-serif"
                }}>
                Student destinations
              </div>
              <div className="paragraph mt-3 text-center">Where are our students now?</div>
            </div>
            <StudentDestinationCard type={2} />
          </div>
        </section>

        <section style={{ backgroundColor: '#F7EFEA' }}>
          <div className="container">
            <div className="section__header_landing_title mb-5">
              <div
                className="header mb-2 mt-5"
                style={{
                  fontFamily: "'Libre Baskerville', sans-serif"
                }}>
                News and events
              </div>
              <div className="paragraph mb-2 mt-3 text-left">Trending stories from the world of education.</div>
            </div>
            <div className="row align-items-center justify-content-around mb-4">
              <div className="row">
                <NewsAndEventsCard articleSlot={1} />
                <NewsAndEventsCard articleSlot={2} />
                <NewsAndEventsCard articleSlot={3} />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white" style={{ marginBottom: '-40px' }}>
          <div className="container">
            <div className="section__header_landing_title my-5">
              <div
                className="header mb-2 text-center"
                style={{
                  fontFamily: "'Libre Baskerville', sans-serif"
                }}>
                Our School
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-12 p-4">
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/s1.png"
                  alt="s1"
                  width="100%"></img>
              </div>
              <div className="col-lg-4 col-12 p-4">
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/s2.png"
                  alt="s2"
                  width="100%"></img>
              </div>
              <div className="col-lg-4 col-12 p-4">
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/s3.png"
                  alt="s3"
                  width="100%"></img>
              </div>
              <div className="col-lg-4 col-12 p-4">
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/s4.png"
                  alt="s4"
                  width="100%"></img>
              </div>
              <div className="col-lg-4 col-12 p-4">
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/s5.png"
                  alt="s5"
                  width="100%"></img>
              </div>
              <div className="col-lg-4 col-12 p-4">
                <img
                  src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/s6.png"
                  alt="s6"
                  width="100%"></img>
              </div>
            </div>
          </div>
        </section>

        <CTACard />

        <MUICookieConsent
          cookieName="mySiteCookieConsent"
          componentType="Snackbar" // default value is Snackbar
          message={
            <>
              This site uses 🍪cookies to ensure that you get the best experience.{' '}
              <Link to={COOKIES_POLICY}>Read More</Link>
            </>
          }
        />
        {/* <section className="bg-white">
          <div className="section__header_landing mb-5">
            <div
              className="title mb-2"
              style={{
                fontSize: '2.3rem',
                fontWeight: '400',
                fontFamily: "'Libre Baskerville', sans-serif",
                lineHeight: 1.2,
                color: '#000000'
              }}>
              Our Team
            </div>
            <div className="mx-auto" style={{ maxWidth: '120px', borderBottom: '2px solid #7B7B7B' }}></div>
          </div>
          <div className={isMobile ? `${isTablet ? 'container-fluid' : 'container-fluid px-0'}` : 'container'}>
            <FeaturedTeacherCarousel />
          </div>
        </section> */}

        <VideoPlayerDialog
          isOpen={isVideoDialogOpen}
          close={this.toggleVideoDialog}
          videoURL="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/EPA.mp4"
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingSG))
