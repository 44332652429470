import _ from 'lodash'

const defaultState = {
  shoppingCart: {
    item: []
  },
  updatingCartItem: false,
  gettingCartQuote: false,
  cartQuote: null,
  cartClasses: null,
  cartQuoteError: null,
  checkoutInProgress: false,
  invoice: null,
  orderList: null,
  checkoutError: null,
  initializingPayment: false,
  paymentIntent: null,
  initPaymentError: null,
  gettingPostPaymentStat: false,
  postPaymentStat: null,
  lessonPackages: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
