import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ROOT } from 'routes/routes'

export default function TermOfService() {
  return (
    <div className="landing">
      <Helmet>
        <title>Terms of Service | EPA</title>
      </Helmet>
      <section className="bg-white">
        <div className="container">
          <div className="section__header">
            <img
              width="40%"
              src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/EPA+Extended+Logo+Light+Background+(Transparent).png"></img>
            <div className="title mt-3">TERMS AND CONDITIONS</div>
          </div>

          <div className="row">
            <div className="col-12 section__text text-justify info-explanation-black ">
              <p className="text-underline mb-0">
                <strong>BOOKING POLICY</strong>
              </p>
              <p className="ml-3 mt-2">
                1. เมื่อนักเรียนและผู้ปกครองได้รับตารางเรียนให้ตรวจสอบความถูกต้องของตารางเรียน
                หากพบข้อผิดพลาดหรือต้องการเปลี่ยนแปลง กรุณาแจ้งเจ้าหน้าที่ทันที
              </p>
              <p>
                {' '}
                Upon receiving our class schedule, students or parents are responsible for double-checking the accuracy
                of the schedule. Staff must be notified immediately if there are any mistakes or alterations in the
                schedule.
              </p>
              <p className="ml-3">
                2. กรุณาชำระค่าเรียนและส่งหลักฐานยืนยันการจ่ายเงินให้กับทางสถาบัน ภายใน 1 สัปดาห์หลังได้รับตารางเรียน
                เพื่อรักษาสิทธิ์และยืนยันตารางเรียน หากเกินช่วงเวลาที่กำหนด
                ทางระบบคอมพิวเตอร์จะยกเลิกตารางเรียนที่ได้ลงไว้โดยอัตโนมัติและจะไม่สามารถรับรองเวลาเรียนเดิมที่เคยจองไว้
              </p>
              <p>
                After receiving your class schedule, please send in your proof of payment within 1 week to secure your
                scheduled classes. Failure to do so will result in cancellation of your schedule.
              </p>
              <p className="ml-3">
                3. สำหรับนักเรียนที่เรียนเป็นประจำทุกเดือน/นักเรียนโฮมสคูล
                สามารถแจ้งสำรองตารางเรียนในวัน-เวลาเดิมโดยการชำระค่าเรียนล่วงหน้าอย่างน้อย 1 เดือน
              </p>
              <p>
                For homeschool students or students with ongoing monthly schedule, please make the payment a minimum of
                I month in advance to secure the booked time slot.
              </p>
              <p className="ml-3">
                4. นักเรียนที่มียอดค้างค่าเรียนเกิน 5,000 บาท
                ทางสถาบันขอสงวนสิทธิ์ในการยกเลิกตารางเรียนที่ได้จองไว้ทั้งหมด ตามข้อกำหนดnที่กลางไว้ในข้อ (2)
              </p>
              <p>Students who have a deficit more than 5,000 baht will have all schedule removed as stated in (2).</p>
              <p className="text-underline mb-0">
                <strong>CANCELLETION POLICY</strong>
              </p>
              <p className="ml-3 mt-2">
                1. พนักงาน EPA จะไลน์เพื่อแจ้งนักเรียน/ผู้ปกครอง ล่วงหน้า 1 วัน ก่อนเวลา 18.00 น.
                หากมีการยกเลิกการเรียนหลังจากนี้ (ทุกกรณี)หรือขาดเรียน ทาง EPA
                ขอสงวนสิทธิ์ในการคิดค่าเรียนเต็มจำนวนตามเวลาที่ระบุไว้ทุกกรณี
              </p>
              <p>
                EPA Staff will contact either the student / parents /guardian via application LINE, one day in advance
                to confirm the classes. Full amount of fee will be applied in case of cancellation.
              </p>
              <p className="ml-3">
                2. พนักงาน EPA จะไลน์เพื่อแจ้งนักเรียน/ผู้ปกครอง ล่วงหน้า 1 วัน ก่อนเวลา 18.00 น.
                หากมีการยกเลิกการเรียนหลังจากนี้ (ทุกกรณี)หรือขาดเรียน ทาง EPA
                ขอสงวนสิทธิ์ในการคิดค่าเรียนเต็มจำนวนตามเวลาที่ระบุไว้ทุกกรณี
              </p>
              <p>
                In case student cannot attend any classes, please inform EPA at least one day in advance before 6 pm to
                avoid late cancellation fees. EPA reserves the right to charge the class fees at full amount for any
                cancellations after 6pm deadline or no-shows.
              </p>
              <p className="text-underline mb-0">
                <strong>REFUND POLICY</strong>
              </p>
              <p className="ml-3 mt-2">
                1. หากนักเรียน/ผู้ปกครอง ได้ทำการชำระเงินล่วงหน้าเพื่อจองตารางแลrrrt
                ะต้องการยกเลิกตารางเรียนที่ได้ทำการชำระไว้ เมื่อมีการแจ้งความประสงค์ขอ Refund ยอดเงินคงเหลือคืน
                ทางสถาบันจะทำการ Refund เต็มจำนวนของยอดคงเหลือ เมื่อยอดชำระล่วงหน้านั้นเป็นการโอนผ่านบัญชีธนาคาร
                และทางสถาบันขอสงวนสิทธิ์ หัก 3% ของยอดคงเหลือ เมื่อเป็นการชำระโดยบัตรเครดิต
              </p>
              <p>
                Students/Parents can notify EPA for refunding. The credit balance will be fully refunded if the payment
                is done by bank transfer. Three percent reduction (-3%) will be applied if the payment is done by credit
                card.
              </p>
              <p className="text-underline mb-0">
                <strong>REMOVAL POLICY</strong>
              </p>
              <p className="ml-3 mt-2">
                1. หากนักเรียนยกเลิกตารางเรียน ใน วัน-เวลา-วิชา-อาจารย์ ท่านเดิม 2 ครั้งติดต่อกัน หรือมาเข้าเรียนสายเกิน
                30 นาที 2 ครั้งติดต่อกันภายใน 1 เดือน ทางสถาบันจะโทรแจ้งผู้ปกครองเพื่อหาครูใหม่
                หรือเวลาเรียนใหม่ที่เหมาะสมแล้วแต่กรณี
              </p>
              <p>
                Students who miss a class of the same subject, the same teacher and the same time or is late for more 30
                minutes twice in a row within 1 month period will be reported to the pare appropriate schedule will be
                proposed. This might include changing teacher or time slot.
              </p>

              <p className="text-center mt-5">
                *ทางสถาบัน EPA ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขตามความเหมาะสมโดยไม่ต้องแจ้งล่วงหน้า{' '}
              </p>
              <p className="text-center">
                * EPA reserves the right to change these terms and conditions without notification.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
