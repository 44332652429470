import React, { useState } from 'react'
import Proptypes from 'prop-types'
import { Button } from '@material-ui/core'
import UploadResourceDialog from 'components/online-class/dialog-upload-resource'
import ResourceList from 'components/online-class/list-resource'

const ResourceTab = props => {
  const { lessonId, currentUser } = props
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)

  return (
    <div className="lesson__section">
      <div className="row">
        <div className="col text-right">
          {currentUser?._type !== 'Parent' && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setUploadDialogOpen(true)
              }}>
              Add New Resource
            </Button>
          )}
        </div>
      </div>
      <div className="list__resources">
        <ResourceList lessonId={lessonId} />
      </div>
      <UploadResourceDialog
        isOpen={uploadDialogOpen}
        close={() => {
          setUploadDialogOpen(false)
        }}
        lessonId={lessonId}
      />
    </div>
  )
}

ResourceTab.propTypes = {
  lessonId: Proptypes.string.isRequired
}

export default ResourceTab
