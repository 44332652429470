import React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Button from '@mui/material/Button'
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import SchoolsAcademic from 'components/profile/schoolsacademic'

import _ from 'lodash'
import { Link, withRouter } from 'react-router-dom'

import countrycodes from 'assets/country_code.json'
import { USER_PROFILE } from 'routes/routes'
import { NumberOnly } from 'utils/format'
import AcademicCareerGoal from 'components/profile/academiccareergoal'
import PersonalityPreference from 'components/profile/personalitypreference'

const steps = ['Basic Information', 'Schools & Academic', 'Academic & Career Goal', 'Personality & Preference']
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function StepperProfile(props) {
  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState({})
  const [open, setOpen] = React.useState(false)

  // const handleClick = () => {
  //   setOpen(true)
  // }

  const {
    currentUser,
    handleChange,
    facebook,
    line,
    validateFormElement,
    onFocusFormElement,
    submitChanges,
    email,
    name,
    dateOfBirth,
    gender,
    phoneCountryCode,
    phone,
    level,
    othersLevel,
    gradeLevel,
    yearLevel,
    showOthersLevel,
    othersSchool,
    showOthersSchool,
    school,
    typeOfSchool,
    othersTypeOfSchool,
    showOthersTypeOfSchool,
    studyMediumEng,
    studyMediumThai,
    courseType,
    examType,
    studyingAtOtherSchool,
    studyingAtOtherSchoolName,
    formError,

    contactPreference,
    contactPreferenceOther,
    isSaved,
    handleClose,

    handleBlurInput,
    handleCheckboxChange,
    handleAutoCompleteChange,

    gpaEngBox,
    gpaThaiBox,
    gpaMathsBox,
    gpaPhysBox,
    gpaChemBox,
    gpaBioBox,
    gpaGeoBox,
    gpaHistBox,
    gpaLitBox,
    gpaSSBox,
    gpaPolBox,
    gpaEcoBox,
    gpaArtBox,
    gpaFLBox,
    gpaOthersBox,
    gpaEng,
    gpaThai,
    gpaMaths,
    gpaPhys,
    gpaChem,
    gpaBio,
    gpaGeo,
    gpaHist,
    gpaLit,
    gpaSS,
    gpaPol,
    gpaEco,
    gpaArt,
    gpaFL,
    gpaOthersField,
    favSubjEngBox,
    favSubjThaiBox,
    favSubjMathsBox,
    favSubjPhysBox,
    favSubjChemBox,
    favSubjBioBox,
    favSubjGeoBox,
    favSubjHistBox,
    favSubjLitBox,
    favSubjSSBox,
    favSubjPolBox,
    favSubjEcoBox,
    favSubjArtBox,
    favSubjFLBox,
    favSubjOthersBox,
    favSubjOthersBoxField,
    weakestSubjEngBox,
    weakestSubjThaiBox,
    weakestSubjMathsBox,
    weakestSubjPhysBox,
    weakestSubjChemBox,
    weakestSubjBioBox,
    weakestSubjGeoBox,
    weakestSubjHistBox,
    weakestSubjLitBox,
    weakestSubjSSBox,
    weakestSubjPolBox,
    weakestSubjEcoBox,
    weakestSubjArtBox,
    weakestSubjFLBox,
    weakestSubjOthersBox,
    weakestSubjOthersBoxField,
    extExamSAT,
    extExamGRE,
    extExamIELTS,
    extExamTOEFL,
    extExamGMAT,
    extExamOthers,
    extExamOthersField,
    otherTCcourseSAT,
    otherTCcourseGRE,
    otherTCcourseIELTS,
    otherTCcourseTOEFL,
    otherTCcourseGMAT,
    otherTCcourseEnglish,
    otherTCcourseThai,
    otherTCcourseMath,
    otherTCcoursePhysics,
    otherTCcourseBiology,
    otherTCcourseChemistry,
    otherTCcourseGeography,
    otherTCcourseHistory,
    otherTCcourseLiterature,
    otherTCcourseSocialStudies,
    otherTCcoursePolitics,
    otherTCcourseEconomics,
    otherTCcourseArt,
    otherTCcourseForeignLanguage,
    otherTCcourseOthers,
    otherTCcourseOthersField,
    studyingAtEPA,
    coursesTakenAtEpaSAT,
    coursesTakenAtEpaGRE,
    coursesTakenAtEpaIELTS,
    coursesTakenAtEpaTOEFL,
    coursesTakenAtEpaGMAT,
    coursesTakenAtEpaEnglish,
    coursesTakenAtEpaThai,
    coursesTakenAtEpaMath,
    coursesTakenAtEpaPhysics,
    coursesTakenAtEpaBiology,
    coursesTakenAtEpaGeography,
    coursesTakenAtEpaChemistry,
    coursesTakenAtEpaHistory,
    coursesTakenAtEpaLiterature,
    coursesTakenAtEpaSocialStudies,
    coursesTakenAtEpaPolitics,
    coursesTakenAtEpaEconomics,
    coursesTakenAtEpaArt,
    coursesTakenAtEpaForeignLanguage,
    coursesTakenAtEpaOthers,
    coursesTakenAtEpaOthersField,

    handleAutoCompleteStudyAreaChange,
    handleAutoCompleteStudyAreaSpecificChange,
    handleAutoCompleteStudyAreaChange1,
    handleAutoCompleteStudyAreaSpecificChange1,
    handleAutoCompleteStudyAreaChange2,
    handleAutoCompleteStudyAreaSpecificChange2,
    handleAutoCompleteStudyAreaChange3,
    handleAutoCompleteStudyAreaSpecificChange3,
    handleAutoCompleteStudyAreaChange4,
    handleAutoCompleteStudyAreaSpecificChange4,
    handleAutoCompleteParentStudyAreaChange,
    handleAutoCompleteParentStudyAreaSpecificChange,
    handleAutoCompleteParentStudyAreaChange1,
    handleAutoCompleteParentStudyAreaSpecificChange1,
    handleAutoCompleteParentStudyAreaChange2,
    handleAutoCompleteParentStudyAreaSpecificChange2,
    handleAutoCompleteParentStudyAreaChange3,
    handleAutoCompleteParentStudyAreaSpecificChange3,
    handleAutoCompleteParentStudyAreaChange4,
    handleAutoCompleteParentStudyAreaSpecificChange4,
    careerGoalsStudentPerspective,
    careerGoalsParentPerspective,

    studyGoalsStudentPerspectiveEntranceExamBox,
    studyGoalsStudentPerspectiveEntranceExamField,
    studyGoalsStudentPerspectiveSchoolExamBox,
    studyGoalsStudentPerspectiveSATBox,
    studyGoalsStudentPerspectiveGREBox,
    studyGoalsStudentPerspectiveIELTSBox,
    studyGoalsStudentPerspectiveTOEFLBox,
    studyGoalsStudentPerspectiveGMATBox,
    studyGoalsStudentPerspectiveOthersBox,
    studyGoalsStudentPerspectiveOthersField,

    studyGoalsParentPerspectiveEntranceExamBox,
    studyGoalsParentPerspectiveEntranceExamField,
    studyGoalsParentPerspectiveSchoolExamBox,
    studyGoalsParentPerspectiveSATBox,
    studyGoalsParentPerspectiveGREBox,
    studyGoalsParentPerspectiveIELTSBox,
    studyGoalsParentPerspectiveTOEFLBox,
    studyGoalsParentPerspectiveGMATBox,
    studyGoalsParentPerspectiveOthersBox,
    studyGoalsParentPerspectiveOthersField,

    targetCountryStudentUS,
    targetCountryStudentCanada,
    targetCountryStudentUK,
    targetCountryStudentAustralia,
    targetCountryStudentNewZealand,
    targetCountryStudentOthersBox,
    targetCountryStudentOthersField,

    targetCountryParentUS,
    targetCountryParentCanada,
    targetCountryParentUK,
    targetCountryParentAustralia,
    targetCountryParentNewZealand,
    targetCountryParentOthersBox,
    targetCountryParentOthersField,

    needConsultationForACC,
    areaOfStudy,
    areaOfStudySpecific,
    areaOfStudy1,
    areaOfStudySpecific1,
    areaOfStudy2,
    areaOfStudySpecific2,
    areaOfStudy3,
    areaOfStudySpecific3,
    areaOfStudy4,
    areaOfStudySpecific4,
    areaOfStudyOthers,
    areaOfStudyOthersField,
    areaOfStudyUnsure,
    areaOfStudyParent,
    areaOfStudyParentSpecific,
    areaOfStudyParent1,
    areaOfStudyParentSpecific1,
    areaOfStudyParent2,
    areaOfStudyParentSpecific2,
    areaOfStudyParent3,
    areaOfStudyParentSpecific3,
    areaOfStudyParent4,
    areaOfStudyParentSpecific4,
    areaOfStudyParentOthers,
    areaOfStudyParentOthersField,
    areaOfStudyParentUnsure,

    specialRequests,

    learningTypeVisual,
    learningTypeKinaesthetic,
    learningTypeAural,
    learningTypeSocial,
    learningTypeSolitary,
    learningTypeVerbal,
    learningTypeLogical,
    learningTypeUnsure,
    learningTypeOthersBox,
    learningTypeOthersField,

    personalityTestCheck,
    personalityTestResult,
    personalityTestResultField,

    preferredTeacherStudentPerspectiveMale,
    preferredTeacherStudentPerspectiveFemale,
    preferredTeacherStudentPerspectiveNameBox,
    preferredTeacherStudentPerspectiveNameField,
    preferredTeacherStudentPerspectiveAuthority,
    preferredTeacherStudentPerspectiveDelegator,
    preferredTeacherStudentPerspectiveFacilitator,
    preferredTeacherStudentPerspectiveDemonstrator,
    preferredTeacherStudentPerspectiveHybrid,
    preferredTeacherStudentPerspectiveOthersBox,
    preferredTeacherStudentPerspectiveOthersField,

    preferredTeacherParentPerspectiveMale,
    preferredTeacherParentPerspectiveFemale,
    preferredTeacherParentPerspectiveNameBox,
    preferredTeacherParentPerspectiveNameField,
    preferredTeacherParentPerspectiveAuthority,
    preferredTeacherParentPerspectiveDelegator,
    preferredTeacherParentPerspectiveFacilitator,
    preferredTeacherParentPerspectiveDemonstrator,
    preferredTeacherParentPerspectiveHybrid,
    preferredTeacherParentPerspectiveOthersBox,
    preferredTeacherParentPerspectiveOthersField,

    interestedTopicsPublicSpeaking,
    interestedTopicsDebate,
    interestedTopicsCoding,
    interestedTopicsLeadership,
    interestedTopicsWriting,
    interestedTopicsCreativeWriting,
    interestedTopicsFormalWriting,
    interestedTopicsReadingComprehension,
    interestedTopicsLiterature,
    interestedTopicsOthersBox,
    interestedTopicsOthersField
  } = props

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStep = step => () => {
    setActiveStep(step)
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div className="my-5">
          {activeStep === 0 ? (
            <Card>
              <CardContent>
                <h1 className="mb-0">Student details</h1>
                {currentUser._type === 'Student' && (
                  <>
                    <small className="text-muted">
                      <i className="fa fa-exclamation-circle mr-2" />
                      This is important for your tutors to recognise and address you during lessons.
                    </small>
                  </>
                )}
                <div className="row align-items-center mt-3">
                  <div className="col-md-3 col-5 font-weight-medium">Email</div>
                  <div className="col-md-9 col-7">
                    <Input className="w-100 mb-3" type="email" name="email" value={email} disabled />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Name</div>
                  <div className="col-md-9 col-7">
                    <TextField
                      className="w-100 mb-3"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      value={name}
                      error={!!formError.name}
                      helperText={formError.name}
                      onFocus={onFocusFormElement}
                    />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium mb-2">Gender</div>
                  <div className="col-md-9 col-7 mb-2">
                    <FormControl component="fieldset">
                      <RadioGroup row aria-label="gender" name="gender" value={gender} onChange={handleChange}>
                        <FormControlLabel value="f" control={<Radio color="primary" />} label="Female" />
                        <FormControlLabel value="m" control={<Radio color="primary" />} label="Male" />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium mb-2">Date of birth</div>
                  <div className="col-md-9 col-7 mb-2">
                    <TextField
                      type="date"
                      className="mb-3"
                      name="dateOfBirth"
                      onChange={handleChange}
                      value={dateOfBirth}
                      error={!!formError.dateOfBirth}
                      helperText={formError.dateOfBirth}
                      onFocus={onFocusFormElement}
                    />
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Phone number</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col-auto">
                        <Select
                          value={Number(phoneCountryCode)}
                          onChange={handleChange}
                          className="mt-0"
                          name="phoneCountryCode">
                          {countrycodes.map(cc => {
                            return (
                              <MenuItem key={cc.code} value={cc.code}>
                                <span className={`flag ${cc.flag} mr-1`} /> +{cc.code}{' '}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="tel"
                          name="phone"
                          onChange={handleChange}
                          value={phone}
                          onFocus={onFocusFormElement}
                          InputProps={{ inputComponent: NumberOnly }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Facebook URL</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="tel"
                          name="facebook"
                          onChange={handleChange}
                          value={facebook}
                          onFocus={onFocusFormElement}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">LINE ID</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col">
                        <TextField
                          className="w-100 mb-3"
                          type="tel"
                          name="line"
                          onChange={handleChange}
                          value={line}
                          onFocus={onFocusFormElement}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-5 font-weight-medium">Preferred mode of contact</div>
                  <div className="col-md-9 col-7">
                    <div className="row">
                      <div className="col">
                        <RadioGroup
                          row
                          aria-label="contactPreference"
                          name="contactPreference"
                          value={contactPreference}
                          onChange={handleChange}>
                          <FormControlLabel value="line" control={<Radio color="primary" />} label="Line" />
                          <FormControlLabel value="whatsapp" control={<Radio color="primary" />} label="Whatsapp" />
                          <FormControlLabel value="other" control={<Radio color="primary" />} label="Other" />
                        </RadioGroup>
                        {contactPreference === 'other' && (
                          <TextField
                            className="w-100 mb-3"
                            type="text"
                            name="contactPreferenceOther"
                            onChange={handleChange}
                            value={contactPreferenceOther}
                            onFocus={onFocusFormElement}
                            placeholder="e.g. SMS, email"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="d-flex flex-row flex-wrap justify-content-between align-items-center mt-3 mr-3">
                  {currentUser._type === 'Parent' ? (
                    <Link to={`/app`}>Go Back to Profile</Link>
                  ) : (
                    <Link to={USER_PROFILE}>Go Back to Profile</Link>
                  )}
                  <Button variant="contained" color="primary" className="ml-3" onClick={submitChanges} disabled={!name}>
                    Save Changes
                  </Button>
                  {/* <Button variant="outlined" onClick={handleClick}>
                    Open success snackbar
                  </Button> */}
                </div>
              </CardContent>
            </Card>
          ) : activeStep === 1 ? (
            <SchoolsAcademic
              currentUser={currentUser}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              handleAutoCompleteChange={handleAutoCompleteChange}
              handleBlurInput={handleBlurInput}
              level={level}
              othersLevel={othersLevel}
              showOthersLevel={showOthersLevel}
              othersSchool={othersSchool}
              showOthersSchool={showOthersSchool}
              school={school}
              typeOfSchool={typeOfSchool}
              othersTypeOfSchool={othersTypeOfSchool}
              showOthersTypeOfSchool={showOthersTypeOfSchool}
              studyMediumEng={studyMediumEng}
              studyMediumThai={studyMediumThai}
              studyingAtOtherSchool={studyingAtOtherSchool}
              studyingAtOtherSchoolName={studyingAtOtherSchoolName}
              gpaEngBox={gpaEngBox}
              gpaThaiBox={gpaThaiBox}
              gpaMathsBox={gpaMathsBox}
              gpaPhysBox={gpaPhysBox}
              gpaChemBox={gpaChemBox}
              gpaBioBox={gpaBioBox}
              gpaGeoBox={gpaGeoBox}
              gpaHistBox={gpaHistBox}
              gpaLitBox={gpaLitBox}
              gpaSSBox={gpaSSBox}
              gpaPolBox={gpaPolBox}
              gpaEcoBox={gpaEcoBox}
              gpaArtBox={gpaArtBox}
              gpaFLBox={gpaFLBox}
              gpaOthersBox={gpaOthersBox}
              gpaEng={gpaEng}
              gpaThai={gpaThai}
              gpaMaths={gpaMaths}
              gpaPhys={gpaPhys}
              gpaChem={gpaChem}
              gpaBio={gpaBio}
              gpaGeo={gpaGeo}
              gpaHist={gpaHist}
              gpaLit={gpaLit}
              gpaSS={gpaSS}
              gpaPol={gpaPol}
              gpaEco={gpaEco}
              gpaArt={gpaArt}
              gpaFL={gpaFL}
              gpaOthersField={gpaOthersField}
              favSubjEngBox={favSubjEngBox}
              favSubjThaiBox={favSubjThaiBox}
              favSubjMathsBox={favSubjMathsBox}
              favSubjPhysBox={favSubjPhysBox}
              favSubjChemBox={favSubjChemBox}
              favSubjBioBox={favSubjBioBox}
              favSubjGeoBox={favSubjGeoBox}
              favSubjHistBox={favSubjHistBox}
              favSubjLitBox={favSubjLitBox}
              favSubjSSBox={favSubjSSBox}
              favSubjPolBox={favSubjPolBox}
              favSubjEcoBox={favSubjEcoBox}
              favSubjArtBox={favSubjArtBox}
              favSubjFLBox={favSubjFLBox}
              favSubjOthersBox={favSubjOthersBox}
              favSubjOthersBoxField={favSubjOthersBoxField}
              weakestSubjEngBox={weakestSubjEngBox}
              weakestSubjThaiBox={weakestSubjThaiBox}
              weakestSubjMathsBox={weakestSubjMathsBox}
              weakestSubjPhysBox={weakestSubjPhysBox}
              weakestSubjChemBox={weakestSubjChemBox}
              weakestSubjBioBox={weakestSubjBioBox}
              weakestSubjGeoBox={weakestSubjGeoBox}
              weakestSubjHistBox={weakestSubjHistBox}
              weakestSubjLitBox={weakestSubjLitBox}
              weakestSubjSSBox={weakestSubjSSBox}
              weakestSubjPolBox={weakestSubjPolBox}
              weakestSubjEcoBox={weakestSubjEcoBox}
              weakestSubjArtBox={weakestSubjArtBox}
              weakestSubjFLBox={weakestSubjFLBox}
              weakestSubjOthersBox={weakestSubjOthersBox}
              weakestSubjOthersBoxField={weakestSubjOthersBoxField}
              extExamSAT={extExamSAT}
              extExamGRE={extExamGRE}
              extExamIELTS={extExamIELTS}
              extExamTOEFL={extExamTOEFL}
              extExamGMAT={extExamGMAT}
              extExamOthers={extExamOthers}
              extExamOthersField={extExamOthersField}
              otherTCcourseSAT={otherTCcourseSAT}
              otherTCcourseGRE={otherTCcourseGRE}
              otherTCcourseIELTS={otherTCcourseIELTS}
              otherTCcourseTOEFL={otherTCcourseTOEFL}
              otherTCcourseGMAT={otherTCcourseGMAT}
              otherTCcourseEnglish={otherTCcourseEnglish}
              otherTCcourseThai={otherTCcourseThai}
              otherTCcourseMath={otherTCcourseMath}
              otherTCcoursePhysics={otherTCcoursePhysics}
              otherTCcourseBiology={otherTCcourseBiology}
              otherTCcourseChemistry={otherTCcourseChemistry}
              otherTCcourseGeography={otherTCcourseGeography}
              otherTCcourseHistory={otherTCcourseHistory}
              otherTCcourseLiterature={otherTCcourseLiterature}
              otherTCcourseSocialStudies={otherTCcourseSocialStudies}
              otherTCcoursePolitics={otherTCcoursePolitics}
              otherTCcourseEconomics={otherTCcourseEconomics}
              otherTCcourseArt={otherTCcourseArt}
              otherTCcourseForeignLanguage={otherTCcourseForeignLanguage}
              otherTCcourseOthers={otherTCcourseOthers}
              otherTCcourseOthersField={otherTCcourseOthersField}
              studyingAtEPA={studyingAtEPA}
              coursesTakenAtEpaSAT={coursesTakenAtEpaSAT}
              coursesTakenAtEpaGRE={coursesTakenAtEpaGRE}
              coursesTakenAtEpaIELTS={coursesTakenAtEpaIELTS}
              coursesTakenAtEpaTOEFL={coursesTakenAtEpaTOEFL}
              coursesTakenAtEpaGMAT={coursesTakenAtEpaGMAT}
              coursesTakenAtEpaEnglish={coursesTakenAtEpaEnglish}
              coursesTakenAtEpaThai={coursesTakenAtEpaThai}
              coursesTakenAtEpaMath={coursesTakenAtEpaMath}
              coursesTakenAtEpaPhysics={coursesTakenAtEpaPhysics}
              coursesTakenAtEpaBiology={coursesTakenAtEpaBiology}
              coursesTakenAtEpaGeography={coursesTakenAtEpaGeography}
              coursesTakenAtEpaChemistry={coursesTakenAtEpaChemistry}
              coursesTakenAtEpaHistory={coursesTakenAtEpaHistory}
              coursesTakenAtEpaLiterature={coursesTakenAtEpaLiterature}
              coursesTakenAtEpaSocialStudies={coursesTakenAtEpaSocialStudies}
              coursesTakenAtEpaPolitics={coursesTakenAtEpaPolitics}
              coursesTakenAtEpaEconomics={coursesTakenAtEpaEconomics}
              coursesTakenAtEpaArt={coursesTakenAtEpaArt}
              coursesTakenAtEpaForeignLanguage={coursesTakenAtEpaForeignLanguage}
              coursesTakenAtEpaOthers={coursesTakenAtEpaOthers}
              coursesTakenAtEpaOthersField={coursesTakenAtEpaOthersField}
              gradeLevel={gradeLevel}
              yearLevel={yearLevel}
              submitChanges={submitChanges}
            />
          ) : activeStep === 2 ? (
            <AcademicCareerGoal
              currentUser={currentUser}
              needConsultationForACC={needConsultationForACC}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              handleAutoCompleteChange={handleAutoCompleteChange}
              handleBlurInput={handleBlurInput}
              handleAutoCompleteStudyAreaChange={handleAutoCompleteStudyAreaChange}
              handleAutoCompleteStudyAreaSpecificChange={handleAutoCompleteStudyAreaSpecificChange}
              handleAutoCompleteStudyAreaChange1={handleAutoCompleteStudyAreaChange1}
              handleAutoCompleteStudyAreaSpecificChange1={handleAutoCompleteStudyAreaSpecificChange1}
              handleAutoCompleteStudyAreaChange2={handleAutoCompleteStudyAreaChange2}
              handleAutoCompleteStudyAreaSpecificChange2={handleAutoCompleteStudyAreaSpecificChange2}
              handleAutoCompleteStudyAreaChange3={handleAutoCompleteStudyAreaChange3}
              handleAutoCompleteStudyAreaSpecificChange3={handleAutoCompleteStudyAreaSpecificChange3}
              handleAutoCompleteStudyAreaChange4={handleAutoCompleteStudyAreaChange4}
              handleAutoCompleteStudyAreaSpecificChange4={handleAutoCompleteStudyAreaSpecificChange4}
              handleAutoCompleteParentStudyAreaChange={handleAutoCompleteParentStudyAreaChange}
              handleAutoCompleteParentStudyAreaSpecificChange={handleAutoCompleteParentStudyAreaSpecificChange}
              handleAutoCompleteParentStudyAreaChange1={handleAutoCompleteParentStudyAreaChange1}
              handleAutoCompleteParentStudyAreaSpecificChange1={handleAutoCompleteParentStudyAreaSpecificChange1}
              handleAutoCompleteParentStudyAreaChange2={handleAutoCompleteParentStudyAreaChange2}
              handleAutoCompleteParentStudyAreaSpecificChange2={handleAutoCompleteParentStudyAreaSpecificChange2}
              handleAutoCompleteParentStudyAreaChange3={handleAutoCompleteParentStudyAreaChange3}
              handleAutoCompleteParentStudyAreaSpecificChange3={handleAutoCompleteParentStudyAreaSpecificChange3}
              handleAutoCompleteParentStudyAreaChange4={handleAutoCompleteParentStudyAreaChange4}
              handleAutoCompleteParentStudyAreaSpecificChange4={handleAutoCompleteParentStudyAreaSpecificChange4}
              careerGoalsStudentPerspective={careerGoalsStudentPerspective}
              careerGoalsParentPerspective={careerGoalsParentPerspective}
              areaOfStudy={areaOfStudy}
              areaOfStudySpecific={areaOfStudySpecific}
              areaOfStudy1={areaOfStudy1}
              areaOfStudySpecific1={areaOfStudySpecific1}
              areaOfStudy2={areaOfStudy2}
              areaOfStudySpecific2={areaOfStudySpecific2}
              areaOfStudy3={areaOfStudy3}
              areaOfStudySpecific3={areaOfStudySpecific3}
              areaOfStudy4={areaOfStudy4}
              areaOfStudySpecific4={areaOfStudySpecific4}
              areaOfStudyOthers={areaOfStudyOthers}
              areaOfStudyOthersField={areaOfStudyOthersField}
              areaOfStudyUnsure={areaOfStudyUnsure}
              areaOfStudyParent={areaOfStudyParent}
              areaOfStudyParentSpecific={areaOfStudyParentSpecific}
              areaOfStudyParent1={areaOfStudyParent1}
              areaOfStudyParentSpecific1={areaOfStudyParentSpecific1}
              areaOfStudyParent2={areaOfStudyParent2}
              areaOfStudyParentSpecific2={areaOfStudyParentSpecific2}
              areaOfStudyParent3={areaOfStudyParent3}
              areaOfStudyParentSpecific3={areaOfStudyParentSpecific3}
              areaOfStudyParent4={areaOfStudyParent4}
              areaOfStudyParentSpecific4={areaOfStudyParentSpecific4}
              areaOfStudyParentOthers={areaOfStudyParentOthers}
              areaOfStudyParentOthersField={areaOfStudyParentOthersField}
              areaOfStudyParentUnsure={areaOfStudyParentUnsure}
              studyGoalsStudentPerspectiveEntranceExamBox={studyGoalsStudentPerspectiveEntranceExamBox}
              studyGoalsStudentPerspectiveEntranceExamField={studyGoalsStudentPerspectiveEntranceExamField}
              studyGoalsStudentPerspectiveSchoolExamBox={studyGoalsStudentPerspectiveSchoolExamBox}
              studyGoalsStudentPerspectiveSATBox={studyGoalsStudentPerspectiveSATBox}
              studyGoalsStudentPerspectiveGREBox={studyGoalsStudentPerspectiveGREBox}
              studyGoalsStudentPerspectiveIELTSBox={studyGoalsStudentPerspectiveIELTSBox}
              studyGoalsStudentPerspectiveTOEFLBox={studyGoalsStudentPerspectiveTOEFLBox}
              studyGoalsStudentPerspectiveGMATBox={studyGoalsStudentPerspectiveGMATBox}
              studyGoalsStudentPerspectiveOthersBox={studyGoalsStudentPerspectiveOthersBox}
              studyGoalsStudentPerspectiveOthersField={studyGoalsStudentPerspectiveOthersField}
              studyGoalsParentPerspectiveEntranceExamBox={studyGoalsParentPerspectiveEntranceExamBox}
              studyGoalsParentPerspectiveEntranceExamField={studyGoalsParentPerspectiveEntranceExamField}
              studyGoalsParentPerspectiveSchoolExamBox={studyGoalsParentPerspectiveSchoolExamBox}
              studyGoalsParentPerspectiveSATBox={studyGoalsParentPerspectiveSATBox}
              studyGoalsParentPerspectiveGREBox={studyGoalsParentPerspectiveGREBox}
              studyGoalsParentPerspectiveIELTSBox={studyGoalsParentPerspectiveIELTSBox}
              studyGoalsParentPerspectiveTOEFLBox={studyGoalsParentPerspectiveTOEFLBox}
              studyGoalsParentPerspectiveGMATBox={studyGoalsParentPerspectiveGMATBox}
              studyGoalsParentPerspectiveOthersBox={studyGoalsParentPerspectiveOthersBox}
              studyGoalsParentPerspectiveOthersField={studyGoalsParentPerspectiveOthersField}
              targetCountryStudentUS={targetCountryStudentUS}
              targetCountryStudentCanada={targetCountryStudentCanada}
              targetCountryStudentUK={targetCountryStudentUK}
              targetCountryStudentAustralia={targetCountryStudentAustralia}
              targetCountryStudentNewZealand={targetCountryStudentNewZealand}
              targetCountryStudentOthersBox={targetCountryStudentOthersBox}
              targetCountryStudentOthersField={targetCountryStudentOthersField}
              targetCountryParentUS={targetCountryParentUS}
              targetCountryParentCanada={targetCountryParentCanada}
              targetCountryParentUK={targetCountryParentUK}
              targetCountryParentAustralia={targetCountryParentAustralia}
              targetCountryParentNewZealand={targetCountryParentNewZealand}
              targetCountryParentOthersBox={targetCountryParentOthersBox}
              targetCountryParentOthersField={targetCountryParentOthersField}
              specialRequests={specialRequests}
              submitChanges={submitChanges}
            />
          ) : activeStep === 3 ? (
            <PersonalityPreference
              currentUser={currentUser}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              handleAutoCompleteChange={handleAutoCompleteChange}
              handleBlurInput={handleBlurInput}
              learningTypeVisual={learningTypeVisual}
              learningTypeKinaesthetic={learningTypeKinaesthetic}
              learningTypeAural={learningTypeAural}
              learningTypeSocial={learningTypeSocial}
              learningTypeSolitary={learningTypeSolitary}
              learningTypeVerbal={learningTypeVerbal}
              learningTypeLogical={learningTypeLogical}
              learningTypeUnsure={learningTypeUnsure}
              learningTypeOthersBox={learningTypeOthersBox}
              learningTypeOthersField={learningTypeOthersField}
              personalityTestCheck={personalityTestCheck}
              personalityTestResult={personalityTestResult}
              personalityTestResultField={personalityTestResultField}
              preferredTeacherStudentPerspectiveMale={preferredTeacherStudentPerspectiveMale}
              preferredTeacherStudentPerspectiveFemale={preferredTeacherStudentPerspectiveFemale}
              preferredTeacherStudentPerspectiveNameBox={preferredTeacherStudentPerspectiveNameBox}
              preferredTeacherStudentPerspectiveNameField={preferredTeacherStudentPerspectiveNameField}
              preferredTeacherStudentPerspectiveAuthority={preferredTeacherStudentPerspectiveAuthority}
              preferredTeacherStudentPerspectiveDelegator={preferredTeacherStudentPerspectiveDelegator}
              preferredTeacherStudentPerspectiveFacilitator={preferredTeacherStudentPerspectiveFacilitator}
              preferredTeacherStudentPerspectiveDemonstrator={preferredTeacherStudentPerspectiveDemonstrator}
              preferredTeacherStudentPerspectiveHybrid={preferredTeacherStudentPerspectiveHybrid}
              preferredTeacherStudentPerspectiveOthersBox={preferredTeacherStudentPerspectiveOthersBox}
              preferredTeacherStudentPerspectiveOthersField={preferredTeacherStudentPerspectiveOthersField}
              preferredTeacherParentPerspectiveMale={preferredTeacherParentPerspectiveMale}
              preferredTeacherParentPerspectiveFemale={preferredTeacherParentPerspectiveFemale}
              preferredTeacherParentPerspectiveNameBox={preferredTeacherParentPerspectiveNameBox}
              preferredTeacherParentPerspectiveNameField={preferredTeacherParentPerspectiveNameField}
              preferredTeacherParentPerspectiveAuthority={preferredTeacherParentPerspectiveAuthority}
              preferredTeacherParentPerspectiveDelegator={preferredTeacherParentPerspectiveDelegator}
              preferredTeacherParentPerspectiveFacilitator={preferredTeacherParentPerspectiveFacilitator}
              preferredTeacherParentPerspectiveDemonstrator={preferredTeacherParentPerspectiveDemonstrator}
              preferredTeacherParentPerspectiveHybrid={preferredTeacherParentPerspectiveHybrid}
              preferredTeacherParentPerspectiveOthersBox={preferredTeacherParentPerspectiveOthersBox}
              preferredTeacherParentPerspectiveOthersField={preferredTeacherParentPerspectiveOthersField}
              interestedTopicsPublicSpeaking={interestedTopicsPublicSpeaking}
              interestedTopicsDebate={interestedTopicsDebate}
              interestedTopicsCoding={interestedTopicsCoding}
              interestedTopicsLeadership={interestedTopicsLeadership}
              interestedTopicsWriting={interestedTopicsWriting}
              interestedTopicsCreativeWriting={interestedTopicsCreativeWriting}
              interestedTopicsFormalWriting={interestedTopicsFormalWriting}
              interestedTopicsReadingComprehension={interestedTopicsReadingComprehension}
              interestedTopicsLiterature={interestedTopicsLiterature}
              interestedTopicsOthersBox={interestedTopicsOthersBox}
              interestedTopicsOthersField={interestedTopicsOthersField}
              submitChanges={submitChanges}
            />
          ) : (
            ''
          )}
          {
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  Next
                </Button>
              </Box>
            </React.Fragment>
          }
        </div>
        <Snackbar open={isSaved} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Changes has been successfully saved.
          </Alert>
        </Snackbar>
      </Box>
    </>
  )
}
