import React from 'react'
import agent from '../../../agent'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  GET_USER_CLASS,
  GET_CHILD_DETAILS,
  GET_TUTOR_LESSONS,
  GET_CLASS_LIST,
  SET_CLASS_PAGE,
  SET_SELECTED_CLASS
} from '../../../constants/actionTypes'
import _ from 'lodash'
import { LESSON_PAGE } from 'routes/tutorRoutes'

import { USER_STUDENT_PROFILE_EDIT, MY_CLASS_DETAILS } from '../../../routes/routes'
import ClassItem from '../../shared/Classes/ClassItem'

import moment from 'moment'
import { isMobile } from 'react-device-detect'
import {
  MenuItem,
  Button,
  CircularProgress,
  CardContent,
  Card,
  Popover,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Input,
  Select
} from '@material-ui/core'
import ClassReportToolTip from 'components/shared/Report/reportTooltip'

import Pagination from '../../../utils/Pagination'
import { TA, TUTOR, PARENT } from '../../../constants/userTypes'
import LessonItem from 'components/shared/Classes/LessonItem'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  allLessons: state.lessons.allLessons,
  loadingLessons: state.lessons.loadingLessons,
  classPage: state.classes.classPage,
  selectedClass: state.classes.selectedClass,
  child: state.common.child,
  chosenClass: state.classes.chosenClass,
  classes: state.classes.classes,
  classCount: state.classes.classCount,
  classPage: state.classes.classPage
})

//try: update the class with next lesson time whenever the next lesson time changes.
// pull the next lesson time from the class
const mapDispatchToProps = dispatch => ({
  getAllLessons: tutorId => dispatch({ type: GET_TUTOR_LESSONS, payload: agent.Lessons.getAllLessons(tutorId) }),
  getStudentClass: userId => dispatch({ type: GET_USER_CLASS, payload: agent.Classes.getStudentClass(userId) }),
  getChild: userId => dispatch({ type: GET_CHILD_DETAILS, payload: agent.Auth.getChild(userId) }),
  getClassList: (classType, perPage, page, additional) =>
    dispatch({
      type: GET_CLASS_LIST,
      payload: agent.Classes.getClassList(classType, perPage, page, {
        sortBy: 'updated',
        order: 'desc',
        ...additional
      })
    }),
  setClass: classObject => dispatch({ type: SET_SELECTED_CLASS, selectedClass: classObject }),
  setPage: page => dispatch({ type: SET_CLASS_PAGE, payload: page })
})

class Classes extends React.Component {
  constructor(props) {
    super()
    this.viewLessons = selectedClass => {
      //this.props.setClass(selectedClass)
      let classDetailUrl =
        LESSON_PAGE.replace(':classId', selectedClass.class._id).replace(':lessonId', selectedClass._id) + '?tf=home'
      this.props.history.push(classDetailUrl)
    }

    this.changeSubject = e => {
      this.setState({ subject: e.target.value })
    }

    this.changeText = e => {
      this.setState({ name: e.target.value })
    }

    this.handleClick = event => {
      this.setState({ open: true, anchorEl: event.currentTarget })
    }

    this.handleRequestClose = () => {
      this.setState({ open: false })
    }

    this.viewClass = selectedClass => {
      //this.props.setClass(selectedClass)
      let classDetailUrl = MY_CLASS_DETAILS.replace(':classId', selectedClass._id)
      this.props.history.push(classDetailUrl)
    }

    this.onChangePage = page => {
      this.props.setPage(page)
      this.setState({ currentPage: page })
    }

    this.handleShowClass = index => {
      this.setState({
        indexClass: index
      })
    }

    this.state = {
      currentLessons: [],
      todayLessons: [],
      tomorrowLessons: [],
      d2ysAfterLessons: [],
      d3ysAfterLessons: [],
      d4ysAfterLessons: [],
      d5ysAfterLessons: [],
      d6ysAfterLessons: [],
      upcomingLessons: [],
      //-- for searching
      name: '',
      subject: '',
      searchQuery: null,
      //-- for searching
      anchorEl: null,
      open: false,
      options: {},
      series: [],
      optionsMastery: {},
      seriesMastery: [],
      indexClass: 0,
      classNow: []
    }
  }

  componentDidMount() {
    if (this.props.currentUser._type === 'Parent') {
      const { chosenClass } = this.props
      this.props.getStudentClass(this.props.currentUser.child)
      this.props.getChild(this.props.currentUser.child)
      this.setState({ classNow: chosenClass && chosenClass[0]?.lesson })
    } else {
      const { perPage, currentPage } = this.state
      this.props.getClassList('class', perPage, currentPage)
      this.props.getAllLessons(this.props.currentUser._id)
    }
    // past will be in class.
  }

  componentDidUpdate(prevProps, prevState) {
    // tutor view
    if (prevState.currentPage !== this.state.currentPage) {
      const { perPage, currentPage, searchQuery } = this.state

      if (searchQuery) {
        this.props.getClassList('class', perPage, currentPage, searchQuery)
      } else {
        this.props.getClassList('class', perPage, currentPage)
      }
    }

    if (this.props.loadingLessons !== prevProps.loadingLessons) {
      let now = moment()
      let closest = []
      let todayLessons = []
      let tomorrowLessons = []
      let d2ysAfterLessons = []
      let d3ysAfterLessons = []
      let d4ysAfterLessons = []
      let d5ysAfterLessons = []
      let d6ysAfterLessons = []
      let upcomingLessons = []
      // ALEX TODO: change to timetable
      // let monday = []
      // let tuesday = []
      // let wednesday = []
      // let thursday = []
      // let friday = []
      // let saturday = []
      // let sunday = []

      _.forEach(this.props.allLessons, ll => {
        if (moment(ll.scheduled_on) >= now) {
          closest.push(ll)
        } else if (moment(ll.scheduled_on) >= moment().startOf('day')) {
          closest.push(ll)
        } else if (moment(ll.scheduled_on) < moment(closest.scheduled_on) && moment(ll.scheduled_on) >= now) {
          closest.push(ll)
        }
      })

      let sortedClosest = _.orderBy(closest, ['scheduled_on', 'asc'])
      this.setState({
        currentLessons: sortedClosest
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().endOf('day'))) {
          todayLessons.push(ll)
        }
      })
      this.setState({
        todayLessons: todayLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().add(1, 'day').endOf('day'))) {
          tomorrowLessons.push(ll)
        }
      })
      this.setState({
        tomorrowLessons: tomorrowLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().add(2, 'day').endOf('day'))) {
          d2ysAfterLessons.push(ll)
        }
      })
      this.setState({
        d2ysAfterLessons: d2ysAfterLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().add(3, 'day').endOf('day'))) {
          d3ysAfterLessons.push(ll)
        }
      })
      this.setState({
        d3ysAfterLessons: d3ysAfterLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().add(4, 'day').endOf('day'))) {
          d4ysAfterLessons.push(ll)
        }
      })
      this.setState({
        d4ysAfterLessons: d4ysAfterLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().add(5, 'day').endOf('day'))) {
          d5ysAfterLessons.push(ll)
        }
      })
      this.setState({
        d5ysAfterLessons: d5ysAfterLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSame(moment().add(6, 'day').endOf('day'))) {
          d6ysAfterLessons.push(ll)
        }
      })
      this.setState({
        d6ysAfterLessons: d6ysAfterLessons
      })

      _.forEach(sortedClosest, ll => {
        if (moment(ll.scheduled_on).endOf('day').isSameOrAfter(moment().add(7, 'day').endOf('day'))) {
          upcomingLessons.push(ll)
        }
      })
      this.setState({
        upcomingLessons: upcomingLessons
      })
    }

    // parent view
    const { indexClass } = this.state
    const { chosenClass } = this.props

    if (prevProps.chosenClass !== chosenClass || prevState.indexClass !== this.state.indexClass) {
      let chosenLessons = chosenClass[indexClass]?.lesson

      let filteredHomeworkSubmission = chosenLessons?.filter(
        lessons => lessons?.lesson_meta?.homework.homeworkAssignment === 'y'
      )

      let filteredOnTimeHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '1'
      )

      let filteredLateHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '2'
      )

      let filteredNoHWS = filteredHomeworkSubmission?.filter(
        lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '3'
      )

      let lateHWSLength = filteredLateHWS?.length
      let noHWSLength = filteredNoHWS?.length
      let onTimeHWSLength = filteredOnTimeHWS?.length

      let filteredHWSTotalLength = onTimeHWSLength + lateHWSLength + noHWSLength

      let tot = (onTimeHWSLength / filteredHWSTotalLength) * 100
      let totlate = (lateHWSLength / filteredHWSTotalLength) * 100
      let totno = (noHWSLength / filteredHWSTotalLength) * 100

      let filteredOnTimeRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '1')

      let filteredLateRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '2')

      let filteredNoRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '3')

      let totalRNS = filteredOnTimeRNS?.length + filteredLateRNS?.length + filteredNoRNS?.length

      let totRNS = (filteredOnTimeRNS?.length / totalRNS) * 100
      let totlateRNS = (filteredLateRNS?.length / totalRNS) * 100
      let totnoRNS = (filteredNoRNS?.length / totalRNS) * 100

      let filteredEngagementLessons = chosenLessons?.filter(
        lessons =>
          lessons?.lesson_meta?.engagement?.engagementParticipation > 0 &&
          lessons?.lesson_meta?.engagement?.engagementDistracted > 0 &&
          lessons?.lesson_meta?.engagement?.engagementTiredBored > 0 &&
          lessons?.lesson_meta?.engagement?.engagementMotivate > 0 &&
          lessons?.lesson_meta?.engagement?.engagementAskQuestions > 0
      )

      let filteredMasteryLessons = chosenLessons?.filter(
        lessons =>
          lessons?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryConfused > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryParticipative > 0 &&
          lessons?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept > 0
      )

      const generateColors = data => {
        return data?.map((d, idx) => {
          let color = d >= 1 && d <= 2 ? '#ef4544' : d >= 3 && d <= 4 ? '#F28C28' : d >= 5 && d <= 6 ? '#50C878' : ''

          return {
            offset: (idx / data?.length) * 100,
            color,
            opacity: 1
          }
        })
      }

      this.setState({
        classNow: chosenLessons,
        ontime: tot,
        late: totlate,
        no: totno,
        totRNS: totRNS,
        totlateRNS: totlateRNS,
        totnoRNS: totnoRNS,
        options: {
          chart: {
            id: 'engagement'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              colorStops: generateColors(
                filteredEngagementLessons?.map(lesson =>
                  Math.round(
                    (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                      parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                      5
                  )
                )
              )
            }
          },
          xaxis: {
            categories: filteredEngagementLessons?.map(lessons => lessons.name.slice(6))
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: `Engagement level over ${filteredEngagementLessons?.length} lessons`,
            align: 'left'
          }
        },
        series: [
          {
            name: 'Engagement',
            data: filteredEngagementLessons?.map(lesson =>
              Math.round(
                (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                  parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                  parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                  parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                  parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                  5
              )
            )
          }
        ],
        optionsMastery: {
          chart: {
            id: 'mastery'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              colorStops: generateColors(
                filteredMasteryLessons?.map(lesson =>
                  Math.round(
                    (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                      parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                      5
                  )
                )
              )
            }
          },
          xaxis: {
            categories: filteredMasteryLessons?.map(lessons => lessons.name.slice(6))
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: `Mastery of skills over ${filteredMasteryLessons?.length} lessons`,
            align: 'left'
          }
        },
        seriesMastery: [
          {
            name: 'Mastery',
            data: filteredMasteryLessons?.map(lesson =>
              Math.round(
                (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                  parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                  parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                  parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                  parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                  5
              )
            )
          }
        ]
      })
    }
  }

  searchClasses = e => {
    e.preventDefault()
    const { name, subject, perPage } = this.state
    let query = {}
    if (name) {
      query.name = { $regex: `.*${name}.*`, $options: 'i' }
    }
    if (subject) {
      query.subject = subject
    }
    this.setState({ searchQuery: query })
    this.props.getClassList('class', perPage, 1, query)
  }

  resetSearch = () => {
    const { perPage, currentPage } = this.state
    this.setState({ name: '', subject: '', searchMode: null })
    this.props.getClassList('class', perPage, currentPage)
  }

  render() {
    const openDialog = Boolean(this.state.anchorEl)
    const { currentUser, chosenClass, child, loadingLessons, classes, classCount } = this.props
    const {
      //parent
      ontime,
      late,
      no,
      totRNS,
      totlateRNS,
      totnoRNS,
      punctualityDialog,
      cancellationDialog,
      homeworkDialog,
      revisionNotesDialog,
      engagementDialog,
      masteryDialog,
      classNow,
      indexClass,
      //tutor
      name,
      subject,
      currentLessons,
      todayLessons,
      tomorrowLessons,
      d2ysAfterLessons,
      d3ysAfterLessons,
      d4ysAfterLessons,
      d5ysAfterLessons,
      d6ysAfterLessons,
      upcomingLessons,
      currentPage,
      perPage
    } = this.state

    let chosenLessons = classNow

    let filteredAttendanceEarly = chosenLessons?.filter(lessons => lessons?.lesson_meta?.punctuality === 'n')
    let filteredAttendanceLate = chosenLessons?.filter(lessons => lessons?.lesson_meta?.punctuality === 'y')

    let filteredAttendanceTotal = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.punctuality === 'y' || lessons?.lesson_meta?.punctuality === 'n'
    )
    let attendanceEarly = (filteredAttendanceEarly?.length / filteredAttendanceTotal?.length) * 100
    let attendanceLate = (filteredAttendanceLate?.length / filteredAttendanceTotal?.length) * 100

    let filteredCancelledTotal = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.cancelledClass === 'y' || lessons?.lesson_meta?.cancelledClass === 'n'
    )

    let filteredCancelledNo = chosenLessons?.filter(lessons => lessons?.lesson_meta?.cancelledClass === 'n')
    let filteredCancelledActual = chosenLessons?.filter(lessons => lessons?.lesson_meta?.cancelledClass === 'y')

    let cancelledNo = (filteredCancelledNo?.length / filteredCancelledTotal?.length) * 100
    let cancelledActual = (filteredCancelledActual?.length / filteredCancelledTotal?.length) * 100

    let filteredHomeworkSubmission = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkAssignment === 'y'
    )

    let filteredOnTimeHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '1'
    )

    let filteredLateHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '2'
    )

    let filteredNoHWS = filteredHomeworkSubmission?.filter(
      lessons => lessons?.lesson_meta?.homework.homeworkSubmission === '3'
    )

    let filteredOnTimeRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '1')
    let filteredLateRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '2')
    let filteredNoRNS = chosenLessons?.filter(lessons => lessons?.lesson_meta?.revision_notes === '3')
    let totalRNS = filteredOnTimeRNS?.length + filteredLateRNS?.length + filteredNoRNS?.length

    let punctualityColor = '#000'
    let cancellationColor = '#000'
    let engagementColor = '#000'
    let engagementPercentage
    let masteryColor = '#000'
    let masteryPercentage
    let homeworkColor = '#000'
    let revisionNotesColor = '#000'

    if (attendanceEarly >= 0 && attendanceEarly <= 49) {
      punctualityColor = '#F44336'
    }
    if (attendanceEarly >= 50 && attendanceEarly <= 80) {
      punctualityColor = '#F28C28'
    }
    if (attendanceEarly >= 81 && attendanceEarly <= 100) {
      punctualityColor = '#50C878'
    }

    if (cancelledActual >= 0 && cancelledActual <= 49) {
      cancellationColor = '#50C878'
    }
    if (cancelledActual >= 50 && cancelledActual <= 80) {
      cancellationColor = '#F28C28'
    }
    if (cancelledActual >= 81 && cancelledActual <= 100) {
      cancellationColor = '#F44336'
    }

    let homeworkSubmissionTotal = ontime + late

    if (homeworkSubmissionTotal >= 0 && homeworkSubmissionTotal <= 49) {
      homeworkColor = '#F44336'
    }
    if (homeworkSubmissionTotal >= 50 && homeworkSubmissionTotal <= 80) {
      homeworkColor = '#F28C28'
    }
    if (homeworkSubmissionTotal >= 81 && homeworkSubmissionTotal <= 100) {
      homeworkColor = '#50C878'
    }

    let revisionSubmissionTotal = totRNS + totlateRNS

    if (revisionSubmissionTotal >= 0 && revisionSubmissionTotal <= 49) {
      revisionNotesColor = '#F44336'
    }
    if (revisionSubmissionTotal >= 50 && revisionSubmissionTotal <= 80) {
      revisionNotesColor = '#F28C28'
    }
    if (revisionSubmissionTotal >= 81 && revisionSubmissionTotal <= 100) {
      revisionNotesColor = '#50C878'
    }

    let sumEngagement = 0
    let sumMastery = 0
    let totalSumEngagement = 0
    let totalSumMastery = 0

    let filteredEngagementLessons = chosenLessons?.filter(
      lessons =>
        lessons?.lesson_meta?.engagement?.engagementParticipation > 0 &&
        lessons?.lesson_meta?.engagement?.engagementDistracted > 0 &&
        lessons?.lesson_meta?.engagement?.engagementTiredBored > 0 &&
        lessons?.lesson_meta?.engagement?.engagementMotivate > 0 &&
        lessons?.lesson_meta?.engagement?.engagementAskQuestions > 0
    )

    let filteredMasteryLessons = chosenLessons?.filter(
      lessons =>
        lessons?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryConfused > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryParticipative > 0 &&
        lessons?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept > 0
    )

    let filteredLessonTopics = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.lesson_topic !== '' || lessons?.lesson_meta?.lesson_topic !== undefined
    )

    let filteredLessonComments = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.comment.commentParentView && lessons?.lesson_meta?.comment.comment !== ''
    )

    let filteredLessonParentComments = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.comment?.commentParentView && lessons?.lesson_meta?.comment?.comment !== ''
    )
    let filteredRecommendations = chosenLessons?.filter(
      lessons => lessons?.lesson_meta?.recommendation.recommendationOthers !== ''
    )

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        {/* search bar */}
        {/* {(currentUser._type === TA || currentUser._type === TUTOR) && (
            <div className="my-3">
              <form onSubmit={this.searchClasses}>
                <div className="row">
                  <div className="col-md">
                    <TextField label={'Class Name'} value={name} onChange={this.changeText} fullWidth />
                  </div>
                  <div className="col">
                    <FormControl className="w-100 mb-2">
                      <InputLabel htmlFor="subject-selector">Subject</InputLabel>
                      <Select value={subject} onChange={this.changeSubject} input={<Input id="subject-selector" />}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'English'}>English</MenuItem>
                        <MenuItem value={'Mathematics'}>Mathematics</MenuItem>
                        <MenuItem value={'Science'}>Science</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <Button type={'submit'} variant={'contained'} color={'primary'}>
                      Search
                    </Button>
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <Button variant={'contained'} color={'default'} type={'button'} onClick={this.resetSearch}>
                      Reset
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )} */}
        {currentUser._type === PARENT ? (
          <Paper elevation={2} className="app-wrapper mb-3">
            <h2 className="mb-0">Child Profile</h2>
          </Paper>
        ) : (
          ''
        )}
        <div className="app-wrapper">
          {currentUser._type === PARENT ? (
            <>
              <div className="dashboard animated slideInUpTiny animation-duration-3">
                <div className="row">
                  {/* class details */}
                  {/* <div className="col-lg-6 col-12">
                    <Card className="mb-3">
                      <CardContent>
                        <h2>Class</h2>
                        <div className="row align-items-center">
                          <div className="col-md-5 col-5 my-2 font-weight-medium">Currently enrolled class</div>
                          <div className="col-md-7 col-7 my-2">{child?.tbd ?? '---'}</div>

                          <div className="col-md-5 col-5 my-2 font-weight-medium">Date of upcoming exams</div>
                          <div className="col-md-7 col-7 my-2">{child?.tbd ?? '---'}</div>

                          <div className="col-md-5 col-5 my-2 font-weight-medium">Exam target score</div>
                          <div className="col-md-7 col-7 my-2">{child?.tbd ?? '---'}</div>

                          <div className="col-md-5 col-5 my-2 font-weight-medium">Exam target score (tutor)</div>
                          <div className="col-md-7 col-7 my-2">{child?.tbd ?? '---'}</div>

                          <div className="col-md-5 col-5 my-2 font-weight-medium">Exam result</div>
                          <div className="col-md-7 col-7 my-2">{child?.tbd ?? '---'}</div>
                        </div>
                      </CardContent>
                    </Card>
                  </div> */}

                  <div className="col-12">
                    {/* <Card className="mb-3">
                      <CardContent> */}
                    {/* <h2>Lessons</h2> */}
                    <Card className="mb-3">
                      <CardContent>
                        <div className="row">
                          <div className="col-12 row justify-content-around">
                            {chosenClass ? (
                              chosenClass?.map((classes, index) => (
                                <div key={index} className="col-md-3 col-12">
                                  <div
                                    style={{
                                      fontSize: 25,
                                      minHeight: '120px',
                                      backgroundColor: index === indexClass ? 'lightgreen' : '',
                                      textAlign: 'center'
                                    }}
                                    className="col-md-12 col-12 my-2 py-4 box-dashboard"
                                    onClick={() => this.handleShowClass(index)}>
                                    {classes?.name.slice(6)}
                                    {' ('}
                                    {classes?.tutor[0]?.name}
                                    {') '}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <CircularProgress />
                            )}
                          </div>
                        </div>
                      </CardContent>
                    </Card>

                    <ClassReportToolTip
                      currentUser={currentUser}
                      options={this.state.options}
                      series={this.state.series}
                      optionsMastery={this.state.optionsMastery}
                      seriesMastery={this.state.seriesMastery}
                      punctualityColor={punctualityColor}
                      attendanceEarly={attendanceEarly}
                      filteredAttendanceLate={filteredAttendanceLate}
                      filteredAttendanceTotal={filteredAttendanceTotal}
                      cancellationColor={cancellationColor}
                      cancelledActual={cancelledActual}
                      filteredCancelledActual={filteredCancelledActual}
                      homeworkColor={homeworkColor}
                      homeworkSubmissionTotal={homeworkSubmissionTotal}
                      filteredOnTimeHWS={filteredOnTimeHWS}
                      filteredLateHWS={filteredLateHWS}
                      filteredNoHWS={filteredNoHWS}
                      filteredLateRNS={filteredLateRNS}
                      filteredNoRNS={filteredNoRNS}
                      revisionNotesColor={revisionNotesColor}
                      revisionSubmissionTotal={revisionSubmissionTotal}
                      filteredOnTimeRNS={filteredOnTimeRNS}
                      filteredEngagementLessons={filteredEngagementLessons}
                      engagementColor={engagementColor}
                      filteredMasteryLessons={filteredMasteryLessons}
                      masteryColor={masteryColor}
                      filteredLessonParentComments={filteredLessonParentComments}
                      filteredLessonComments={filteredLessonComments}
                      filteredCancelledTotal={filteredCancelledTotal}
                      filteredLessonTopics={filteredLessonTopics}
                    />
                    {/* </CardContent>
                    </Card> */}
                  </div>

                  <div className="mt-3 col-lg-12 col-12" style={{ fontSize: isMobile ? '' : '20px' }}>
                    <Card className="mb-3">
                      <CardContent>
                        <h2>School & Academic</h2>
                        <div className="row align-items-center">
                          <div className="col-md-1 col-5 my-2 font-weight-medium">Current School</div>
                          <div className="col-md-11 col-7 my-2">{child?.school ?? '---'}</div>

                          {child?.typeOfSchool && (
                            <>
                              <div className="col-md-1 col-5 my-2 font-weight-medium">Type of School</div>
                              <div className="col-md-11 col-7 my-2">
                                {child?.typeOfSchool === 'international'
                                  ? 'International'
                                  : child?.typeOfSchool === 'government'
                                  ? 'Government'
                                  : child?.typeOfSchool}
                              </div>
                            </>
                          )}

                          <div className="col-md-1 col-5 my-2 font-weight-medium">Level: Year / Grade</div>
                          <div className="col-md-11 col-7 mt-2">
                            {child?.level === 'grade' ? 'Grade' : child?.level === 'year' ? 'Year' : 'Others: '}{' '}
                            {child?.level === 'grade'
                              ? child?.gradeYearLevel?.grade
                              : child?.level === 'year'
                              ? child?.gradeYearLevel?.year
                              : child?.level}
                          </div>

                          <div className="col-md-1 col-5 my-2 font-weight-medium">Medium of Study</div>
                          <div className="col-md-11 col-7 my-2">
                            {`${child?.studyMediumEng === true ? 'English' : '---'} ${
                              child?.studyMediumThai === true ? 'Thai' : ''
                            }`}
                          </div>

                          <div className="col-md-1 col-5 mt-md-5 my-2 font-weight-medium">Grades (GPA)</div>

                          {/* mobile view */}
                          <div className="row col-md-11 col-12 d-md-none d-flex">
                            {child?.grade.gpa.art && (
                              <div className="col-md-1 col-auto my-2">
                                Art
                                <br /> {child?.grade.gpa.art}
                              </div>
                            )}
                            {child?.grade.gpa.biology && (
                              <div className="col-md-1 col-auto my-2">
                                Biology <br />
                                {child?.grade.gpa.biology}
                              </div>
                            )}
                            {child?.grade.gpa.chemistry && (
                              <div className="col-md-1 col-auto my-2">
                                Chemistry <br />
                                {child?.grade.gpa.chemistry}
                              </div>
                            )}
                            {child?.grade.gpa.economics && (
                              <div className="col-md-1 col-auto my-2">
                                Economics <br />
                                {child?.grade.gpa.economics}
                              </div>
                            )}
                            {child?.grade.gpa.english && (
                              <div className="col-md-1 col-auto my-2">
                                English <br />
                                {child?.grade.gpa.english}
                              </div>
                            )}
                            {child?.grade.gpa.foreign_language && (
                              <div className="col-md-1 col-auto my-2">
                                Foreign Language
                                <br />
                                {child?.grade.gpa.foreign_language}
                              </div>
                            )}
                            {child?.grade.gpa.geography && (
                              <div className="col-md-1 col-auto my-2">
                                Geography <br />
                                {child?.grade.gpa.geography}
                              </div>
                            )}
                            {child?.grade.gpa.history && (
                              <div className="col-md-1 col-auto my-2">
                                History <br />
                                {child?.grade.gpa.history}
                              </div>
                            )}
                            {child?.grade.gpa.literature && (
                              <div className="col-md-1 col-auto my-2">
                                Literature <br />
                                {child?.grade.gpa.literature}
                              </div>
                            )}
                            {child?.grade.gpa.math && (
                              <div className="col-md-1 col-auto my-2">
                                Math <br />
                                {child?.grade.gpa.math}
                              </div>
                            )}
                            {child?.grade.gpa.physics && (
                              <div className="col-md-1 col-auto my-2">
                                Physics <br />
                                {child?.grade.gpa.physics}
                              </div>
                            )}
                            {child?.grade.gpa.politics && (
                              <div className="col-md-1 col-auto my-2">
                                Politics <br />
                                {child?.grade.gpa.politics}
                              </div>
                            )}
                            {child?.grade.gpa.social_studies && (
                              <div className="col-md-1 col-auto my-2">
                                Social studies <br />
                                {child?.grade.gpa.social_studies}
                              </div>
                            )}
                            {child?.grade.gpa.thai && (
                              <div className="col-md-1 col-auto my-2">
                                Thai <br />
                                {child?.grade.gpa.thai}
                              </div>
                            )}
                            {child?.grade.gpa.others && child?.grade.gpa.others_type && (
                              <div className="col-md-1 col-auto my-2">
                                Others <br />
                                {child?.grade.gpa.others_type}
                              </div>
                            )}
                          </div>

                          {/* desktop view */}
                          <div className="row col-md-11 col-12 d-md-flex d-none">
                            {child?.grade.art && (
                              <div className="col-md-1 col-auto my-2 bt-grades bl-grades br-grades bb-grades">
                                Art <br /> <br />
                                {child?.grade.gpa.art}
                              </div>
                            )}
                            {child?.grade.biology && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Biology <br />
                                <br /> {child?.grade.gpa.biology}
                              </div>
                            )}
                            {child?.grade.chemistry && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Chemistry <br /> <br />
                                {child?.grade.gpa.chemistry}
                              </div>
                            )}
                            {child?.grade.economics && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Economics
                                <br /> <br />
                                {child?.grade.gpa.economics}
                              </div>
                            )}
                            {child?.grade.english && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                English
                                <br /> <br />
                                {child?.grade.gpa.english}
                              </div>
                            )}
                            {child?.grade.foreign_language && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Foreign Language {child?.grade.gpa.foreign_language}
                              </div>
                            )}
                            {child?.grade.geography && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Geography <br /> <br />
                                {child?.grade.gpa.geography}
                              </div>
                            )}
                            {child?.grade.history && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                History
                                <br /> <br /> {child?.grade.gpa.history}
                              </div>
                            )}
                            {child?.grade.literature && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Literature
                                <br /> <br />
                                {child?.grade.gpa.literature}{' '}
                              </div>
                            )}
                            {child?.grade.math && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Math <br /> <br />
                                {child?.grade.gpa.math}
                              </div>
                            )}
                            {child?.grade.physics && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Physics
                                <br /> <br />
                                {child?.grade.gpa.physics}
                              </div>
                            )}
                            {child?.grade.politics && (
                              <div className="col-md-1 col-auto my-2 bt-grades br-grades bb-grades">
                                Politics
                                <br /> <br />
                                {child?.grade.gpa.politics}
                              </div>
                            )}
                            {child?.grade.social_studies && (
                              <div className="col-md-1 col-auto my-2 bl-grades bt-grades br-grades bb-grades">
                                Social studies <br /> {child?.grade.gpa.social_studies}
                              </div>
                            )}
                            {child?.grade.thai && (
                              <div className="col-md-1 col-auto my-2 bl-grades bt-grades br-grades bb-grades">
                                Thai <br /> <br /> {child?.grade.gpa.thai}
                              </div>
                            )}
                            {child?.grade.others && (
                              <div className="col-md-1 col-auto my-2 bl-grades bt-grades br-grades bb-grades">
                                Others <br /> <br />
                                {child?.grade.gpa.others_type}
                              </div>
                            )}
                          </div>

                          <div className="col-md-1 col-5 my-2 font-weight-medium">Favorite Subject(s)</div>
                          <div className="row col-md-11 col-12">
                            {child?.favoriteSubjects.art && <div className="col-md-1 col-auto my-2">Art </div>}
                            {child?.favoriteSubjects.biology && <div className="col-md-1 col-auto my-2">Biology </div>}
                            {child?.favoriteSubjects.chemistry && (
                              <div className="col-md-1 col-auto my-2">Chemistry</div>
                            )}
                            {child?.favoriteSubjects.economics && (
                              <div className="col-md-1 col-auto my-2">Economics</div>
                            )}
                            {child?.favoriteSubjects.english && <div className="col-md-1 col-auto my-2">English</div>}
                            {child?.favoriteSubjects.history && <div className="col-md-1 col-auto my-2">History</div>}
                            {child?.favoriteSubjects.literature && (
                              <div className="col-md-1 col-auto my-2">Literature</div>
                            )}
                            {child?.favoriteSubjects.math && <div className="col-md-1 col-auto my-2">Math</div>}
                            {child?.favoriteSubjects.foreign_language && (
                              <div className="col-md-1 col-auto my-2">Foreign Language</div>
                            )}
                            {child?.favoriteSubjects.geography && (
                              <div className="col-md-1 col-auto my-2">Geography</div>
                            )}
                            {child?.favoriteSubjects.physics && <div className="col-md-1 col-auto my-2">Physics</div>}
                            {child?.favoriteSubjects.politics && <div className="col-md-1 col-auto my-2">Politics</div>}
                            {child?.favoriteSubjects.social_studies && (
                              <div className="col-md-1 col-auto my-2">Social studies</div>
                            )}
                            {child?.favoriteSubjects.thai && <div className="col-md-1 col-auto my-2">Thai</div>}
                            {child?.favoriteSubjects.others_type && (
                              <div className="col-md-1 col-auto my-2">{child?.favoriteSubjects.others_type} </div>
                            )}
                          </div>
                          <div className="col-md-1 col-5 my-2 font-weight-medium">Weakest Subject(s)</div>
                          <div className="row col-md-11 col-12">
                            {child?.weakestSubjects.art && <div className="col-md-1 col-auto my-2">Art </div>}
                            {child?.weakestSubjects.biology && <div className="col-md-1 col-auto my-2">Biology </div>}
                            {child?.weakestSubjects.chemistry && (
                              <div className="col-md-1 col-auto my-2">Chemistry </div>
                            )}
                            {child?.weakestSubjects.economics && (
                              <div className="col-md-1 col-auto my-2">Economics</div>
                            )}
                            {child?.weakestSubjects.english && <div className="col-md-1 col-auto my-2">English</div>}
                            {child?.weakestSubjects.history && <div className="col-md-1 col-auto my-2">History</div>}
                            {child?.weakestSubjects.literature && (
                              <div className="col-md-1 col-auto my-2">Literature</div>
                            )}
                            {child?.weakestSubjects.math && <div className="col-md-1 col-auto my-2">Math</div>}
                            {child?.weakestSubjects.foreign_language && (
                              <div className="col-md-1 col-auto my-2">Foreign Language</div>
                            )}
                            {child?.weakestSubjects.geography && (
                              <div className="col-md-1 col-auto my-2">Geography</div>
                            )}
                            {child?.weakestSubjects.physics && <div className="col-md-1 col-auto my-2">Physics </div>}
                            {child?.weakestSubjects.politics && <div className="col-md-1 col-auto my-2">Politics </div>}
                            {child?.weakestSubjects.social_studies && (
                              <div className="col-md-1 col-auto my-2">Social studies</div>
                            )}
                            {child?.weakestSubjects.thai && <div className="col-md-1 col-auto my-2">Thai</div>}
                            {child?.weakestSubjects.others_type && (
                              <div className="col-md-1 col-auto my-2">{child?.weakestSubjects.others_type} </div>
                            )}
                          </div>
                          <div className="col-md-1 col-5 my-2 font-weight-medium">Type of external test taken</div>
                          <div className="row col-md-11 col-12">
                            {child?.externalExams.gmat && <div className="col-md-1 col-auto my-2">GMAT</div>}
                            {child?.externalExams.gre && <div className="col-md-1 col-auto my-2">GRE</div>}
                            {child?.externalExams.ietls && <div className="col-md-1 col-auto my-2">IELTS</div>}
                            {child?.externalExams.sat && <div className="col-md-1 col-auto my-2">SAT</div>}
                            {child?.externalExams.toefl && <div className="col-md-1 col-auto my-2">TOEFL</div>}
                            {child?.externalExams.others_type && (
                              <div className="col-md-1 col-auto my-2">{child?.externalExams.others_type} </div>
                            )}
                          </div>
                          <div className="col-md-1 col-5 my-2 font-weight-medium">
                            Type of course taken at other tuition centre
                          </div>
                          <div className="row col-md-11 col-12">
                            {child?.coursesTuition.art && (
                              <div className="col-md-1 col-auto my-2">
                                Art <br /> {child?.coursesTuition.art}
                              </div>
                            )}
                            {child?.coursesTuition.biology && (
                              <div className="col-md-1 col-auto my-2">
                                Biology <br />
                                {child?.coursesTuition.biology}
                              </div>
                            )}
                            {child?.coursesTuition.chemistry && (
                              <div className="col-md-1 col-auto my-2">
                                Chemistry <br />
                                {child?.coursesTuition.chemistry}
                              </div>
                            )}
                            {child?.coursesTuition.economics && (
                              <div className="col-md-1 col-auto my-2">
                                Economics <br />
                                {child?.coursesTuition.economics}
                              </div>
                            )}
                            {child?.coursesTuition.english && (
                              <div className="col-md-1 col-auto my-2">English {child?.coursesTuition.english}</div>
                            )}
                            {child?.coursesTuition.foreign_language && (
                              <div className="col-md-1 col-auto my-2">
                                Foreign Language <br />
                                {child?.coursesTuition.foreign_language}
                              </div>
                            )}
                            {child?.coursesTuition.geography && (
                              <div className="col-md-1 col-auto my-2">
                                Geography <br />
                                {child?.coursesTuition.geography}
                              </div>
                            )}
                            {child?.coursesTuition.history && (
                              <div className="col-md-1 col-auto my-2">History {child?.coursesTuition.history}</div>
                            )}
                            {child?.coursesTuition.literature && (
                              <div className="col-md-1 col-auto my-2">
                                Literature {child?.coursesTuition.literature}
                              </div>
                            )}

                            {child?.coursesTuition.math && (
                              <div className="col-md-1 col-auto my-2">Math {child?.coursesTuition.math}</div>
                            )}

                            {child?.coursesTuition.physics && (
                              <div className="col-md-1 col-auto my-2">
                                Physics <br />
                                {child?.coursesTuition.physics}
                              </div>
                            )}
                            {child?.coursesTuition.politics && (
                              <div className="col-md-1 col-auto my-2">
                                Politics <br />
                                {child?.coursesTuition.politics}
                              </div>
                            )}
                            {child?.coursesTuition.social_studies && (
                              <div className="col-md-1 col-auto my-2">
                                Social studies <br />
                                {child?.coursesTuition.social_studies}
                              </div>
                            )}
                            {child?.coursesTuition.thai && (
                              <div className="col-md-1 col-auto my-2">Thai {child?.coursesTuition.thai}</div>
                            )}
                            {child?.coursesTuition.others_type && (
                              <div className="col-md-1 col-auto my-2">
                                Others <br />
                                {child?.coursesTuition.others_type}
                              </div>
                            )}
                          </div>
                          <div className="col-md-1 col-5 my-2 font-weight-medium">Type of course taken at EPA</div>
                          <div className="row col-md-11 col-12">
                            {child?.currentlyAtEPA.coursesTakenAtEPA.art && (
                              <div className="col-md-1 col-auto my-2">
                                Art <br /> {child?.currentlyAtEPA.coursesTakenAtEPA.art}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.biology && (
                              <div className="col-md-1 col-auto my-2">
                                Biology <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.biology}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.chemistry && (
                              <div className="col-md-1 col-auto my-2">
                                Chemistry <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.chemistry}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.economics && (
                              <div className="col-md-1 col-auto my-2">
                                Economics <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.economics}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.english && (
                              <div className="col-md-1 col-auto my-2">
                                English {child?.currentlyAtEPA.coursesTakenAtEPA.english}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.foreign_language && (
                              <div className="col-md-1 col-auto my-2">
                                Foreign Language <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.foreign_language}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.geography && (
                              <div className="col-md-1 col-auto my-2">
                                Geography <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.geography}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.history && (
                              <div className="col-md-1 col-auto my-2">
                                History {child?.currentlyAtEPA.coursesTakenAtEPA.history}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.literature && (
                              <div className="col-md-1 col-auto my-2">
                                Literature {child?.currentlyAtEPA.coursesTakenAtEPA.literature}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.math && (
                              <div className="col-md-1 col-auto my-2">
                                Math {child?.currentlyAtEPA.coursesTakenAtEPA.math}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.physics && (
                              <div className="col-md-1 col-auto my-2">
                                Physics <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.physics}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.politics && (
                              <div className="col-md-1 col-auto my-2">
                                Politics <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.politics}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.social_studies && (
                              <div className="col-md-1 col-auto my-2">
                                Social studies <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.social_studies}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.thai && (
                              <div className="col-md-1 col-auto my-2">
                                Thai {child?.currentlyAtEPA.coursesTakenAtEPA.thai}
                              </div>
                            )}
                            {child?.currentlyAtEPA.coursesTakenAtEPA.others_type && (
                              <div className="col-md-1 col-auto my-2">
                                Others <br />
                                {child?.currentlyAtEPA.coursesTakenAtEPA.others_type}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="d-flex flex-wrap mt-3">
                          {/* <Button
                    variant="contained"
                    color="primary"
                    className="text-white mr-2 mb-2"
                    component={Link}
                    to={USER_PARENT_PROFILE_EDIT}>
                    Update my details
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-white mr-2 mb-2"
                    component={Link}
                    to={USER_PASSWORD_EDIT}>
                    Change my password
                  </Button> */}
                        </div>
                      </CardContent>
                    </Card>
                  </div>

                  <div className="col-lg-6 col-12" style={{ fontSize: isMobile ? '' : '20px' }}>
                    <Card className="mb-3">
                      <CardContent>
                        <h2>Academic & Career Goal</h2>
                        <div className="row align-items-center">
                          <div className="col-md-2 col-5 my-2 font-weight-medium">Career Goal</div>
                          <div className="col-md-10 col-7 my-2">{child?.careerGoals.student ?? '---'}</div>

                          <div className="col-md-2 col-5 my-2 font-weight-medium">Study Goal</div>
                          <div className="row col-md-10 col-12">
                            {child?.studyGoals.student.entrance && (
                              <div className="col-auto my-2">{child?.studyGoals.student.entrance_type}</div>
                            )}
                            {child?.studyGoals.student.gmat && <div className="col-auto my-2">GMAT</div>}
                            {child?.studyGoals.student.gre && <div className="col-auto my-2">GRE</div>}
                            {child?.studyGoals.student.ietls && <div className="col-auto my-2">IELTS</div>}
                            {child?.studyGoals.student.sat && <div className="col-auto my-2">SAT</div>}
                            {child?.studyGoals.student.toefl && <div className="col-auto my-2">TOEFL</div>}
                            {child?.studyGoals.student.others && (
                              <div className="col-auto my-2">{child?.studyGoals.student.others_type} </div>
                            )}
                          </div>

                          <div className="col-md-2 col-5 my-2 font-weight-medium">Country of target university</div>
                          <div className="row col-md-10 col-12">
                            {child?.targetCountry?.student?.us && <div className="col-auto my-2">US</div>}
                            {child?.targetCountry?.student?.uk && <div className="col-auto my-2">UK</div>}
                            {child?.targetCountry?.student?.canada && <div className="col-auto my-2">Canada</div>}
                            {child?.targetCountry?.student?.aus && <div className="col-auto my-2">Australia</div>}
                            {child?.targetCountry?.student?.nz && <div className="col-auto my-2">New Zealand</div>}
                            {child?.targetCountry?.student?.others && child?.targetCountry?.student?.others_type && (
                              <div className="col-auto my-2">{child?.targetCountry?.student?.others_type}</div>
                            )}
                          </div>

                          <div className="col-md-2 col-5 my-2 font-weight-medium">Tentative area of study</div>
                          <div className="row col-md-10 col-12">
                            {!child?.areaOfStudy?.unsure && child?.areaOfStudy?.area && (
                              <div className="col-md-12 col-auto my-2">
                                {child?.areaOfStudy?.area} - {child?.areaOfStudy?.specific}
                              </div>
                            )}
                            {!child?.areaOfStudy?.unsure && child?.areaOfStudy?.area1 && (
                              <div className="col-md-12 col-auto my-2">
                                {child?.areaOfStudy?.area1} - {child?.areaOfStudy?.specific1}
                              </div>
                            )}
                            {!child?.areaOfStudy?.unsure && child?.areaOfStudy?.area2 && (
                              <div className="col-md-12 col-auto my-2">
                                {child?.areaOfStudy?.area2} - {child?.areaOfStudy?.specific2}
                              </div>
                            )}
                            {!child?.areaOfStudy?.unsure && child?.areaOfStudy?.area3 && (
                              <div className="col-md-12 col-auto my-2">
                                {child?.areaOfStudy?.area3} - {child?.areaOfStudy?.specific3}
                              </div>
                            )}
                            {!child?.areaOfStudy?.unsure && child?.areaOfStudy?.area4 && (
                              <div className="col-md-12 col-auto my-2">
                                {child?.areaOfStudy?.area4} - {child?.areaOfStudy?.specific4}
                              </div>
                            )}
                            {child?.areaOfStudy?.unsure && <div className="col-md-12 col-auto my-2">Unsure</div>}
                            {!child?.areaOfStudy?.unsure && child?.areaOfStudy?.others && (
                              <div className="col-md-12 col-auto my-2">{child?.areaOfStudy?.others_type}</div>
                            )}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>

                  <div className="col-lg-6 col-12" style={{ fontSize: isMobile ? '' : '20px' }}>
                    <Card className="mb-3">
                      <CardContent>
                        <h2>Basic Information</h2>
                        <div className="row align-items-center">
                          {/* <div className="col-md-3 col-5 my-2 font-weight-medium">Email</div>
                          <div className="col-md-9 col-7 my-2">{child?.email}</div> */}

                          <div className="col-md-3 col-5 my-2 font-weight-medium">Name</div>
                          <div className="col-md-9 col-7 my-2">{child?.name}</div>

                          <div className="col-md-3 col-5 my-2 font-weight-medium">Date of birth</div>
                          <div className="col-md-9 col-7 my-2">
                            {child?.date_of_birth ? moment(child?.date_of_birth).format('ll') : '---'}
                          </div>

                          <div className="col-md-3 col-5 my-2 font-weight-medium">Gender</div>
                          <div className="col-md-9 col-7 my-2">{child?.gender}</div>

                          <div className="col-md-3 col-5 my-2 font-weight-medium">Phone number</div>
                          <div className="col-md-9 col-7 my-2">
                            {child?.phone ? (
                              <span>
                                (+{child?.phone_country_code}) {child?.phone}
                              </span>
                            ) : (
                              '---'
                            )}
                          </div>

                          <div className="col-md-3 col-5 my-2 font-weight-medium">Social Media</div>
                          <div className="col-md-9 col-7 my-2">
                            {child?.facebook ?? '---'} / {child?.line ?? '---'}
                          </div>

                          <div className="col-md-3 col-5 my-2 font-weight-medium">Contact Preference</div>
                          <div className="col-md-9 col-7 my-2">
                            {child?.contact_preference.contact === 'nil'
                              ? '---'
                              : child?.contact_preference.contact === 'others'
                              ? child?.contact_preference.others_type
                              : child?.contact_preference.contact === 'line'
                              ? 'Line'
                              : child?.contact_preference.contact === 'whatsapp'
                              ? 'Whatsapp'
                              : ''}{' '}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
              <Button variant="contained" color="primary" className="text-white mr-2 mb-2">
                <Link
                  style={{ color: 'white' }}
                  to={{
                    pathname: USER_STUDENT_PROFILE_EDIT,
                    meta: child
                  }}>
                  Update child profile
                </Link>
              </Button>

              {/* component={Link}
                to={USER_STUDENT_PROFILE_EDIT}>
                Update child profile */}
            </>
          ) : (
            ''
          )}

          {currentUser._type === TA && (
            <div className="my-3">
              <form onSubmit={this.searchClasses}>
                <div className="row">
                  <div className="col-md">
                    <TextField label={'Class Name'} value={name} onChange={this.changeText} fullWidth />
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <Button type={'submit'} variant={'contained'} color={'primary'}>
                      Search
                    </Button>
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <Button variant={'contained'} color={'default'} type={'button'} onClick={this.resetSearch}>
                      Reset
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {currentUser._type === TA && classes ? (
            classes.length > 0 ? (
              <div className="grid__renderer">
                {classes.map((classData, index) => {
                  return (
                    <ClassItem
                      key={index}
                      className={'grid__item'}
                      classData={classData}
                      viewClass={() => {
                        this.viewClass(classData)
                      }}
                      isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                    />
                  )
                })}
              </div>
            ) : (
              <h2>There are no classes</h2>
            )
          ) : (
            ''
          )}
          {currentUser._type === TA && !!classCount && (
            <div className="mt-3">
              <Pagination
                itemCount={classCount}
                pageSize={18}
                initialPage={currentPage}
                onChangePage={this.onChangePage}
              />
            </div>
          )}

          {currentUser._type === PARENT ? (
            ''
          ) : loadingLessons ? (
            currentLessons.length > 0 ? (
              <div className="grid__renderer">
                <div className="flex col-12 mb-4">
                  <h3> {moment().format('LLLL').slice(0, -8)} (Today)</h3>
                  <div className="row">
                    {todayLessons.length > 0 ? (
                      todayLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                            isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons for today</h4>
                    )}
                  </div>
                </div>
                {todayLessons.length > 0 ? (
                  ''
                ) : (
                  <div className="col-12 mb-5" style={{ backgroundColor: '#C5C5C5' }}>
                    {' '}
                  </div>
                )}
                <div className="flex col-12 mb-4">
                  <h3> {moment().add(1, 'day').format('LLLL').slice(0, -8)} (Tomorrow)</h3>
                  <div className="row">
                    {tomorrowLessons.length > 0 ? (
                      tomorrowLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                            isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons for tomorrow</h4>
                    )}
                  </div>{' '}
                </div>
                {tomorrowLessons.length > 0 ? (
                  ''
                ) : (
                  <div className="col-12 mb-5" style={{ backgroundColor: '#C5C5C5' }}>
                    {' '}
                  </div>
                )}

                <div className="flex col-12 mb-4">
                  <h3> {moment().add(2, 'day').format('LLLL').slice(0, -8)}</h3>
                  <div className="row">
                    {d2ysAfterLessons.length > 0 ? (
                      d2ysAfterLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                            isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons on {moment().add(2, 'day').format('LLLL').slice(0, -8)}</h4>
                    )}
                  </div>{' '}
                </div>
                {d2ysAfterLessons.length > 0 ? (
                  ''
                ) : (
                  <div className="col-12 mb-5" style={{ backgroundColor: '#C5C5C5' }}>
                    {' '}
                  </div>
                )}

                <div className="flex col-12 mb-4">
                  <h3> {moment().add(3, 'day').format('LLLL').slice(0, -8)}</h3>
                  <div className="row">
                    {d3ysAfterLessons.length > 0 ? (
                      d3ysAfterLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                            isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons on {moment().add(3, 'day').format('LLLL').slice(0, -8)}</h4>
                    )}
                  </div>{' '}
                </div>
                {d3ysAfterLessons.length > 0 ? (
                  ''
                ) : (
                  <div className="col-12 mb-5" style={{ backgroundColor: '#C5C5C5' }}>
                    {' '}
                  </div>
                )}

                <div className="flex col-12 mb-4">
                  <h3> {moment().add(4, 'day').format('LLLL').slice(0, -8)}</h3>
                  <div className="row">
                    {d4ysAfterLessons.length > 0 ? (
                      d4ysAfterLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                            isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons on {moment().add(4, 'day').format('LLLL').slice(0, -8)}</h4>
                    )}
                  </div>{' '}
                </div>
                {d4ysAfterLessons.length > 0 ? (
                  ''
                ) : (
                  <div className="col-12 mb-5" style={{ backgroundColor: '#C5C5C5' }}>
                    {' '}
                  </div>
                )}

                <div className="flex col-12 mb-4">
                  <h3> {moment().add(5, 'day').format('LLLL').slice(0, -8)}</h3>
                  <div className="row">
                    {d5ysAfterLessons.length > 0 ? (
                      d5ysAfterLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                            isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons on {moment().add(5, 'day').format('LLLL').slice(0, -8)}</h4>
                    )}
                  </div>{' '}
                </div>
                {d5ysAfterLessons.length > 0 ? (
                  ''
                ) : (
                  <div className="col-12 mb-5" style={{ backgroundColor: '#C5C5C5' }}>
                    {' '}
                  </div>
                )}

                <div className="flex col-12 mb-4">
                  <h3> {moment().add(6, 'day').format('LLLL').slice(0, -8)}</h3>
                  <div className="row">
                    {d6ysAfterLessons.length > 0 ? (
                      d6ysAfterLessons.map((classData, index) => {
                        return (
                          <LessonItem
                            key={index}
                            className={'grid__item'}
                            classData={classData}
                            viewClass={() => {
                              this.viewLessons(classData)
                            }}
                            isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                          />
                        )
                      })
                    ) : (
                      <h4 className="mt-2 ml-3">No Lessons on {moment().add(6, 'day').format('LLLL').slice(0, -8)}</h4>
                    )}
                  </div>{' '}
                </div>
                {d6ysAfterLessons.length > 0 ? (
                  ''
                ) : (
                  <div className="col-12 mb-5" style={{ backgroundColor: '#C5C5C5' }}>
                    {' '}
                  </div>
                )}

                <div className="flex col-12">
                  <h3>Upcoming Lessons</h3>{' '}
                  <div className="row">
                    {upcomingLessons.map((classData, index) => {
                      return (
                        <LessonItem
                          key={index}
                          className={'grid__item'}
                          classData={classData}
                          viewClass={() => {
                            this.viewLessons(classData)
                          }}
                          isTutor={currentUser._type === TUTOR || currentUser._type === TA}
                        />
                      )
                    })}
                  </div>{' '}
                </div>
              </div>
            ) : (
              currentUser._type !== TA && <h2>You are not enrolled in any classes</h2>
            )
          ) : (
            <div className="my-5 text-center">
              <CircularProgress size={40} color={'primary'} />
            </div>
          )}
        </div>

        {/* <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }}>
          {filteredAttendanceLate?.map((lesson, index) => (
            <MenuItem key={index}>
              <div>
                {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
              </div>
            </MenuItem>
          ))}
        </Menu> */}

        <Popover
          id="mouse-over-popover"
          style={{ borderRadius: '20px' }}
          open={openDialog}
          onClose={this.handlePopoverClose}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          disableRestoreFocus>
          {' '}
          <div>
            {punctualityDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  late to these classes
                </div>
                {filteredAttendanceLate?.map((lesson, index) => (
                  <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                ))}{' '}
              </>
            )}

            {cancellationDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  cancelled classes
                </div>
                {filteredCancelledActual?.map((lesson, index) => (
                  <MenuItem key={index}>
                    {lesson.name}, {moment(lesson.scheduled_on).format('LL')}
                  </MenuItem>
                ))}{' '}
              </>
            )}

            {homeworkDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {`${filteredLateHWS?.length !== 0 ? filteredLateHWS?.length : ' no'} late submission `}
                </div>
                {filteredLateHWS?.map((lesson, index) => (
                  <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                ))}
                <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                  {filteredNoHWS?.length !== 0 ? `${filteredNoHWS?.length} did not submit` : ''}
                </div>
                {filteredNoHWS?.map((lesson, index) => (
                  <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                ))}
              </>
            )}

            {revisionNotesDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {`${filteredLateRNS?.length !== 0 ? filteredLateRNS?.length : ' no'} late submission `}
                </div>
                {filteredLateRNS?.map((lesson, index) => (
                  <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                ))}
                <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                  {filteredNoRNS?.length !== 0 ? `${filteredNoRNS?.length} did not submit` : ''}
                </div>
                {filteredNoRNS?.map((lesson, index) => (
                  <MenuItem key={index}>{moment(lesson.scheduled_on).format('LL')}</MenuItem>
                ))}
              </>
            )}

            {engagementDialog && (
              <>
                <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {/* {`${filteredEngagementLessons?.length !== 0 ? filteredEngagementLessons?.length : ''} `} */}
                </div>
                {`( `}
                {filteredEngagementLessons?.map((lesson, index) => (
                  <span key={index}>
                    {Math.round(
                      (parseInt(lesson?.lesson_meta?.engagement?.engagementParticipation) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementDistracted) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementTiredBored) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementMotivate) +
                        parseInt(lesson?.lesson_meta?.engagement?.engagementAskQuestions)) /
                        5
                    )}{' '}
                    {index === filteredEngagementLessons.length - 1 ? '' : '+ '}
                  </span>
                ))}
                {`)`} ÷ {filteredEngagementLessons.length} (no. of lessons) ÷ 6 (score) * 100%
                {/* <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                  {filteredNoRNS?.length !== 0 ? `${filteredNoRNS?.length} did not submit` : ''}
                </div>
               */}
              </>
            )}

            {masteryDialog && (
              <>
                {/* <div className="mt-1" style={{ justifyContent: 'center', display: 'flex' }}>
                  {`${filteredLateRNS?.length !== 0 ? filteredLateRNS?.length : 'no'} late submission`}
                </div> */}
                {`( `}
                {filteredMasteryLessons?.map((lesson, index) => (
                  <span key={index}>
                    {Math.round(
                      (parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryAnswerQuestion) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryConfused) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryShowUnderstanding) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryParticipative) +
                        parseInt(lesson?.lesson_meta?.skill_mastery?.skillMasteryUnderstandConcept)) /
                        5
                    )}{' '}
                    {index === filteredMasteryLessons.length - 1 ? '' : '+ '}
                  </span>
                ))}
                {`)`} ÷ {filteredMasteryLessons.length} (no. of lessons) ÷ 6 (score) * 100%
              </>
            )}
          </div>
        </Popover>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Classes))
