import countrycodes from '../assets/country_code.json'
import _ from 'lodash'
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export const validateTextEntry = (string, fieldName, custom) => {
  if (!string) {
    if (custom) {
      return `Please fill in ${custom}`
    } else {
      return `Please fill in your ${fieldName}`
    }
  } else {
    return null
  }
}

export const validatePassword = string => {
  if (!string) {
    return `Please fill in your password`
  } else if (string.length < 6) {
    return `Your password should contain at least 6 characters`
  } else {
    return null
  }
}

export const validateEmail = string => {
  var emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  let validEmail = emailRegex.test(string)

  if (!string) {
    return `Please fill in your email address`
  } else if (!validEmail) {
    return `Please provide a valid email address`
  } else {
    return null
  }
}

export const validateRetypePassword = (retype, ori) => {
  if (retype !== ori) {
    return 'The password that you have typed did not match'
  } else {
    return null
  }
}

export const validatePhoneNumber = (phone, code) => {
  if (!phone) {
    return `Please fill in your phone number`
  }

  let regionCode = 'SG'
  let selectedCountry = _.find(countrycodes, o => {
    return o.code === code
  })
  if (selectedCountry) {
    regionCode = selectedCountry.abbr
  }

  try {
    const number = phoneUtil.parseAndKeepRawInput(phone.toString(), regionCode)
    if (phoneUtil.isValidNumber(number)) {
      return null
    } else {
      return 'Please fill in valid phone number'
    }
  } catch {
    return null
  }
}
