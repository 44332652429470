import superagent from 'superagent'
const API_ROOT = process.env.REACT_APP_API_ROOT
const HOSTNAME = process.env.REACT_APP_HOSTNAME
const responseBody = res => res.body

let token = null
const tokenPlugin = req => {
  if (token) {
    req.set('Authorization', `Bearer ${token}`)
  }
}

const appUrlHeader = req => {
  // NOTE: handle this stupid way now bcz only 2 endpoint this header and both use it slightly different.
  //       Standardize and hanle in better way later.
  if (req.url.includes('/forgotPassword')) {
    req.set('app-url', `${HOSTNAME}/reset-password`)
  }
  if (req.url.includes('shopping_cart/init_payment')) {
    req.set('app-url', HOSTNAME)
  }
}

let getHost = port => {
  let host = API_ROOT
  if (port && process.env.REACT_APP_ENVIRONMENT === 'local') {
    host = `http://localhost:${port}`
  }
  return host
}

const requests = {
  del: (url, port) =>
    superagent
      .del(`${getHost(port)}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: (url, body, port) =>
    superagent
      .get(`${getHost(port)}${url}`)
      .use(tokenPlugin)
      .query(body)
      .then(responseBody),
  getBinary: (url, port) =>
    superagent
      .get(`${getHost(port)}${url}`)
      .responseType('blob')
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body, port) =>
    superagent
      .put(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body, port) =>
    superagent
      .post(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .use(appUrlHeader)
      .then(responseBody),
  patch: (url, body, port) =>
    superagent
      .patch(`${getHost(port)}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
}

const externalReq = {
  get: url => superagent.get(url).then(responseBody)
}

const Auth = {
  current: () => requests.get('/me'),
  login: loginObj => requests.post('/auth', loginObj),
  register: (details, userType) => requests.post(`/user/${userType}`, details),
  updateUser: reqPayload => requests.patch('/v2/user', reqPayload),
  updateStudentProfileFromParent: (studentId, reqPayload) =>
    requests.patch(`/v2/user/studentprofile/${studentId}`, reqPayload),
  getChild: userId => requests.get(`/v2/user/child/${userId}`),
  updateParentMeta: (parent, reqPayload) => requests.patch(`/v2/user/parent/studentedit/${parent}`, reqPayload), //TODO: useless to remove
  updateStudentMeta: (student, reqPayload) => requests.patch(`/v2/user/student/parentedit/${student}`, reqPayload), // TODO: useless to remove
  updateStudent: (userId, userObj) => requests.patch(`/v2/user/student/${userId}`, userObj),
  forgotPassword: email => requests.post('/forgotPassword', { email }),
  changePassword: reqPayload => requests.post('/changePassword', reqPayload),
  activateUser: reqPayload => requests.patch('/user/activate/email', reqPayload),
  detectUser: (token, classId) => requests.post('/detectUser', { token, class_id: classId }),
  refreshToken: () => requests.post('/refreshToken', {})
}

const Classes = {
  getClassList: (type, perPage, page, additional) => {
    if (!type) {
      type = 'class'
    }
    if (!page) {
      page = 1
    }
    if (!perPage) {
      perPage = 18
    }
    let offset = (page - 1) * perPage

    let query = {
      max: perPage,
      offset: offset
    }

    if (!!additional) {
      query = {
        ...query,
        ...additional
      }
    }

    return requests.get(`/class/${type}`, query)
  },
  getClassById: classId => requests.get(`/class/class?_id=${classId}`),
  getById: classId => requests.get(`/v2/online-class/${classId}/data`),
  getMyClassList: (classOpt, pageOpt) => requests.get(`/v2/online-class/${classOpt}`, pageOpt),
  search: keyword => requests.get(`/v2/online-class/search?keyword=${keyword}`), // NOTE: search not support pagination for start
  getStudentClass: userId => requests.get(`/v2/online-class/${userId}/studentclass`),
  editClassMeta: (payload, classId) => requests.patch(`/v2/online-class/${classId}/classmeta`, payload)
}

const Lessons = {
  getAllLessons: userId => requests.get(`/v2/online-lesson/all-lessons/${userId}`),
  getLessons: (classId, filter) => {
    if (!!filter) {
      filter = {
        sortBy: 'scheduled_on',
        order: 'asc',
        ...filter,
        class: classId
      }
    } else {
      filter = { class: classId }
    }
    return requests.get(`/lesson/online_lesson`, filter)
  },
  getList: (opt, filter) => requests.get(`/v2/online-lesson/${opt}`, filter),
  getById: lessonId => requests.get(`/lesson/online_lesson/${lessonId}`),
  getById2: lessonId => requests.get(`/v2/online-lesson/${lessonId}/data`), // NOTE & TODO: to return lesson with new api version, check and move getById over
  getLiveLink: lessonId => requests.get(`/v2/online-lesson/${lessonId}/live-link`),
  getVideo: lessonId => requests.post(`/lesson/playback?_id=${lessonId}`, {}),
  uploadLessonResource: formData => requests.post(`/material/lesson_material`, formData),
  getLessonResources2: (lessonId, types, perPage, page) => {
    if (!perPage) perPage = 50
    if (!page) page = 1

    let query = {
      lesson: lessonId,
      type: {
        $in: types
      },
      max: perPage,
      offset: (page - 1) * perPage
    }
    return requests.get(`/material/lesson_material`, query)
  },
  getLessonResources: (lessonId, type, perPage, page) => {
    if (!perPage) perPage = 5
    if (!page) page = 1

    let query
    if (!!type) {
      query = {
        lesson: lessonId,
        type: type,
        max: perPage,
        offset: (page - 1) * perPage,
        sortBy: 'name',
        order: 'asc'
      }
    } else {
      let queryArr = [`lesson=${lessonId}`, 'sortBy=name', 'order=asc']
      query = queryArr.join('&')
    }

    return requests.get(`/material/lesson_material`, query)
  },
  editLessonResource: (resourceId, name, type) =>
    requests.patch(`/material/lesson_material/${resourceId}`, { name, type }),
  deleteLessonResource: resourceId => requests.del(`/material/lesson_material/${resourceId}`),
  getMaterial: lessonId => requests.get(`/v2/material/lesson/${lessonId}`),
  uploadMaterial: (lessonId, reqPayload) => requests.post(`/v2/material/lesson/${lessonId}`, reqPayload),
  removeLessonActivity: (lessonId, activityId) => requests.del(`/v2/online-lesson/${lessonId}/activity/${activityId}`),
  update: (lessonId, reqPayload) => requests.patch(`/v2/online-lesson/${lessonId}`, reqPayload),
  editLessonMeta: lessonObj => requests.patch(`/v2/online-lesson/meta`, lessonObj),
  getLessonMeta: lessonId => requests.get(`/v2/online-lesson/${lessonId}/meta`),
  consultImmediately: (lessonId, checked) => requests.patch(`/v2/online-lesson/${lessonId}/consultimmediately`, checked)
}

const Misc = {
  contactUs: reqPayload => requests.post('/contactUs', reqPayload),
  subscribe: (service, subscribe) => requests.post(`/sub/${service}/${subscribe ? 'sub' : 'unsub'}`, {}),
  recordAction: reqPayload => requests.post('/v2/user/record', reqPayload)
}

const OnlineClass = {
  getList: (query, disableIsListed) => {
    let reqPayload = {
      ...query,
      is_listed: true,
      country: process.env.REACT_APP_REGION
    }
    if (disableIsListed) {
      delete reqPayload['is_listed']
    }
    return requests.get(`/class/info`, reqPayload)
  },
  getClassById: classId => requests.get(`/class/info?_id=${classId}`), // TODO: switch to V2 and return only queried obj instead of array
  getQuote: payload => requests.post(`/v2/online-class/quote`, payload)
}

const UserInput = {
  // createFeedback: feedbackObj => requests.post(`/user_input/feedback`, feedbackObj),
  createFeedback: feedbackObj => requests.post(`/v2/misc/feedback`, feedbackObj),
  getFeedback: (filter, perPage, page) => {
    if (!perPage) perPage = 10
    if (!page) page = 1
    let query = {
      ...filter,
      max: perPage,
      offset: perPage * (page - 1)
    }
    return requests.get(`/user_input/feedback`, query)
  }
}

const Order = {
  getList: filter => requests.get(`/order/order`, filter)
}

const ShoppingCart = {
  addItem: item => requests.post('/shopping_cart', item),
  getCart: () => requests.get('/shopping_cart'),
  updateItem: (item_id, edits) => requests.patch(`/shopping_cart/item/${item_id}`, edits),
  removeItem: item_id => requests.del(`/shopping_cart/item/${item_id}`),
  clearCart: () => requests.del('/shopping_cart/clear'),
  getCartQuote: payload => requests.post(`/shopping_cart/quote`, payload),
  checkout: payload => requests.post(`/shopping_cart/pay`, payload),
  initPayment: payload => requests.post(`/shopping_cart/init_payment`, payload),
  getPostPaymentStat: referenceId => requests.get(`/shopping_cart/post_payment?ref=${referenceId}`, {})
}

const External = {
  getJSON: url => externalReq.get(url)
}

const Event = {
  getList: filter => requests.get('/info/event/webinar', filter),
  getById: id => requests.get(`/event/${id}/data`),
  join: eventId => requests.patch(`/event/webinar/${eventId}/register`, {}),
  getAutoLoginUrl: eventId => requests.get(`/event/${eventId}/autologin`),
  cancelReservation: eventId => requests.put(`/event/webinar/${eventId}/cancel`, {})
}

const Homework = {
  getList: filter => requests.get(`/homework`, filter),
  getById: homeworkId => requests.get(`/homework/${homeworkId}`),
  getPendingSubmission: filter => requests.get(`/homework/pending-submission`, filter),
  getStudentReportByLessons: (studentId, lessonIds) =>
    requests.get(`/homework/student/${studentId}?lesson=${lessonIds}`),
  create: (type, reqPayload) => requests.post(`/homework/${type}`, reqPayload),
  update: (type, homeworkId, reqPayload) => requests.patch(`/homework/${type}/${homeworkId}`, reqPayload),
  delete: homeworkId => requests.del(`/homework/${homeworkId}`)
}

const Submission = {
  create: (type, reqPayload) => requests.post(`/submission/${type}`, reqPayload),
  update: (type, submmisionId, reqPayload) => requests.patch(`/submission/${type}/${submmisionId}`, reqPayload),
  getByHomework: homeworkId => requests.get(`/submission/homework/${homeworkId}`),
  getStat: filter => requests.get('/submission/stat', filter),
  getList: filter => requests.get('/submission', filter),
  delete: submissionId => requests.del(`/submission/${submissionId}`)
}

const Info = {
  getTutor: slug => requests.get(`/info/tutor?slug=${slug}`)
}

const Ticket = {
  getByLesson: lessonId => requests.get(`/ticket?lesson=${lessonId}`),
  update: reqPayload => requests.patch('/ticket/comment', reqPayload)
}

const Report = {
  getList: reqParams => requests.get(`/report?${reqParams}`),
  getByClass: (classId, title) => requests.get(`/report/class/${classId}${title ? `?title=${title}` : ''}`),
  getPDF: reportId => requests.getBinary(`/report/${reportId}/pdf`),
  create: reqPayload => requests.post('/report/regular', reqPayload),
  update: (reportType, reportId, reqPayload) => requests.patch(`/report/${reportType}/${reportId}`, reqPayload),
  releaseCompleted: (classId, report_title) => requests.patch(`/report/release/${classId}`, { report_title })
}

const Activity = {
  read: reqPayload => requests.post(`/activity/records`, reqPayload),
  create: (type, reqPayload) => requests.post(`/activity/${type}`, reqPayload),
  update: (type, id, reqPayload) => requests.put(`/activity/${type}/${id}`, reqPayload)
}

const NewsAndEvents = {
  getNews: () => requests.get(`/v2/newsandevents/`)
}

export default {
  Auth,
  Classes,
  Lessons,
  Misc,
  OnlineClass,
  UserInput,
  Order,
  ShoppingCart,
  Event,
  External,
  Homework,
  Submission,
  Info,
  Ticket,
  Report,
  Activity,
  NewsAndEvents,
  setToken: _token => {
    token = _token
  }
}
