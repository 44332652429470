import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Done as CorrectIcon } from '@material-ui/icons'
import _ from 'lodash'
import agent from 'agent'
import { GET_LESSON_BY_ID, REMOVE_ACTIVITY_FROM_LESSON } from 'constants/actionTypes'
import AddMCQDialog from 'components/online-class/lesson-activity/dialog-add-mcq'
import AddOpenEndedDialog from 'components/online-class/lesson-activity/dialog-add-open-ended'
import SortActivityDialog from 'components/online-class/lesson-activity/dialog-sort-activity'
import ImportActivityDialog from 'components/online-class/lesson-activity/dialog-import-activity'

const mapStateToProps = state => ({
  removingActivity: state.lessons.removingActivity,
  updatingLesson: state.lessons.updatingLesson
})

const mapDispatchToProps = dispatch => ({
  getLesson: lessonId => dispatch({ type: GET_LESSON_BY_ID, payload: agent.Lessons.getById2(lessonId) }),
  removeActivity: (lessonId, activityId) =>
    dispatch({ type: REMOVE_ACTIVITY_FROM_LESSON, payload: agent.Lessons.removeLessonActivity(lessonId, activityId) })
})

const MCQ = 'MultipleChoice'
const OpenEnded = 'OpenEnded'
class StudentTab extends React.Component {
  state = {
    sortedActivity: [],
    selectedActivity: null,
    addMCQDIalogOpen: false,
    addOpenEndedDialogOpen: false,
    sortActivityDialogOpen: false,
    importDialogOpen: false
  }

  componentDidMount() {
    this.sortActivity()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.removingActivity && !this.props.removingActivity) {
      this.props.getLesson(this.props.lessonId)
    }

    if (prevProps.updatingLesson && !this.props.updatingLesson) {
      this.props.getLesson(this.props.lessonId)
    }
  }

  sortActivity = () => {
    const { activities } = this.props
    if (!activities) return

    const sortedActivity = _.orderBy(activities, ['order'])
    this.setState({ sortedActivity })
  }

  toggleAddMCQDialog = selectedActivity => () => {
    this.setState({
      selectedActivity,
      addMCQDIalogOpen: !this.state.addMCQDIalogOpen
    })
  }

  toggleAddOpenEndedDialog = selectedActivity => () => {
    this.setState({
      selectedActivity,
      addOpenEndedDialogOpen: !this.state.addOpenEndedDialogOpen
    })
  }

  toggleSortActivityDialog = () => {
    this.setState({
      sortActivityDialogOpen: !this.state.sortActivityDialogOpen
    })
  }

  toggleImportDialog = () => {
    this.setState({
      importDialogOpen: !this.state.importDialogOpen
    })
  }

  handleEdit = activity => () => {
    const activityType = activity.content._type
    if (activityType === MCQ) {
      this.toggleAddMCQDialog(activity)()
    } else if (activityType === OpenEnded) {
      this.toggleAddOpenEndedDialog(activity)()
    }
  }

  render() {
    const { lessonId } = this.props
    const {
      sortedActivity,
      selectedActivity,
      addMCQDIalogOpen,
      addOpenEndedDialogOpen,
      sortActivityDialogOpen,
      importDialogOpen
    } = this.state

    return (
      <div className="container-fluid animated slideInUpTiny animation-duration-3">
        <div className="row">
          <div className="col-12 mb-3">
            <div className="d-flex justify-content-between">
              <div className="manage-margin">
                <Button variant="outlined" color="primary" onClick={this.toggleImportDialog}>
                  Import from
                </Button>
                <Button variant="outlined" color="primary" onClick={this.toggleSortActivityDialog}>
                  Sort Activity
                </Button>
              </div>
              <div className="manage-margin">
                <Button variant="outlined" color="primary" onClick={this.toggleAddMCQDialog(null)}>
                  Add MCQ
                </Button>
                <Button variant="outlined" color="primary" onClick={this.toggleAddOpenEndedDialog(null)}>
                  Add Open Ended
                </Button>
              </div>
            </div>
          </div>

          <div className="col-12 bg-white">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Answer</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedActivity.map(activity => (
                  <TableRow key={activity.content._id}>
                    <TableCell>{activity.order}</TableCell>
                    <TableCell>
                      <div className="manage-margin">
                        <Chip label={activity.content._type} />
                        {activity.content.mode && <Chip label={activity.content.mode} />}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div dangerouslySetInnerHTML={{ __html: activity.content.question }} />
                    </TableCell>
                    <TableCell>
                      {activity.content._type === MCQ && (
                        <>
                          {activity.content.option.map((o, index) => (
                            <div key={index} className="d-flex align-items-center">
                              {o.label}. {o.content}
                              {activity.content.answer_index.includes(index) && (
                                <CorrectIcon
                                  className="ml-1 text-success"
                                  style={{ width: '0.8em', height: '0.8em' }}
                                />
                              )}
                            </div>
                          ))}
                        </>
                      )}
                      {activity.content._type === OpenEnded && (
                        <div className="d-flex flex-wrap align-items-center manage-margin">
                          {activity.content.answer.map((o, index) => (
                            o.length < 30 ?
                            <Chip key={index} label={o} />
                            : <span key={index}>- {o}</span>
                          ))}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        className="mb-1 mr-1"
                        onClick={this.handleEdit(activity)}>
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        className="bg-danger text-white mb-1"
                        onClick={() => {
                          this.props.removeActivity(lessonId, activity.content._id)
                        }}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>

        <AddMCQDialog
          isOpen={addMCQDIalogOpen}
          close={this.toggleAddMCQDialog(null)}
          lessonId={lessonId}
          projectedOrder={sortedActivity.length + 1}
          activityData={selectedActivity}
        />

        <AddOpenEndedDialog
          isOpen={addOpenEndedDialogOpen}
          close={this.toggleAddOpenEndedDialog(null)}
          lessonId={lessonId}
          projectedOrder={sortedActivity.length + 1}
          activityData={selectedActivity}
        />

        <SortActivityDialog
          isOpen={sortActivityDialogOpen}
          close={this.toggleSortActivityDialog}
          lessonId={lessonId}
          activityList={sortedActivity}
        />

        <ImportActivityDialog isOpen={importDialogOpen} close={this.toggleImportDialog} lessonId={lessonId} />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentTab))
