import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Avatar, Button, CircularProgress, Paper } from '@material-ui/core'
import { Subject, PersonOutline, DateRange, Schedule, CloudDownload, ArrowBackIos } from '@material-ui/icons'
import moment from 'moment'
import _ from 'lodash'
import agent from 'agent'
import {
  GET_LESSON_DETAILS,
  GET_LESSON_MATERIAL,
  GET_HOMEWORK_BY_LESSON,
  GET_MY_SUBMISSION,
  CLEAR_PACKAGE_QUOTE,
  RECORD_USER_ACTION
} from 'constants/actionTypes'
import JoinLessonButton from 'components/online-class/button-join-lesson'
import SubmitHomeworkDialog from 'pages/app/dashboard/dialog-submit-homework'
import PlayRecordingDialog from 'components/online-class/dialog-play-recording'

const mapStateToProps = state => ({
  loadingLesson: state.lessons.loadingLesson,
  lessonData: state.lessons.lessonData,
  loadingLessonMaterial: state.material.loadingLessonMaterial,
  lessonMaterial: state.material.lessonMaterial,
  loadingHomeworkByLesson: state.homework.loadingHomeworkByLesson,
  homeworksByLesson: state.homework.homeworksByLesson,
  loadingMySubmission: state.submission.loadingMySubmission,
  mySubmissions: state.submission.mySubmissions
})

const mapDispatchToProps = dispatch => ({
  getLessonDetails: lessonId => dispatch({ type: GET_LESSON_DETAILS, payload: agent.Lessons.getById(lessonId) }),
  getLessonMaterial: lessonId =>
    dispatch({ type: GET_LESSON_MATERIAL, payload: agent.Lessons.getMaterial(lessonId), lessonId }),
  getHomeworkByLesson: lessonId =>
    dispatch({ type: GET_HOMEWORK_BY_LESSON, payload: agent.Homework.getList({ lesson: lessonId }), lessonId }),
  getHomeworkSubmission: homeworkId =>
    dispatch({ type: GET_MY_SUBMISSION, payload: agent.Submission.getByHomework(homeworkId), homeworkId }),
  clearPackageQuote: class_id => dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id }),
  trackAction: reqPayload => dispatch({ type: RECORD_USER_ACTION, payload: agent.Misc.recordAction(reqPayload) })
})

class LessonDetails extends React.Component {
  state = {
    materialTypes: [],
    materialList: [],
    selectedHomework: '',
    isSubmitHomeworkDialogOpen: false,
    isVideoDialogOpen: false
  }

  componentDidMount() {
    const lessonId = this.props.match.params.lessonId
    this.props.getLessonDetails(lessonId)
    this.props.getLessonMaterial(lessonId)
    this.props.getHomeworkByLesson(lessonId)
  }

  componentDidUpdate(prevProps) {
    const lessonId = this.props.match.params.lessonId
    if (prevProps.loadingLessonMaterial[lessonId] && !this.props.loadingLessonMaterial[lessonId]) {
      const { lessonMaterial } = this.props
      const materialList = lessonMaterial[lessonId] ?? []
      this.setState({
        materialTypes: _.uniq(_.map(materialList, 'type')),
        materialList
      })
    }

    if (prevProps.loadingHomeworkByLesson !== this.props.loadingHomeworkByLesson) {
      const { homeworksByLesson } = this.props
      if (homeworksByLesson[lessonId]) {
        let homeworkId = homeworksByLesson[lessonId].data.length > 0 ? homeworksByLesson[lessonId].data[0]._id : ''
        let { loadingMySubmission, mySubmissions } = this.props
        if (homeworkId && !loadingMySubmission.includes(homeworkId) && !mySubmissions[homeworkId]) {
          this.props.getHomeworkSubmission(homeworkId)
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.props.lessonData?.class?._id) {
      this.props.clearPackageQuote(this.props.lessonData.class._id)
    }
  }

  submissionViewed = submissionId => () => {
    agent.Submission.update('classic', submissionId, { viewed: true, status: 'MARKED' })
  }

  toggleSubmitHomeworkDialog = homeworkData => () => {
    // NOTE: supposingly not need selected homework here, but to save time
    this.setState({
      selectedHomework: homeworkData,
      isSubmitHomeworkDialogOpen: !this.state.isSubmitHomeworkDialogOpen
    })
  }

  toggleVideoDialog = () => {
    if (!this.state.isVideoDialogOpen) {
      const { lessonData } = this.props

      this.props.trackAction({
        type: 'lesson_playback',
        class_id: lessonData.class._id,
        lesson_id: lessonData._id
      })
    }

    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen
    })
  }

  render() {
    const {
      loadingLesson,
      lessonData,
      loadingLessonMaterial,
      loadingHomeworkByLesson,
      homeworksByLesson,
      mySubmissions
    } = this.props
    const { materialTypes, materialList, isSubmitHomeworkDialogOpen, selectedHomework, isVideoDialogOpen } = this.state

    if (loadingLesson || !lessonData) {
      return (
        <div className="text-center my-5">
          <CircularProgress size={40} color="primary" />
        </div>
      )
    }

    const now = moment()
    const lessonOver = now > moment(lessonData.ended_on).add(15, 'minutes')
    const playbackAvailable = lessonData.playback_url?.length > 0
    const homeworkData =
      homeworksByLesson[lessonData._id]?.data.length > 0 ? homeworksByLesson[lessonData._id].data[0] : null

    let homeworkSubmissionClosed = true
    if (homeworkData?.close_on) {
      homeworkSubmissionClosed = moment() > moment(homeworkData.close_on)
    }

    const submissionData = homeworkData ? mySubmissions[homeworkData._id] : null

    let markedHomework = []
    let audioComments = []
    if (submissionData?.marked_attachment?.length > 0) {
      submissionData.marked_attachment.forEach(file => {
        if (file.mimetype === 'audio/wav') {
          audioComments.push(file)
        } else {
          markedHomework.push(file)
        }
      })
    }

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <div className="row align-items-center">
            <div className="col-md col-12">
              <div className="d-flex flex-row align-items-center">
                <ArrowBackIos
                  className="clickable mr-2"
                  onClick={() => {
                    this.props.history.goBack()
                  }}
                />
                <h2 className="title--rubik mb-0">{lessonData.class.name}</h2>
              </div>
            </div>
          </div>
        </Paper>

        <div className="app-wrapper">
          <Paper elevation={2}>
            <div className="row px-3">
              <div className="col-12 border-bottom mb-3">
                <h3 className="title--rubik my-3">Lesson Details</h3>
              </div>
              <div className="col-md-auto col-12">
                <Avatar
                  src={lessonData.tutor[0].profile_pic}
                  alt={lessonData.tutor[0].name}
                  className="size-180 avatar-shadow mr-2 mb-3"
                />
              </div>
              <div className="col-md col-12">
                <div className="row">
                  <div className="col-md col-12">
                    <div className="d-flex align-items-center">
                      <Subject className="text-muted mr-2" />
                      :&nbsp;&nbsp;&nbsp;{lessonData.meta?.term ? `Term ${lessonData.meta.term}` : ''} Lesson&nbsp;
                      {lessonData.meta?.index}: {lessonData.name}
                    </div>
                    <div className="d-flex align-items-center">
                      <PersonOutline className="text-muted mr-2" />
                      :&nbsp;&nbsp;&nbsp;{lessonData.tutor[0].name}
                    </div>
                    <div className="d-flex align-items-center">
                      <DateRange className="text-muted mr-2" />
                      :&nbsp;&nbsp;&nbsp;{moment(lessonData.scheduled_on).format('dddd, Do MMM YYYY')}
                    </div>
                    <div className="d-flex align-items-center">
                      <Schedule className="text-muted mr-2" />
                      :&nbsp;&nbsp;&nbsp;{moment(lessonData.scheduled_on).format('h:mm')}
                      &nbsp;-&nbsp;
                      {moment(lessonData.ended_on).format('h:mm a')}
                    </div>
                  </div>
                  <div className="col-md-auto col-12 align-self-center">
                    {lessonOver ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!playbackAvailable}
                        disableRipple
                        disableFocusRipple
                        onClick={this.toggleVideoDialog}>
                        {playbackAvailable ? 'View Recording' : 'Recording ready soon...'}
                      </Button>
                    ) : (
                      <JoinLessonButton lessonData={lessonData} />
                    )}
                  </div>
                </div>
                <div className="label text-muted text-small mt-3">Class Description</div>
                <div dangerouslySetInnerHTML={{ __html: lessonData.class.desc }} />
              </div>
            </div>
          </Paper>

          <div className="row my-4">
            <div className="col-md-6 col-12">
              <Paper elevation={2}>
                <div className="row px-3">
                  <div className="col-12 border-bottom mb-3">
                    <h3 className="title--rubik my-3">Resources</h3>
                  </div>
                  <div className="col-12">
                    {loadingLessonMaterial[lessonData._id] && <CircularProgress size={30} className="my-5" />}

                    {materialTypes.length === 0 && <div className="text-center mb-3">No resource uploaded yet</div>}

                    {materialTypes.map((type, index) => {
                      return (
                        <div key={index}>
                          <div className="text-muted text-small text-capitalize mb-2">{type}</div>
                          {materialList.map((material, index) => {
                            if (material.type !== type) {
                              return null
                            }

                            return (
                              <div className="card px-3 py-2 mb-2" key={index}>
                                <a href={material.material_meta.location} download>
                                  <div className="row align-items-center ">
                                    <div className="col">
                                      <span className="link">{material.name}</span>
                                    </div>
                                    <div className="col-auto">
                                      <CloudDownload />
                                    </div>
                                  </div>
                                </a>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Paper>
            </div>
            <div className="col-md-6 col-12">
              <Paper elevation={2}>
                <div className="row px-3">
                  <div className="col-12 border-bottom mb-3">
                    <h3 className="title--rubik my-3">Homework</h3>
                  </div>
                  <div className="col-12">
                    {loadingHomeworkByLesson.includes[lessonData._id] && (
                      <CircularProgress size={30} className="my-5" />
                    )}

                    {!homeworkData && <div className="text-center mb-3">No homework assigned for this lesson</div>}

                    {homeworkData && (
                      <>
                        <div className="d-flex align-items-center mb-2">
                          <div className="title-rubik text-muted mr-2">Due on:</div>
                          <span>{moment(homeworkData.due_on).format('lll')}</span>
                        </div>
                        <div className="title-rubik text-muted mb-1">Homework File:</div>
                        <div className="mb-2">
                          {homeworkData.attachment.map((file, index) => (
                            <div className="card px-3 py-2 mb-2" key={index}>
                              <a href={file.url} download>
                                <div className="row align-items-center ">
                                  <div className="col">
                                    <span className="link">{file.name}</span>
                                  </div>
                                  <div className="col-auto">
                                    <CloudDownload />
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    {submissionData && submissionData !== 'not-found' ? (
                      <>
                        <div className="title-rubik text-muted my-1">Submitted:</div>
                        {submissionData.attachment.map((file, index) => (
                          <div className="card px-3 py-2 mb-2" key={index}>
                            <a href={file.url} download>
                              <div className="row align-items-center ">
                                <div className="col">
                                  <span className="link">{file.name}</span>
                                </div>
                                <div className="col-auto">
                                  <CloudDownload />
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}

                        {markedHomework.length > 0 && (
                          <>
                            <div className="title-rubik text-muted my-1">Returned:</div>
                            {markedHomework.map((file, index) => (
                              <div
                                className="card px-3 py-2 mb-2"
                                key={index}
                                onClick={this.submissionViewed(submissionData._id)}>
                                <a href={file.url} download>
                                  <div className="row align-items-center ">
                                    <div className="col">
                                      <span className="link">{file.name}</span>
                                    </div>
                                    <div className="col-auto">
                                      <CloudDownload />
                                    </div>
                                  </div>
                                </a>
                              </div>
                            ))}
                          </>
                        )}

                        {audioComments.length > 0 && (
                          <>
                            <div className="title-rubik text-muted my-1">Audio Comments:</div>
                            {audioComments.map((file, index) => (
                              <audio
                                style={{ width: '100%', height: '35px' }}
                                className="px-2"
                                controls
                                src={file.url}
                                key={file.name}>
                                D
                              </audio>
                            ))}
                          </>
                        )}

                        <div className="title-rubik text-muted my-1">Comments:</div>
                        <div className="px-2 mb-2">
                          {submissionData.comment ? (
                            <div dangerouslySetInnerHTML={{ __html: submissionData.comment }} />
                          ) : (
                            <span>N/A</span>
                          )}
                        </div>
                      </>
                    ) : (
                      <Button
                        className="my-2"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={homeworkData?.submitted || homeworkSubmissionClosed}
                        disableRipple
                        disableFocusRipple
                        onClick={this.toggleSubmitHomeworkDialog(homeworkData)}>
                        {homeworkData?.submitted ? 'Submitted' : 'Submit Homework/ Revision Notes'}
                      </Button>
                    )}
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </div>

        <SubmitHomeworkDialog
          isOpen={isSubmitHomeworkDialogOpen}
          close={this.toggleSubmitHomeworkDialog()}
          homeworkData={selectedHomework}
        />

        <PlayRecordingDialog
          isOpen={isVideoDialogOpen}
          close={this.toggleVideoDialog}
          lessonId={lessonData._id}
          originalVideoUrl={lessonData.playback_url}
        />
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonDetails))
