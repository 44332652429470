import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import _ from 'lodash'
import qs from 'qs'

import agent from 'agent'
import { RESET_PASSWORD } from 'constants/actionTypes'
import { LOGIN } from 'routes/routes'
import { validatePassword, validateRetypePassword } from 'utils/formValidation'
import AlertDialog from 'components/dialog/alert'

const mapStateToProps = state => ({
  resettingPassword: state.auth.resettingPassword,
  resetStatus: state.auth.resetStatus
})

const mapDispatchToProps = dispatch => ({
  changePassword: reqPayload => dispatch({ type: RESET_PASSWORD, payload: agent.Auth.changePassword(reqPayload) })
})

const HeadMeta = () => {
  return (
    <Helmet>
      <title>Reset Password | EPA</title>
      <meta
        name="description"
        content="EPA is committed to finding the right solution for every student. With a tailored approach, we empower each student to become independent and life-ready. By getting to know each student’s strengths and limitations, we aim to provide an education that is goal-oriented and customized to their specific needs, aspirations, and capabilities. The student’s success is not just about the top grades or prestige, but genuine growth as a learner and an unique individual."
      />
      <meta
        name="keywords"
        content="right solution for every student, tailored approach, independent and life-ready, student’s strengths and limitations, goal-oriented education, customized specific needs, aspirations, and capabilities, top grades, prestige, genuine growth as a learner and an unique individual"
      />
    </Helmet>
  )
}

class ResetPassword extends React.Component {
  state = {
    password: '',
    confirmPassword: '',
    queryToken: '',
    isPasswordVisible: false,
    formError: {},
    resetDone: false,
    isAlertDialogOpen: false
  }

  componentDidMount() {
    let parsed = qs.parse(this.props.location.search.slice(1))
    if (parsed.token) {
      this.setState({ queryToken: parsed.token })
    }
  }

  componentDidUpdate(prevProp) {
    if (prevProp.resettingPassword && !this.props.resettingPassword && this.props.resetStatus === 'success') {
      this.setState({ isAlertDialogOpen: true })
    }
  }

  togglePasswordVisibility = () => {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }

  onChangeFormElement = e => {
    const type = e.target.name
    const value = e.target.value
    this.setState({ [type]: value })
  }

  onFocusFormElement = e => {
    let type = e.target.name
    let tempFormError = this.state.formError
    delete tempFormError[type]
    this.setState({ formError: tempFormError })
  }

  validateFormElement = type => {
    const { password, formError } = this.state
    let errorMsg = ''

    if (type === 'password') {
      errorMsg = validatePassword(this.state[type])
    } else if (type === 'confirmPassword') {
      errorMsg = validateRetypePassword(this.state[type], password)
    }

    let tempFormError = formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  resetPassword = () => {
    const { password, queryToken, formError } = this.state
    this.validateFormElement('password')
    this.validateFormElement('confirmPassword')

    if (_.isEmpty(formError)) {
      this.props.changePassword({ password: password, token: queryToken })
    }
  }

  closeAlertDialog = () => {
    this.setState({ isAlertDialogOpen: false })
    this.props.history.push(LOGIN)
  }

  render() {
    const { resettingPassword, resetStatus } = this.props
    const { queryToken, password, confirmPassword, formError, isPasswordVisible, isAlertDialogOpen } = this.state

    if (!queryToken) {
      return (
        <>
          <HeadMeta />
          <div className="container my-5">
            <div className="row justify-content-center">
              <div className="col-sm-4 col-xs-12 text-center">
                <h2>Something went wrong</h2>
                <p className="text-large">Make sure you got to this page from the link sent to you by email.</p>
                <div className="mt-2 mb-5">
                  <Link to={LOGIN}>Go back to log in</Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }

    const doneResetPassword = resetStatus === 'success'
    const resetPasswordFailed = resetStatus === 'failed'

    return (
      <>
        <HeadMeta />
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-5 col-xs-12 text-center">
              <h2>Welcome back!</h2>
              <p className="text-large mb-5">Please choose a new password</p>
              {resetPasswordFailed && (
                <Alert severity="error" className="text-left mt-2">
                  Your password reset token might have expired or is wrong. Make sure you follow the link sent to you in
                  the email.
                </Alert>
              )}

              <FormControl className="mb-3" fullWidth>
                <InputLabel>Password</InputLabel>
                <Input
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={password}
                  name="password"
                  onChange={this.onChangeFormElement}
                  error={!!formError.password}
                  onFocus={this.onFocusFormElement}
                  disabled={resettingPassword || doneResetPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Toggle password visibility" onClick={this.togglePasswordVisibility}>
                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formError.password && <FormHelperText error={true}>{formError.password}</FormHelperText>}
              </FormControl>

              <FormControl className="mb-3" fullWidth>
                <InputLabel>Retype Your Password</InputLabel>
                <Input
                  type={isPasswordVisible ? 'text' : 'password'}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.onChangeFormElement}
                  error={!!formError.confirmPassword}
                  onFocus={this.onFocusFormElement}
                  disabled={resettingPassword || doneResetPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Toggle password visibility" onClick={this.togglePasswordVisibility}>
                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formError.confirmPassword && <FormHelperText error={true}>{formError.confirmPassword}</FormHelperText>}
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                disabled={resettingPassword || doneResetPassword}
                fullWidth
                onClick={this.resetPassword}>
                Confirm New Password {resettingPassword && <CircularProgress size={24} className="ml-2" />}
              </Button>
            </div>
          </div>
        </div>

        <AlertDialog
          isOpen={isAlertDialogOpen}
          close={this.closeAlertDialog}
          title="Success"
          content="Your password has been reset successfully! Please login using your new password."
          buttonText="Go to Login Page"
        />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
