import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, CircularProgress, Dialog, DialogTitle, List, ListItem, ListItemText, Paper } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'

import agent from 'agent'
import { MY_CLASS_LIST } from 'routes/routes'
import { GET_CLASS_BY_ID, CLEAR_PACKAGE_QUOTE } from 'constants/actionTypes'
import { CLASS_TYPE_WORKSHOP, CLASS_TYPE_REGULAR_CLASSES } from 'constants/generalTypes'
import LessonGroupingNav from 'components/online-class/nav-lesson-grouping'
import LiveLessonTab from './tab-live-lesson'
import ResourceTab from './tab-resource'
import HomeworkTab from './tab-homework'
import RecordingTab from './tab-recording'
import ReportTab from './tab-report-all'

const mapStateToProps = state => ({
  selectedClass: state.classes.selectedClass,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  getClassById: classId => dispatch({ type: GET_CLASS_BY_ID, payload: agent.Classes.getClassById(classId) }),
  clearPackageQuote: class_id => dispatch({ type: CLEAR_PACKAGE_QUOTE, class_id: class_id })
})

class MyClassDetails extends React.Component {
  state = {
    activeTab: 'live-lesson',
    activeMonth: null,
    firstLoad: true,
    switchingMonth: false,
    selectMonthDialogOpen: false,
    availableMonths: [],
    showTabTutorial: '',
    videoOnlyAccess: false
  }

  componentDidMount() {
    let { selectedClass } = this.props
    let classId = this.props.match.params.classId

    if (!selectedClass || selectedClass._id !== classId) {
      this.props.getClassById(classId)
    } else {
      this.processClassData()
    }

    let parsed = qs.parse(this.props.location.search.slice(1))
    if (parsed.tab) {
      this.setState({
        activeTab: parsed.tab
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedClass === 'not_found') {
      this.props.history.push(MY_CLASS_LIST)
    }

    if (prevProps.selectedClass !== this.props.selectedClass) {
      this.processClassData()
    }
  }

  componentWillUnmount() {
    if (this.props.selectedClass?._id) {
      this.props.clearPackageQuote(this.props.selectedClass._id)
    }
  }

  processClassData = () => {
    let { selectedClass, currentUser } = this.props

    if (!selectedClass || selectedClass === 'not_found') return

    if (!selectedClass.is_active) {
      return
    }

    if (selectedClass.class_type === CLASS_TYPE_WORKSHOP) {
      this.setState({
        activeMonth: 'All'
      })
    }

    let isVideoStudent = selectedClass.video_student && selectedClass.video_student.includes(currentUser._id)
    let isNormalStudent = selectedClass.student && selectedClass.student.includes(currentUser._id)
    let isTrialStudent = selectedClass.trial_student && selectedClass.trial_student.includes(currentUser._id)
    let isLiveLessonDisabled = selectedClass.disable?.includes('live-lesson')

    if (isLiveLessonDisabled || (isVideoStudent && !isNormalStudent && !isTrialStudent)) {
      this.setState({
        videoOnlyAccess: true,
        activeTab: 'recording'
      })
    }
  }

  handleTabChange = selectedTab => {
    this.setState({
      activeTab: selectedTab,
      showTabTutorial: selectedTab,
      firstLoad: false
    })
  }

  render() {
    let { selectedClass } = this.props
    let { activeTab, activeMonth, firstLoad, showByTerm, activeTerm, videoOnlyAccess } = this.state

    if (!selectedClass) {
      return (
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <div className="manage-margin text-center py-5">
            <CircularProgress size={50} />
          </div>
        </div>
      )
    }

    let showHomework = true
    if (videoOnlyAccess || selectedClass.is_free || selectedClass.disable?.includes('homework')) {
      showHomework = false
    }

    //NOTE: shall control how every tab behave, for example/espeically homework tab, only fire api and get all homework status when tab mounted, to optimize performance

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <React.Fragment>
          <Paper elevation={2} className={`app-wrapper `}>
            <div className="row align-items-center">
              <div className="col">
                <div
                  className="back-link mb-2"
                  onClick={() => {
                    this.props.history.goBack()
                  }}>
                  <i className="material-icons mr-1" style={{ fontSize: '10px' }}>
                    arrow_back_ios
                  </i>
                  Back to My Classes
                </div>
                <div className="row">
                  <div className="col">
                    <h1 className="title--rubik mb-0">{selectedClass?.name}</h1>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </React.Fragment>
        <div className="app-wrapper">
          <div className="class-details-tab">
            {!videoOnlyAccess && (
              <>
                <input
                  id="live-lesson"
                  type="radio"
                  name="tabs"
                  checked={activeTab === 'live-lesson'}
                  onChange={() => {
                    this.handleTabChange('live-lesson')
                  }}
                />
                <label htmlFor="live-lesson">Lessons</label>
              </>
            )}

            <input
              id="resource"
              type="radio"
              name="tabs"
              checked={activeTab === 'resource'}
              onChange={() => {
                this.handleTabChange('resource')
              }}
            />
            <label htmlFor="resource" id="resource-tab">
              Resources
            </label>

            <input
              id="report"
              type="radio"
              name="tabs"
              checked={activeTab === 'report'}
              onChange={() => {
                this.handleTabChange('report')
              }}
            />
            <label htmlFor="report" id="report-tab">
              Report
            </label>

            {/* {showHomework && (
              <>
                <input
                  id="homework"
                  type="radio"
                  name="tabs"
                  checked={activeTab === 'homework'}
                  onChange={() => {
                    this.handleTabChange('homework')
                  }}
                />
                <label htmlFor="homework" id="homework-tab">
                  Homework
                </label>
              </>
            )} */}

            {/* <input
              id="recording"
              type="radio"
              name="tabs"
              checked={activeTab === 'recording'}
              onChange={() => {
                this.handleTabChange('recording')
              }}
            />
            <label htmlFor="recording">Recordings</label> */}
            {/* 
            {!selectedClass.disable?.includes('report') && (
              <>
                <input
                  id="report"
                  type="radio"
                  name="tabs"
                  checked={activeTab === 'report'}
                  onChange={() => {
                    this.handleTabChange('report')
                  }}
                />
                <label htmlFor="report">Reports</label>
              </>
            )} */}

            {!videoOnlyAccess && (
              <section id="live-lesson">
                <LiveLessonTab
                  activeMonth={activeMonth}
                  switchToRecordingTab={() => {
                    this.handleTabChange('recording')
                  }}
                  firstLoad={firstLoad}
                />
              </section>
            )}

            <section id="resource">
              <ResourceTab activeMonth={activeMonth} firstLoad={firstLoad} />
            </section>

            <section id="report">
              <ReportTab classData={selectedClass} />
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyClassDetails))
