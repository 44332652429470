import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { AppBar, Badge, IconButton, Toolbar } from '@material-ui/core'

import { TOGGLE_COLLAPSED_NAV } from 'constants/actionTypes'
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/settingsTypes'
import { APP, ONLINE_CLASS_LIST, SHOPPING_CART } from 'routes/routes'
import { DASHBOARD } from 'routes/studentRoutes'

const mapStateToProps = state => ({
  drawerType: state.settings.drawerType,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  toggleCollapsedNav: isNavCollapsed => dispatch({ type: TOGGLE_COLLAPSED_NAV, isNavCollapsed })
})

class Header extends React.Component {
  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed
    this.props.toggleCollapsedNav(val)
  }

  render() {
    const { drawerType, location, currentUser } = this.props
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-block d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none'

    let userType = currentUser ? currentUser._type : ''

    return (
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>
          <IconButton className={`jr-menu-icon ${drawerStyle}`} aria-label="Menu" onClick={this.onToggleCollapsedNav}>
            <span className="menu-icon" />
          </IconButton>

          <Link
            className="app-logo"
            to={location.pathname === `${APP}${DASHBOARD}` ? ONLINE_CLASS_LIST : `${APP}${DASHBOARD}`}>
            <img
              src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/logo/EPA+Extended+Logo+Dark+Background+(Transparent).png"
              alt="EPA"
              title="EPA"
            />
          </Link>
        </Toolbar>
      </AppBar>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
