import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

import agent from 'agent'
import {
  GET_PENDING_SUBMISSION_HOMEWORK,
  GET_UPCOMING_LESSONS,
  GET_FILTERED_SUBMISSION_LIST,
  GET_REPORT_LIST,
  GET_REPORT_PDF
} from 'constants/actionTypes'
import { USER_PROFILE } from 'routes/routes'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loadingUpcomingLessons: state.lessons.loadingUpcomingLessons,
  upcomingLessons: state.lessons.upcomingLessons,
  loadingPendingSubmission: state.homework.loadingPendingSubmission,
  pendingSubmissionList: state.homework.pendingSubmissionList,
  loadingUndoneSubmission: state.submission.loadingFilteredList,
  undoneSubmissionList: state.submission.filteredList,
  loadingReports: state.report.loadingReports,
  reportList: state.report.reportList,
  loadingReportPDF: state.report.loadingPDF
})

const mapDispatchToProps = dispatch => ({
  getUpcomingLessons: () =>
    dispatch({ type: GET_UPCOMING_LESSONS, payload: agent.Lessons.getList('upcoming', { max: 20, offset: 0 }) }),
  getPendingSubmissionHomework: () =>
    dispatch({ type: GET_PENDING_SUBMISSION_HOMEWORK, payload: agent.Homework.getPendingSubmission() }),
  getUndoneSubmissionList: filter =>
    dispatch({ type: GET_FILTERED_SUBMISSION_LIST, payload: agent.Submission.getList(filter), filter }),
  getNewReleasedReports: reqParams => dispatch({ type: GET_REPORT_LIST, payload: agent.Report.getList(reqParams) }),
  getReportPDF: (reportId, fileName) =>
    dispatch({ type: GET_REPORT_PDF, payload: agent.Report.getPDF(reportId), fileName })
})

class Dashboard extends React.Component {
  state = {
    quickStat: {
      upcomingCount: 0,
      upcomingText: 'upcoming lessons',
      pendingSubmissionHomeworkCount: 0,
      markedHomeworkCount: 0
    },
    upcomingTitle: 'Upcoming Lessons',
    upcomingLessons: [],
    selectedLessonId: '',
    isLessonDetailDialogOpen: false,
    selectedHomework: '',
    isSubmitHomeworkDialogOpen: false,
    isPreviewDialogOpen: false,
    selectedReport: null
  }

  componentDidMount() {
    this.props.getUpcomingLessons()
    this.props.getPendingSubmissionHomework()
    this.props.getUndoneSubmissionList({
      status: 'MARKED',
      viewed: false,
      updated: {
        $gte: moment().subtract(1, 'month').toISOString()
      },
      max: 30,
      offset: 0
    })
    this.props.getNewReleasedReports(`opt=new_release`)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingUpcomingLessons && !this.props.loadingUpcomingLessons) {
      const { upcomingLessons } = this.props
      const { quickStat } = this.state

      if (upcomingLessons.length > 0) {
        const now = moment()
        const nextWeek = moment().add(1, 'week')
        const nextLessonDate = moment(upcomingLessons[0].scheduled_on)

        const isNextLessonThisWeek = nextLessonDate.isSame(now, 'isoWeek')
        const isNextLessonNextWeek = nextLessonDate.isSame(nextWeek, 'isoWeek')
        let displayUpcomingLessons = []
        let upcomingTitle = 'upcoming Lessons',
          upcomingText = 'upcoming lessons'
        if (isNextLessonThisWeek) {
          upcomingTitle = 'This Week Lessons'
          upcomingText = 'upcoming lessons this week'
          displayUpcomingLessons = _.filter(upcomingLessons, o => {
            return moment(o.scheduled_on).isSame(now, 'isoWeek')
          })
        } else if (isNextLessonNextWeek) {
          upcomingTitle = 'Next Week Lessons'
          upcomingText = 'upcoming lessons next week'
          displayUpcomingLessons = _.filter(upcomingLessons, o => {
            return moment(o.scheduled_on).isSame(nextWeek, 'isoWeek')
          })
        } else {
          upcomingTitle = 'Upcoming Lessons'
          upcomingText = 'upcoming lessons'
          displayUpcomingLessons = _.filter(upcomingLessons, o => {
            return moment(o.scheduled_on).isSame(nextLessonDate, 'isoWeek')
          })
        }

        this.setState({
          upcomingTitle,
          upcomingLessons: displayUpcomingLessons,
          quickStat: {
            ...quickStat,
            upcomingText: upcomingText,
            upcomingCount: displayUpcomingLessons.length
          }
        })
      }
    }
  }

  toggleLessonDetailsDialog = lessonId => () => {
    const { upcomingLessons } = this.props
    const selectedLesson = _.find(upcomingLessons, o => {
      return o._id === lessonId
    })

    this.setState({
      selectedLesson,
      isLessonDetailDialogOpen: !this.state.isLessonDetailDialogOpen
    })
  }

  toggleSubmitHomeworkDialog = homeworkData => () => {
    this.setState({
      selectedHomework: homeworkData,
      isSubmitHomeworkDialogOpen: !this.state.isSubmitHomeworkDialogOpen
    })
  }

  submissionViewed = submissionId => () => {
    agent.Submission.update('classic', submissionId, { viewed: true, status: 'MARKED' })
  }

  goToSection = sectionId => () => {
    let sectionDOM = document.getElementById(sectionId)
    if (sectionDOM) {
      window.scrollTo({ top: sectionDOM.offsetTop - 70, behavior: 'smooth' })
    }
  }

  togglePreviewDialog = reportData => () => {
    this.setState({
      isPreviewDialogOpen: !this.state.isPreviewDialogOpen,
      selectedReport: reportData
    })
  }

  clickDownloadPDF = reportData => () => {
    const { currentUser } = this.props
    this.props.getReportPDF(reportData._id, `${reportData.title} - ${currentUser.name}.pdf`)
  }

  render() {
    return (
      <div>
        <Paper
          elevation={2}
          className="mb-2 mb-md-0 p-3 pointer"
          onClick={this.props.history.push(USER_PROFILE)}
          style={{ backgroundImage: 'linear-gradient(90deg,#3f51b5 0,#1fb6fc)' }}>
          <h1 className="title--rubik text-white mb-1">Go to Profile</h1>
        </Paper>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
