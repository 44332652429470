import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Login from '../components/public/Login'

import ClassCataloguePage from 'pages/common/online-class/catalogue'
import ClassInfoPage from 'pages/common/online-class/info'

import LandingPage from 'pages/public/landing-sg'
import AccountActivationPage from 'pages/public/account-activation'
import ForgotPasswordPage from 'pages/public/forgot-password'
import ResetPasswordPage from 'pages/public/reset-password'

import AboutUsPage from 'pages/public/about-us'
import TermOfServicePage from 'pages/public/term-of-service'
import PrivacyPolicyPage from 'pages/public/privacy-policy'
import CookiesPolicy from 'pages/public/cookies-policy'

import OurMissionPage from 'pages/public/our-mission'
import ServicesPage from 'pages/public/services'
import TeamPage from 'pages/public/team'
import ContactUsPage from 'pages/public/contact-us'
import NewsPage from 'pages/public/news'

/*PUBLIC ROUTES*/
export const ROOT = '/'
export const LOGIN = '/login'
export const ACCOUNT_ACTIVATION = '/activation'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const ABOUT_US = '/about-us'
export const ALL_CLASSES = '/online-class'
export const CLASS_DETAILS = '/online-class/:classId'
export const IE_WARNING = '/browser-warning'
export const TERM_OF_SERVICE = '/terms-of-service'
export const PRIVACY_POLICY = '/privacy-policy'
export const COOKIES_POLICY = '/cookies-policy'

export const OUR_MISSION = '/mission'
export const SERVICES = '/services'
export const TEAM = '/team'
export const CONTACT = '/contact'
export const NEWS = '/news'

export const Routes = () => {
  return (
    <Switch>
      <Route exact path={ROOT} component={LandingPage} />
      <Route path={LOGIN} component={Login} />
      <Route path={FORGOT_PASSWORD} component={ForgotPasswordPage} />
      <Route path={RESET_PASSWORD} component={ResetPasswordPage} />
      <Route path={ACCOUNT_ACTIVATION} component={AccountActivationPage} />
      <Route path={TERM_OF_SERVICE} component={TermOfServicePage} />
      <Route path={PRIVACY_POLICY} component={PrivacyPolicyPage} />
      <Route path={COOKIES_POLICY} component={CookiesPolicy} />
      <Route path={CLASS_DETAILS} component={ClassInfoPage} />
      <Route exact path={ALL_CLASSES} component={ClassCataloguePage} />

      <Route path={ABOUT_US} component={AboutUsPage} />
      <Route path={OUR_MISSION} component={OurMissionPage} />
      <Route path={SERVICES} component={ServicesPage} />
      <Route path={TEAM} component={TeamPage} />
      <Route path={CONTACT} component={ContactUsPage} />
      <Route path={NEWS} component={NewsPage} />

      <Redirect path="*" to={ROOT} />
    </Switch>
  )
}

/*APP ROUTES*/
export const APP = '/app'
export const USER_DASHBOARD = '/app/dashboard'
export const USER_PROFILE = '/app/profile'
export const USER_PROFILE_EDIT = '/app/profile/edit'
export const USER_STUDENT_PROFILE_EDIT = '/app/profile/student-edit'
export const USER_PARENT_PROFILE_EDIT = '/app/profile/parent-edit'
export const USER_PASSWORD_EDIT = '/app/profile/password'
export const ONLINE_CLASS_LIST = '/app/online-class'
export const REST_OF_CLASSES = '/app/all-classes'
export const ONLINE_CLASS_DETAILS = '/app/online-class/:classId'
export const MY_CLASS_LIST = '/app/class'
export const MY_CLASS_DETAILS = '/app/class/:classId'
