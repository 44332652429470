import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { LOGIN as LOGIN_ROUTE, PRIVACY_POLICY, TERM_OF_SERVICE } from '../../routes/routes'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Checkbox,
  TextField
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import _ from 'lodash'
import qs from 'qs'
import moment from 'moment'
import agent from '../../agent'
import countrycodes from '../../assets/country_code.json'
import educationLevels from 'assets/education-level.json'
import schoolType from 'assets/type-of-school.json'
import schools from 'assets/schools.json'
import gradeLevels from 'assets/level-grade.json'
import yearLevels from 'assets/level-year.json'
import { InputText } from 'components/profile/inputText'

import { LOGIN, REGISTER, REGISTER_UNLOAD } from '../../constants/actionTypes'
import { validatePassword, validateTextEntry, validatePhoneNumber } from '../../utils/formValidation'
import { NumberOnly } from '../../utils/format'

const mapStateToProps = state => ({
  ...state.auth
})

const mapDispatchToProps = dispatch => ({
  activateAccount: reqPayload => dispatch({ type: REGISTER, payload: agent.Auth.activateUser(reqPayload) }), // TODO: activate account shall dispatch a different action
  login: (loginObj, next) => dispatch({ type: LOGIN, payload: agent.Auth.login(loginObj), next }),
  onUnload: () => dispatch({ type: REGISTER_UNLOAD })
})

class QuickSignup extends React.Component {
  state = {
    name: '',
    email: '',
    countryCode: 66,
    phone: '',
    password: '',
    level: '',
    othersLevel: '',
    school: '',
    showOthersLevel: false,
    formError: {},
    classTitle: '',
    tutor: '',
    token: '',
    accountAlreadyVerified: false,
    gradeLevel: '',
    yearLevel: '',
    school: '',
    showOthersSchool: false,
    termsandprivacy: false
  }

  componentDidMount() {
    // NOTE: this few lines is here because in early day there is a problem with the url send by backend. Still leave it here because no harm having.
    let search = window.location.href.split('&#x3D;').join('=')
    search = search.split('/activation?')
    if (search.length > 0) {
      search = search[1]
    }
    let parsed = qs.parse(search)
    this.setState({
      email: parsed.email,
      classTitle: parsed.class_name,
      tutor: parsed.tutor,
      token: parsed.token,
      nextLocation: parsed.next ? parsed.next : ''
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginUser) {
      this.props.login(
        {
          email: this.state.email,
          password: this.state.password
        },
        this.state.nextLocation
      )
    }

    if (prevProps.inProgress && !this.props.inProgress) {
      const { errors } = this.props
      if (errors?.errors === 'already_verified') {
        this.setState({ accountAlreadyVerified: true })
      }
    }
  }

  componentWillUnmount() {
    this.props.onUnload()
  }

  validateFormElement = (type, customMsg) => {
    let errorMsg
    if (type === 'password') {
      errorMsg = validatePassword(this.state[type])
    } else if (type === 'phone') {
      if (this.state.countryCode === 66) {
        errorMsg = validatePhoneNumber(this.state[type], this.state.countryCode)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    } else {
      if (customMsg) {
        errorMsg = validateTextEntry(this.state[type], type, customMsg)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  handleCheckboxChange = (e, checked) => {
    this.setState({ [e.target.name]: checked })
  }

  onChangeFormElement = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      let tempFormError = this.state.formError
      delete tempFormError[type]
      this.setState({ formError: tempFormError })
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }
  }

  onFocusFormElement = e => {
    let type = e.target.name
    let tempFormError = this.state.formError
    delete tempFormError[type]
    this.setState({ formError: tempFormError })
  }

  clickSignUp = () => {
    this.validateFormElement('name')
    this.validateFormElement('phone')
    this.validateFormElement('password')
    if (this.state.level === 'others') {
      this.validateFormElement('othersLevel', 'your level')
    } else {
      this.validateFormElement('level')
    }
    this.validateFormElement('school')

    if (_.isEmpty(this.state.formError)) {
      let level =
        this.state.level === 'others'
          ? this.state.othersLevel
          : // : this.state.level === 'year'
          // ? this.state.yearLevel
          // : this.state.level === 'grade'
          // ? this.state.gradeLevel
          this.state.level === 'year'
          ? 'year'
          : this.state.level === 'grade'
          ? 'grade'
          : this.state.level
      let reqPayload = {
        token: this.state.token,
        name: this.state.name,
        phone_country_code: this.state.countryCode,
        phone: this.state.phone,
        password: this.state.password,
        level,
        school: this.state.school,
        is_other_level: this.state.level === 'others' ? true : false,
        gradeYearLevel: {
          grade: this.state.gradeLevel,
          year: this.state.yearLevel
        },
        gradeOrYear: this.state.level === 'year' ? 'Year' : this.state.level === 'grade' ? 'Grade' : 'Others'
      }
      this.props.activateAccount(reqPayload)
    }
  }

  handleAutoCompleteChange = (e, value) => {
    this.setState({ school: value.name })

    if (value.code === 'others') {
      this.setState({ showOthersSchool: true })
    } else {
      this.setState({ showOthersSchool: false })
    }
  }

  closeAccountAlreadyVerifiedDialog = () => {
    this.setState({ accountAlreadyVerified: false })
    this.props.history.push('/login')
  }

  render() {
    const {
      name,
      email,
      countryCode,
      phone,
      password,
      accountAlreadyVerified,
      level,
      othersLevel,
      showOthersLevel,
      othersSchool,
      showOthersSchool,
      school,
      gradeLevel,
      yearLevel,
      typeOfSchool,
      othersTypeOfSchool,
      showOthersTypeOfSchool,
      formError,
      classTitle,
      tutor,
      termsandprivacy
    } = this.state
    const { inProgress } = this.props

    return (
      <div className="d-flex login-container justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <Helmet>
          <title>Account Activation | EPA</title>
          <meta
            name="description"
            content="EPA is committed to finding the right solution for every student. With a tailored approach, we empower each student to become independent and life-ready. By getting to know each student’s strengths and limitations, we aim to provide an education that is goal-oriented and customized to their specific needs, aspirations, and capabilities. The student’s success is not just about the top grades or prestige, but genuine growth as a learner and an unique individual."
          />
          <meta
            name="keywords"
            content="right solution for every student, tailored approach, independent and life-ready, student’s strengths and limitations, goal-oriented education, customized specific needs, aspirations, and capabilities, top grades, prestige, genuine growth as a learner and an unique individual"
          />
        </Helmet>
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-6 col-12">
              <div className="text-center mb-3">
                <h1 className="title--rubik">Welcome to EPA!</h1>
                <span className="text-large">You're joining this class:</span>
              </div>
              <Paper elevation={3} className="p-3">
                <h2 className="title--rubik mb-2">{classTitle}</h2>
                <div className="text-muted text-large">by Teacher {tutor}</div>
              </Paper>
            </div>
            <div className="col-md-5 offset-md-1 col-12">
              <div className="text-center">
                <h2 className="title--rubik mb-4">Complete registration to get started</h2>

                <div className="login-form">
                  <TextField
                    type="email"
                    helperText="This is the email address which will be used to contact you."
                    label="Email"
                    name="email"
                    fullWidth
                    value={email}
                    margin="normal"
                    className="mt-0 mb-3"
                    disabled
                  />

                  <TextField
                    type="text"
                    label="Student's Name"
                    name="name"
                    onChange={this.onChangeFormElement}
                    error={!!formError.name}
                    helperText={formError.name}
                    onFocus={this.onFocusFormElement}
                    fullWidth
                    defaultValue={name}
                    margin="normal"
                    className="mt-0 mb-2"
                  />

                  <div className="row align-items-end no-gutters mb-2">
                    <div className="col-auto">
                      <Select
                        value={countryCode}
                        onChange={this.onChangeFormElement}
                        className="mt-0"
                        name="countryCode">
                        {countrycodes.map(cc => {
                          return (
                            <MenuItem key={cc.code} value={cc.code}>
                              <span className={`flag ${cc.flag} mr-1`} /> +{cc.code}{' '}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </div>
                    <div className="col">
                      <TextField
                        type="text"
                        label="Phone Number"
                        name="phone"
                        onChange={this.onChangeFormElement}
                        error={!!formError.phone}
                        onFocus={this.onFocusFormElement}
                        InputProps={{ inputComponent: NumberOnly }}
                        fullWidth
                        value={phone}
                        margin="normal"
                        className="mt-0 mb-0"
                      />
                    </div>
                    <div className="col-12">
                      <FormHelperText error={!!formError.phone}>
                        {formError.phone ??
                          "We'll provide customer support over the phone in case you face any issues for your classes"}
                      </FormHelperText>
                    </div>
                  </div>

                  <TextField
                    type="password"
                    onChange={this.onChangeFormElement}
                    error={!!this.state.formError.password}
                    helperText={formError.password ?? 'minimum 6 characters'}
                    onFocus={this.onFocusFormElement}
                    id="required"
                    label="Set your own password"
                    name="password"
                    fullWidth
                    defaultValue={password}
                    margin="normal"
                    className="mt-0 mb-2"
                  />
                  <div className="row flex ml-1">
                    <FormControl className="mb-2" error={!!formError.level}>
                      <InputLabel>Level/Grade</InputLabel>
                      <Select value={level} onChange={this.onChangeFormElement} name="level" className=" text-left">
                        {educationLevels.map(level => {
                          return (
                            <MenuItem key={level.code} value={level.code}>
                              {level.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                      <FormHelperText>
                        Grade/ Year in School starting from January {moment().format('YYYY')}
                      </FormHelperText>
                      {formError.level && <FormHelperText>{formError.level}</FormHelperText>}
                    </FormControl>

                    <FormControl className="mb-2" error={!!formError.level}>
                      <div className="col-auto mt-3">
                        {level === 'grade' && (
                          <Select value={gradeLevel} onChange={this.onChangeFormElement} name="gradeLevel">
                            {gradeLevels.map(level => {
                              return (
                                <MenuItem key={level.code} value={level.code}>
                                  {level.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        )}
                        {level === 'year' && (
                          <Select value={yearLevel} onChange={this.onChangeFormElement} name="yearLevel">
                            {yearLevels.map(level => {
                              return (
                                <MenuItem key={level.code} value={level.code}>
                                  {level.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        )}
                      </div>
                      {/* same concept applied to year or grade */}
                      {showOthersLevel && (
                        <TextField
                          type="text"
                          label="Please specify your level"
                          name="othersLevel"
                          onChange={this.onChangeFormElement}
                          error={!!formError.othersLevel}
                          helperText={formError.othersLevel}
                          onFocus={this.onFocusFormElement}
                          fullWidth
                          defaultValue={othersLevel}
                          className="ml-3"
                          style={{ marginTop: '-17px' }}
                        />
                      )}{' '}
                    </FormControl>
                  </div>
                  {/* 
                  <TextField
                    type="text"
                    label="School"
                    name="school"
                    onChange={this.onChangeFormElement}
                    error={!!formError.school}
                    helperText={formError.school}
                    onFocus={this.onFocusFormElement}
                    fullWidth
                    defaultValue={school}
                    margin="normal"
                    className="mt-0 mb-4"
                  />

               */}
                  <Autocomplete
                    id="combo-box-demo"
                    className="mb-4"
                    name="school"
                    options={schools}
                    onChange={(event, value) => {
                      if (value !== null) this.handleAutoCompleteChange(event, value)
                    }}
                    getOptionLabel={option => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    renderInput={params => <TextField {...params} label="Choose a school" />}
                  />

                  <div className="col-12 row mb-3">
                    <div className="col-1 mt-2">
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={'termsandprivacy'}
                              onChange={this.handleCheckboxChange}
                              checked={termsandprivacy}
                            />
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="col-11 mt-2">
                      By signing up and checking the box, I agree with EPA&#697;s{' '}
                      <Link to={TERM_OF_SERVICE} target="_blank">
                        terms of service
                      </Link>{' '}
                      &{' '}
                      <Link to={PRIVACY_POLICY} target={'_blank'}>
                        privacy policy
                      </Link>
                    </div>
                  </div>

                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={this.clickSignUp}
                    disabled={!termsandprivacy}>
                    Sign Up {inProgress && <CircularProgress size={24} className="ml-2" />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog open={accountAlreadyVerified} onClose={this.closeAccountAlreadyVerifiedDialog}>
          <DialogContent>
            <DialogContentText>Thank you, your account activation is done. Please log in instead.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="text-capitalize"
              onClick={this.closeAccountAlreadyVerifiedDialog}
              color="primary"
              variant="contained">
              Log In
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickSignup))
