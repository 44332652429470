import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import {
  USER_PROFILE,
  USER_PROFILE_EDIT,
  USER_PASSWORD_EDIT,
  ONLINE_CLASS_LIST,
  ONLINE_CLASS_DETAILS,
  MY_CLASS_LIST,
  MY_CLASS_DETAILS,
  APP,
  PRIVACY_POLICY,
  TERM_OF_SERVICE
} from './routes'

import ClassListing from '../components/student/Classes'
import HomeworkDetailPage from '../components/student/Homework'

import PrivacyPolicyPage from 'pages/public/privacy-policy'
import TermOfServicePage from 'pages/public/term-of-service'

import ClassCataloguePage from 'pages/common/online-class/catalogue'
import ClassInfoPage from 'pages/common/online-class/info'

import DashboardPage from 'pages/app/dashboard/student'
import MyOngoingClassesPage from 'pages/app/my-class/list/student/ongoing'
import MyCompletedClassesPage from 'pages/app/my-class/list/student/completed'
import MyClassDetailsPage from 'pages/app/my-class/detail/student'
import MyLessonDetailsPage from 'pages/app/my-lesson/student'
import StudentProfilePage from 'pages/app/profile/student'
import StudentProfileEditPage from 'pages/app/profile/student/edit'
import EditPasswordPage from 'pages/app/profile/edit-password'

export const TRANSACTION_HISTORY = '/transaction'
export const HOMEWORK_DETAIL = '/hw/:homeworkId'
export const POST_PAYMENT = '/cart/post-payment'
export const DASHBOARD = '/dashboard'
export const MY_PAID_CLASSES = '/my-class/paid'
export const MY_ARCHIVED_CLASSES = '/my-class/archived'
export const MY_LESSON_DETAILS = '/my-lesson/:lessonId'

/*App Route*/
export const StudentRoutes = () => (
  <Switch>
    <Route exact path={USER_PROFILE} component={StudentProfilePage} />
    <Route exact path={USER_PROFILE_EDIT} component={StudentProfileEditPage} />
    <Route exact path={USER_PASSWORD_EDIT} component={EditPasswordPage} />
    <Route path={MY_CLASS_DETAILS} component={MyClassDetailsPage} />
    <Route path={MY_CLASS_LIST} component={ClassListing} />
    <Route exact path={ONLINE_CLASS_LIST} component={ClassCataloguePage} />
    <Route path={ONLINE_CLASS_DETAILS} component={ClassInfoPage} />
    <Route path={`${APP}${PRIVACY_POLICY}`} component={PrivacyPolicyPage} />
    <Route path={`${APP}${TERM_OF_SERVICE}`} component={TermOfServicePage} />
    <Route path={`${APP}${HOMEWORK_DETAIL}`} component={HomeworkDetailPage} />
    <Route path={`${APP}${DASHBOARD}`} component={DashboardPage} />
    <Route path={`${APP}${MY_PAID_CLASSES}`} component={MyOngoingClassesPage} />
    <Route path={`${APP}${MY_ARCHIVED_CLASSES}`} component={MyCompletedClassesPage} />
    <Route path={`${APP}${MY_LESSON_DETAILS}`} component={MyLessonDetailsPage} />
    <Redirect path="*" to={`${APP}${DASHBOARD}`} />
  </Switch>
)
