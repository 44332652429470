import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import agent from 'agent'
import educationLevels from 'assets/education-level.json'
import { UPDATE_USER, UPDATE_PARENT, UPDATE_STUDENT_PROFILE, GET_CHILD_DETAILS } from 'constants/actionTypes'
import { validateTextEntry, validatePassword } from 'utils/formValidation'
import StepperProfile from 'components/nav/app/profile/profile-progress'
import schoolType from 'assets/type-of-school.json'
import schools from 'assets/schools.json'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  updateUserInProgress: state.common.updateUserInProgress
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch({ type: UPDATE_USER, payload: agent.Auth.updateUser(user), user }),
  updateStudentProfileFromParent: (studentId, userObj) =>
    dispatch({ type: UPDATE_STUDENT_PROFILE, payload: agent.Auth.updateStudentProfileFromParent(studentId, userObj) })
})

class Edit extends React.Component {
  state = {
    name: '',
    email: '',
    dateOfBirth: '',
    gender: '',
    phoneCountryCode: 65,
    phone: '',
    level: '',
    othersLevel: '',
    showOthersLevel: false,
    school: '',
    othersSchool: '',
    showOthersSchool: false,
    parentSalute: '',
    parentName: '',
    parentEmail: '',
    parentPhoneCountryCode: 65,
    parentPhone: '',
    useStudentPhone: false,
    useStudentEmail: false,
    contactPreference: 'nil',
    contactPreferenceOther: '',
    facebook: '',
    line: '',
    formError: {},
    isSaved: false,

    studyMediumEng: false,
    studyMediumThai: false,

    studyingAtOtherSchool: 'nil',
    studyingAtOtherSchoolName: '',

    gpaEngBox: true,
    gpaThaiBox: true,
    gpaMathsBox: true,
    gpaPhysBox: true,
    gpaChemBox: true,
    gpaBioBox: true,
    gpaGeoBox: true, //geography
    gpaHistBox: true,
    gpaLitBox: true, //literature
    gpaSSBox: true, //social studies
    gpaPolBox: true, //politics
    gpaEcoBox: true, //economics
    gpaArtBox: true,
    gpaFLBox: true, //foreign languageg,
    gpaOthersBox: true,

    gpaEng: '',
    gpaThai: '',
    gpaMaths: '',
    gpaPhys: '',
    gpaChem: '',
    gpaBio: '',
    gpaGeo: '',
    gpaHist: '',
    gpaLit: '',
    gpaSS: '',
    gpaPol: '',
    gpaEco: '',
    gpaArt: '',
    gpaFL: '',
    gpaOthersField: '',

    favSubjEngBox: false,
    favSubjThaiBox: false,
    favSubjMathsBox: false,
    favSubjPhysBox: false,
    favSubjChemBox: false,
    favSubjBioBox: false,
    favSubjGeoBox: false, //geography
    favSubjHistBox: false,
    favSubjLitBox: false, //literature
    favSubjSSBox: false, //social studies
    favSubjPolBox: false, //politics
    favSubjEcoBox: false, //economics
    favSubjArtBox: false,
    favSubjFLBox: false, //foreign language
    favSubjOthersBox: false,
    favSubjOthersBoxField: '',

    weakestSubjEngBox: false,
    weakestSubjThaiBox: false,
    weakestSubjMathsBox: false,
    weakestSubjPhysBox: false,
    weakestSubjChemBox: false,
    weakestSubjBioBox: false,
    weakestSubjGeoBox: false, //geography
    weakestSubjHistBox: false,
    weakestSubjLitBox: false, //literature
    weakestSubjSSBox: false, //social studies
    weakestSubjPolBox: false, //politics
    weakestSubjEcoBox: false, //economics
    weakestSubjArtBox: false,
    weakestSubjFLBox: false, //foreign language
    weakestSubjOthersBox: false,
    weakestSubjOthersBoxField: '',

    extExamSAT: false,
    extExamGRE: false,
    extExamIELTS: false,
    extExamTOEFL: false,
    extExamGMAT: false,
    extExamOthers: false,
    extExamOthersField: '',

    otherTCcourseSAT: false,
    otherTCcourseGRE: false,
    otherTCcourseIELTS: false,
    otherTCcourseTOEFL: false,
    otherTCcourseGMAT: false,
    otherTCcourseEnglish: false,
    otherTCcourseThai: false,
    otherTCcourseMath: false,
    otherTCcoursePhysics: false,
    otherTCcourseBiology: false,
    otherTCcourseGeography: false,
    otherTCcourseChemistry: false,
    otherTCcourseHistory: false,
    otherTCcourseLiterature: false,
    otherTCcourseSocialStudies: false,
    otherTCcoursePolitics: false,
    otherTCcourseEconomics: false,
    otherTCcourseArt: false,
    otherTCcourseForeignLanguage: false,
    otherTCcourseOthers: false,
    otherTCcourseOthersField: '',

    studyingAtEPA: 'nil',
    coursesTakenAtEpaSAT: false,
    coursesTakenAtEpaGRE: false,
    coursesTakenAtEpaIELTS: false,
    coursesTakenAtEpaTOEFL: false,
    coursesTakenAtEpaGMAT: false,
    coursesTakenAtEpaEnglish: false,
    coursesTakenAtEpaThai: false,
    coursesTakenAtEpaMath: false,
    coursesTakenAtEpaPhysics: false,
    coursesTakenAtEpaBiology: false,
    coursesTakenAtEpaGeography: false,
    coursesTakenAtEpaChemistry: false,
    coursesTakenAtEpaHistory: false,
    coursesTakenAtEpaLiterature: false,
    coursesTakenAtEpaSocialStudies: false,
    coursesTakenAtEpaPolitics: false,
    coursesTakenAtEpaEconomics: false,
    coursesTakenAtEpaArt: false,
    coursesTakenAtEpaForeignLanguage: false,
    coursesTakenAtEpaOthers: false,
    coursesTakenAtEpaOthersField: '',

    careerGoalsStudentPerspective: '',
    careerGoalsParentPerspective: '',

    studyGoalsStudentPerspectiveEntranceExamBox: false,
    studyGoalsStudentPerspectiveEntranceExamField: '',
    studyGoalsStudentPerspectiveSchoolExamBox: false,
    studyGoalsStudentPerspectiveSATBox: false,
    studyGoalsStudentPerspectiveGREBox: false,
    studyGoalsStudentPerspectiveIELTSBox: false,
    studyGoalsStudentPerspectiveTOEFLBox: false,
    studyGoalsStudentPerspectiveGMATBox: false,
    studyGoalsStudentPerspectiveOthersBox: false,
    studyGoalsStudentPerspectiveOthersField: '',

    studyGoalsParentPerspectiveEntranceExamBox: false,
    studyGoalsParentPerspectiveEntranceExamField: '',
    studyGoalsParentPerspectiveSchoolExamBox: false,
    studyGoalsParentPerspectiveSATBox: false,
    studyGoalsParentPerspectiveGREBox: false,
    studyGoalsParentPerspectiveIELTSBox: false,
    studyGoalsParentPerspectiveTOEFLBox: false,
    studyGoalsParentPerspectiveGMATBox: false,
    studyGoalsParentPerspectiveOthersBox: false,
    studyGoalsParentPerspectiveOthersField: '',

    targetCountryStudentUS: false,
    targetCountryStudentCanada: false,
    targetCountryStudentUK: false,
    targetCountryStudentAustralia: false,
    targetCountryStudentNewZealand: false,
    targetCountryStudentOthersBox: false,
    targetCountryStudentOthersField: '',

    targetCountryParentUS: false,
    targetCountryParentCanada: false,
    targetCountryParentUK: false,
    targetCountryParentAustralia: false,
    targetCountryParentNewZealand: false,
    targetCountryParentOthersBox: false,
    targetCountryParentOthersField: '',

    areaOfStudy: '',
    areaOfStudySpecific: '',
    areaOfStudy1: '',
    areaOfStudySpecific1: '',
    areaOfStudy2: '',
    areaOfStudySpecific2: '',
    areaOfStudy3: '',
    areaOfStudySpecific3: '',
    areaOfStudy4: '',
    areaOfStudySpecific4: '',
    areaOfStudyOthers: false,
    areaOfStudyOthersField: '',
    areaOfStudyUnsure: false,

    areaOfStudyParent: '',
    areaOfStudyParentSpecific: '',
    areaOfStudyParent1: '',
    areaOfStudyParentSpecific1: '',
    areaOfStudyParent2: '',
    areaOfStudyParentSpecific2: '',
    areaOfStudyParent3: '',
    areaOfStudyParentSpecific3: '',
    areaOfStudyParent4: '',
    areaOfStudyParentSpecific4: '',
    areaOfStudyParentOthers: false,
    areaOfStudyParentOthersField: '',
    areaOfStudyParentUnsure: false,

    specialRequests: '',

    learningTypeVisual: false,
    learningTypeKinaesthetic: false,
    learningTypeAural: false,
    learningTypeSocial: false,
    learningTypeSolitary: false,
    learningTypeVerbal: false,
    learningTypeLogical: false,
    learningTypeUnsure: false,
    learningTypeOthersBox: false,
    learningTypeOthersField: '',

    personalityTestCheck: 'nil',
    personalityTestResult: '',
    personalityTestResultField: '',

    scDominanceDirect: false,
    scDominanceResultsOriented: false,
    scDominanceFirm: false,
    scDominanceStrongWilled: false,
    scDominanceForceful: false,

    scInfluenceOutgoing: false,
    scInfluenceEnthusiastic: false,
    scInfluenceOptimistic: false,
    scInfluenceHighSpirited: false,
    scInfluenceLively: false,
    scConscientiousnessAnalytical: false,
    scConscientiousnessReserved: false,
    scConscientiousnessPrecise: false,
    scConscientiousnessPrivate: false,
    scConscientiousnessSystematic: false,
    scSteadinessEvenTempered: false,
    scSteadinessAccommodating: false,
    scSteadinessPatient: false,
    scSteadinessHumble: false,
    scSteadinessTactful: false,

    healthConditionNA: false,
    healthConditionADHD: false,
    healthConditionAutism: false,
    healthConditionOthersBox: false,
    healthConditionOthersField: '',

    preferredTeacherStudentPerspectiveMale: false,
    preferredTeacherStudentPerspectiveFemale: false,
    preferredTeacherStudentPerspectiveNameBox: false,
    preferredTeacherStudentPerspectiveNameField: '',
    preferredTeacherStudentPerspectiveAuthority: false,
    preferredTeacherStudentPerspectiveDelegator: false,
    preferredTeacherStudentPerspectiveFacilitator: false,
    preferredTeacherStudentPerspectiveDemonstrator: false,
    preferredTeacherStudentPerspectiveHybrid: false,
    preferredTeacherStudentPerspectiveOthersBox: false,
    preferredTeacherStudentPerspectiveOthersField: '',

    preferredTeacherParentPerspectiveMale: false,
    preferredTeacherParentPerspectiveFemale: false,
    preferredTeacherParentPerspectiveNameBox: false,
    preferredTeacherParentPerspectiveNameField: '',
    preferredTeacherParentPerspectiveAuthority: false,
    preferredTeacherParentPerspectiveDelegator: false,
    preferredTeacherParentPerspectiveFacilitator: false,
    preferredTeacherParentPerspectiveDemonstrator: false,
    preferredTeacherParentPerspectiveHybrid: false,
    preferredTeacherParentPerspectiveOthersBox: false,
    preferredTeacherParentPerspectiveOthersField: '',

    interestedTopicsPublicSpeaking: false,
    interestedTopicsDebate: false,
    interestedTopicsCoding: false,
    interestedTopicsLeadership: false,
    interestedTopicsWriting: false,
    interestedTopicsCreativeWriting: false,
    interestedTopicsFormalWriting: false,
    interestedTopicsReadingComprehension: false,
    interestedTopicsLiterature: false,
    interestedTopicsOthersBox: false,
    interestedTopicsOthersField: '',

    parentGender: '',
    parentDOB: '',
    parentSalute: 'Mr',
    parentName: '',
    parentEmail: '',
    parentPhoneCountryCode: '',
    parentPhone: '',

    parentContactPreference: 'nil',
    parentContactPreferenceOther: '',
    parentFacebook: '',
    parentLine: '',

    parentOccupation: '',
    parentOccupationSpecific: '',
    parentFamilyBackground: '',
    parentNetworkingFB: false,
    parentNetworkingWebsite: false,
    parentNetworkingFriends: false,
    parentNetworkingOthersBox: false,
    parentNetworkingOthersField: '',

    pcDominanceDirect: false,
    pcDominanceResultsOriented: false,
    pcDominanceFirm: false,
    pcDominanceStrongWilled: false,
    pcDominanceForceful: false,
    pcInfluenceOutgoing: false,
    pcInfluenceEnthusiastic: false,
    pcInfluenceOptimistic: false,
    pcInfluenceHighSpirited: false,
    pcInfluenceLively: false,
    pcConscientiousnessAnalytical: false,
    pcConscientiousnessReserved: false,
    pcConscientiousnessPrecise: false,
    pcConscientiousnessPrivate: false,
    pcConscientiousnessSystematic: false,
    pcSteadinessEvenTempered: false,
    pcSteadinessAccommodating: false,
    pcSteadinessPatient: false,
    pcSteadinessHumble: false,
    pcSteadinessTactful: false,
    parentConflict: false,
    parentDecisionFather: false,
    parentDecisionMother: false,

    paymentMode: 'nil',
    parentInterestedTopicsPublicSpeaking: false,
    parentInterestedTopicsDebate: false,
    parentInterestedTopicsCoding: false,
    parentInterestedTopicsLeadership: false,
    parentInterestedTopicsWriting: false,
    parentInterestedTopicsCreativeWriting: false,
    parentInterestedTopicsFormalWriting: false,
    parentInterestedTopicsReadingComprehension: false,
    parentInterestedTopicsLiterature: false,
    parentInterestedTopicsOthersBox: false,
    parentInterestedTopicsOthersField: '',
    parentDateOfRenewal: ''
  }

  componentDidMount() {
    const { meta } = this.props.location
    if (!meta) {
      this.props.history.push('/app/class')
    }
    let displayLevel = '',
      showOthersLevel = false,
      othersLevel = '',
      displaySchool = '',
      showOthersSchool = false,
      othersSchool = '',
      displayOtherTypeOfSchool = '',
      showOthersTypeOfSchool = false,
      othersTypeOfSchool = ''

    if (meta?.level) {
      let levelObj = _.find(educationLevels, function (o) {
        return o.code === meta?.level
      })
      if (levelObj) {
        displayLevel = levelObj.code
      } else {
        showOthersLevel = true
        othersLevel = meta?.level
        displayLevel = 'others'
      }
    }

    if (meta?.school) {
      let schoolObj = _.find(schools, function (o) {
        return o.name === meta?.school
      })
      if (schoolObj) {
        displaySchool = schoolObj.name
      } else {
        showOthersSchool = true
        othersSchool = meta?.school
        displaySchool = 'others'
      }
    }

    if (meta?.typeOfSchool) {
      let schoolTypeObj = _.find(schoolType, function (o) {
        return o.code === meta?.typeOfSchool
      })
      if (schoolTypeObj) {
        displayOtherTypeOfSchool = schoolTypeObj.code
      } else {
        showOthersTypeOfSchool = true
        othersTypeOfSchool = meta?.typeOfSchool
        displayOtherTypeOfSchool = 'others'
      }
    }

    this.setState({
      name: meta?.name ?? '',
      email: meta?.email ?? '',
      dateOfBirth: meta?.date_of_birth ? moment(meta?.date_of_birth).startOf('day').format('YYYY-MM-DD') : '',
      gender: meta?.gender ?? '',
      phoneCountryCode: meta?.phone_country_code,
      phone: meta?.phone ?? '',
      level: displayLevel,
      othersLevel,
      showOthersLevel,
      // school: meta?.school ?? '',
      school: displaySchool,
      othersSchool,
      showOthersSchool,
      typeOfSchool: displayOtherTypeOfSchool,
      othersTypeOfSchool,
      showOthersTypeOfSchool,
      firstLanguage: meta?.first_language ?? '',
      contactPreference: meta?.contact_preference?.contact ?? 'nil',
      contactPreferenceOther: meta?.contact_preference?.others_type ?? '',
      facebook: meta?.facebook ?? '',
      line: meta?.line ?? '',

      gradeLevel: meta?.gradeYearLevel?.grade ?? '',
      yearLevel: meta?.gradeYearLevel?.year ?? '',

      studyMediumEng: meta?.studyMediumEng ? true : false,
      studyMediumThai: meta?.studyMediumThai ? true : false,

      studyingAtOtherSchool: meta?.studyingAtOtherSchool?.at_other_school ?? 'nil',
      studyingAtOtherSchoolName: meta?.studyingAtOtherSchool?.at_other_school_name ?? '',

      gpaEngBox: meta?.grade?.english ? true : false,
      gpaThaiBox: meta?.grade?.thai ? true : false,
      gpaMathsBox: meta?.grade?.math ? true : false,
      gpaPhysBox: meta?.grade?.physics ? true : false,
      gpaChemBox: meta?.grade?.chemistry ? true : false,
      gpaBioBox: meta?.grade?.biology ? true : false,
      gpaGeoBox: meta?.grade?.geography ? true : false, //geography
      gpaHistBox: meta?.grade?.history ? true : false,
      gpaLitBox: meta?.grade?.literature ? true : false, //literature
      gpaSSBox: meta?.grade?.social_studies ? true : false, //social studies
      gpaPolBox: meta?.grade?.politics ? true : false, //politics
      gpaEcoBox: meta?.grade?.economics ? true : false, //economics
      gpaArtBox: meta?.grade?.art ? true : false,
      gpaFLBox: meta?.grade?.foreign_language ? true : false, //foreign language
      gpaOthersBox: meta?.grade?.others ? true : false,

      gpaEng: meta?.grade?.gpa?.english ?? '',
      gpaThai: meta?.grade?.gpa?.thai ?? '',
      gpaMaths: meta?.grade?.gpa?.math ?? '',
      gpaPhys: meta?.grade?.gpa?.physics ?? '',
      gpaChem: meta?.grade?.gpa?.chemistry ?? '',
      gpaBio: meta?.grade?.gpa?.biology ?? '',
      gpaGeo: meta?.grade?.gpa?.geography ?? '',
      gpaHist: meta?.grade?.gpa?.history ?? '',
      gpaLit: meta?.grade?.gpa?.literature ?? '',
      gpaSS: meta?.grade?.gpa?.social_studies ?? '',
      gpaPol: meta?.grade?.gpa?.politics ?? '',
      gpaEco: meta?.grade?.gpa?.economics ?? '',
      gpaArt: meta?.grade?.gpa?.art ?? '',
      gpaFL: meta?.grade?.gpa?.foreign_language ?? '',
      gpaOthersField: meta?.grade?.gpa?.others_type ?? '',

      favSubjEngBox: meta?.favoriteSubjects?.english ? true : false,
      favSubjThaiBox: meta?.favoriteSubjects?.thai ? true : false,
      favSubjMathsBox: meta?.favoriteSubjects?.math ? true : false,
      favSubjPhysBox: meta?.favoriteSubjects?.physics ? true : false,
      favSubjChemBox: meta?.favoriteSubjects?.chemistry ? true : false,
      favSubjBioBox: meta?.favoriteSubjects?.biology ? true : false,
      favSubjGeoBox: meta?.favoriteSubjects?.geography ? true : false,
      favSubjHistBox: meta?.favoriteSubjects?.history ? true : false,
      favSubjLitBox: meta?.favoriteSubjects?.literature ? true : false,
      favSubjSSBox: meta?.favoriteSubjects?.social_studies ? true : false,
      favSubjPolBox: meta?.favoriteSubjects?.politics ? true : false,
      favSubjEcoBox: meta?.favoriteSubjects?.economics ? true : false,
      favSubjArtBox: meta?.favoriteSubjects?.art ? true : false,
      favSubjFLBox: meta?.favoriteSubjects?.foreign_language ? true : false,
      favSubjOthersBox: meta?.favoriteSubjects?.others ? true : false,
      favSubjOthersBoxField: meta?.favoriteSubjects?.others_type ?? '',

      weakestSubjEngBox: meta?.weakestSubjects?.english ? true : false,
      weakestSubjThaiBox: meta?.weakestSubjects?.thai ? true : false,
      weakestSubjMathsBox: meta?.weakestSubjects?.math ? true : false,
      weakestSubjPhysBox: meta?.weakestSubjects?.physics ? true : false,
      weakestSubjChemBox: meta?.weakestSubjects?.chemistry ? true : false,
      weakestSubjBioBox: meta?.weakestSubjects?.biology ? true : false,
      weakestSubjGeoBox: meta?.weakestSubjects?.geography ? true : false, //geography
      weakestSubjHistBox: meta?.weakestSubjects?.history ? true : false,
      weakestSubjLitBox: meta?.weakestSubjects?.literature ? true : false, //literature
      weakestSubjSSBox: meta?.weakestSubjects?.social_studies ? true : false, //social studies
      weakestSubjPolBox: meta?.weakestSubjects?.politics ? true : false, //politics
      weakestSubjEcoBox: meta?.weakestSubjects?.economics ? true : false, //economics
      weakestSubjArtBox: meta?.weakestSubjects?.art ? true : false,
      weakestSubjFLBox: meta?.weakestSubjects?.foreign_language ? true : false, //foreign language
      weakestSubjOthersBox: meta?.weakestSubjects?.others ? true : false,
      weakestSubjOthersBoxField: meta?.weakestSubjects?.others_type ?? '',

      extExamSAT: meta?.externalExams?.sat ? true : false,
      extExamGRE: meta?.externalExams?.gre ? true : false,
      extExamIELTS: meta?.externalExams?.ielts ? true : false,
      extExamTOEFL: meta?.externalExams?.toefl ? true : false,
      extExamGMAT: meta?.externalExams?.gmat ? true : false,
      extExamOthers: meta?.externalExams?.others ? true : false,
      extExamOthersField: meta?.externalExams?.others_type ?? '',

      otherTCcourseSAT: meta?.coursesTuition?.sat ? true : false,
      otherTCcourseGRE: meta?.coursesTuition?.gre ? true : false,
      otherTCcourseIELTS: meta?.coursesTuition?.ielts ? true : false,
      otherTCcourseTOEFL: meta?.coursesTuition?.toefl ? true : false,
      otherTCcourseGMAT: meta?.coursesTuition?.gmat ? true : false,
      otherTCcourseEnglish: meta?.coursesTuition?.english ? true : false,
      otherTCcourseThai: meta?.coursesTuition?.thai ? true : false,
      otherTCcourseMath: meta?.coursesTuition?.math ? true : false,
      otherTCcoursePhysics: meta?.coursesTuition?.physics ? true : false,
      otherTCcourseBiology: meta?.coursesTuition?.biology ? true : false,
      otherTCcourseGeography: meta?.coursesTuition?.geography ? true : false,
      otherTCcourseChemistry: meta?.coursesTuition?.chemistry ? true : false,
      otherTCcourseHistory: meta?.coursesTuition?.history ? true : false,
      otherTCcourseLiterature: meta?.coursesTuition?.literature ? true : false,
      otherTCcourseSocialStudies: meta?.coursesTuition?.social_studies ? true : false,
      otherTCcoursePolitics: meta?.coursesTuition?.politics ? true : false,
      otherTCcourseEconomics: meta?.coursesTuition?.economics ? true : false,
      otherTCcourseArt: meta?.coursesTuition?.art ? true : false,
      otherTCcourseForeignLanguage: meta?.coursesTuition?.foreign_language ? true : false,
      otherTCcourseOthers: meta?.coursesTuition?.others ? true : false,
      otherTCcourseOthersField: meta?.coursesTuition?.others_type ?? '',

      studyingAtEPA: meta?.currentlyAtEPA?.studyingAtEPA ?? 'n',
      coursesTakenAtEpaSAT: meta?.currentlyAtEPA?.coursesTakenAtEPA?.sat ? true : false,
      coursesTakenAtEpaGRE: meta?.currentlyAtEPA?.coursesTakenAtEPA?.gre ? true : false,
      coursesTakenAtEpaIELTS: meta?.currentlyAtEPA?.coursesTakenAtEPA?.ielts ? true : false,
      coursesTakenAtEpaTOEFL: meta?.currentlyAtEPA?.coursesTakenAtEPA?.toefl ? true : false,
      coursesTakenAtEpaGMAT: meta?.currentlyAtEPA?.coursesTakenAtEPA?.gmat ? true : false,
      coursesTakenAtEpaEnglish: meta?.currentlyAtEPA?.coursesTakenAtEPA?.english ? true : false,
      coursesTakenAtEpaThai: meta?.currentlyAtEPA?.coursesTakenAtEPA?.thai ? true : false,
      coursesTakenAtEpaMath: meta?.currentlyAtEPA?.coursesTakenAtEPA?.math ? true : false,
      coursesTakenAtEpaPhysics: meta?.currentlyAtEPA?.coursesTakenAtEPA?.physics ? true : false,
      coursesTakenAtEpaBiology: meta?.currentlyAtEPA?.coursesTakenAtEPA?.biology ? true : false,
      coursesTakenAtEpaGeography: meta?.currentlyAtEPA?.coursesTakenAtEPA?.geography ? true : false,
      coursesTakenAtEpaChemistry: meta?.currentlyAtEPA?.coursesTakenAtEPA?.chemistry ? true : false,
      coursesTakenAtEpaHistory: meta?.currentlyAtEPA?.coursesTakenAtEPA?.history ? true : false,
      coursesTakenAtEpaLiterature: meta?.currentlyAtEPA?.coursesTakenAtEPA?.literature ? true : false,
      coursesTakenAtEpaSocialStudies: meta?.currentlyAtEPA?.coursesTakenAtEPA?.social_studies ? true : false,
      coursesTakenAtEpaPolitics: meta?.currentlyAtEPA?.coursesTakenAtEPA?.politics ? true : false,
      coursesTakenAtEpaEconomics: meta?.currentlyAtEPA?.coursesTakenAtEPA?.economics ? true : false,
      coursesTakenAtEpaArt: meta?.currentlyAtEPA?.coursesTakenAtEPA?.art ? true : false,
      coursesTakenAtEpaForeignLanguage: meta?.currentlyAtEPA?.coursesTakenAtEPA?.foreign_language ? true : false,
      coursesTakenAtEpaOthers: meta?.currentlyAtEPA?.coursesTakenAtEPA?.others ? true : false,
      coursesTakenAtEpaOthersField: meta?.currentlyAtEPA?.coursesTakenAtEPA?.others_type ?? '',

      careerGoalsStudentPerspective: meta?.careerGoals?.student ?? '',
      careerGoalsParentPerspective: meta?.careerGoals?.parent ?? '',

      studyGoalsStudentPerspectiveEntranceExamBox: meta?.studyGoals?.student?.entrance ?? false,
      studyGoalsStudentPerspectiveEntranceExamField: meta?.studyGoals?.student?.entrance_type ?? '',
      studyGoalsStudentPerspectiveSchoolExamBox: meta?.studyGoals?.student?.school_exam ?? false,
      studyGoalsStudentPerspectiveSATBox: meta?.studyGoals?.student?.sat ?? false,
      studyGoalsStudentPerspectiveGREBox: meta?.studyGoals?.student?.gre ?? false,
      studyGoalsStudentPerspectiveIELTSBox: meta?.studyGoals?.student?.ielts ?? false,
      studyGoalsStudentPerspectiveTOEFLBox: meta?.studyGoals?.student?.toefl ?? false,
      studyGoalsStudentPerspectiveGMATBox: meta?.studyGoals?.student?.gmat ?? false,
      studyGoalsStudentPerspectiveOthersBox: meta?.studyGoals?.student?.others ?? false,
      studyGoalsStudentPerspectiveOthersField: meta?.studyGoals?.student?.others_type ?? '',

      studyGoalsParentPerspectiveEntranceExamBox: meta?.studyGoals?.parent?.entrance ?? false,
      studyGoalsParentPerspectiveEntranceExamField: meta?.studyGoals?.parent?.entrance_type ?? '',
      studyGoalsParentPerspectiveSchoolExamBox: meta?.studyGoals?.parent?.school_exam ?? false,
      studyGoalsParentPerspectiveSATBox: meta?.studyGoals?.parent?.sat ?? false,
      studyGoalsParentPerspectiveGREBox: meta?.studyGoals?.parent?.gre ?? false,
      studyGoalsParentPerspectiveIELTSBox: meta?.studyGoals?.parent?.ielts ?? false,
      studyGoalsParentPerspectiveTOEFLBox: meta?.studyGoals?.parent?.toefl ?? false,
      studyGoalsParentPerspectiveGMATBox: meta?.studyGoals?.parent?.gmat ?? false,
      studyGoalsParentPerspectiveOthersBox: meta?.studyGoals?.parent?.others ?? false,
      studyGoalsParentPerspectiveOthersField: meta?.studyGoals?.parent?.others_type ?? '',

      targetCountryStudentUS: meta?.targetCountry?.student?.us ?? false,
      targetCountryStudentCanada: meta?.targetCountry?.student?.canada ?? false,
      targetCountryStudentUK: meta?.targetCountry?.student?.uk ?? false,
      targetCountryStudentAustralia: meta?.targetCountry?.student?.aus ?? false,
      targetCountryStudentNewZealand: meta?.targetCountry?.student?.nz ?? false,
      targetCountryStudentOthersBox: meta?.targetCountry?.student?.others ?? false,
      targetCountryStudentOthersField: meta?.targetCountry?.student?.others_type ?? '',

      targetCountryParentUS: meta?.targetCountry?.parent?.us ?? false,
      targetCountryParentCanada: meta?.targetCountry?.parent?.canada ?? false,
      targetCountryParentUK: meta?.targetCountry?.parent?.uk ?? false,
      targetCountryParentAustralia: meta?.targetCountry?.parent?.aus ?? false,
      targetCountryParentNewZealand: meta?.targetCountry?.parent?.nz ?? false,
      targetCountryParentOthersBox: meta?.targetCountry?.parent?.others ?? false,
      targetCountryParentOthersField: meta?.targetCountry?.parent?.others_type ?? '',

      areaOfStudy: meta?.areaOfStudy?.area ?? '',
      areaOfStudySpecific: meta?.areaOfStudy?.specific ?? '',
      areaOfStudy1: meta?.areaOfStudy?.area1 ?? '',
      areaOfStudySpecific1: meta?.areaOfStudy?.specific1 ?? '',
      areaOfStudy2: meta?.areaOfStudy?.area2 ?? '',
      areaOfStudySpecific2: meta?.areaOfStudy?.specific2 ?? '',
      areaOfStudy3: meta?.areaOfStudy?.area3 ?? '',
      areaOfStudySpecific3: meta?.areaOfStudy?.specific3 ?? '',
      areaOfStudy4: meta?.areaOfStudy?.area4 ?? '',
      areaOfStudySpecific4: meta?.areaOfStudy?.specific4 ?? '',
      areaOfStudyOthers: meta?.areaOfStudy?.others ?? false,
      areaOfStudyOthersField: meta?.areaOfStudy?.others_type ?? '',
      areaOfStudyUnsure: meta?.areaOfStudy?.unsure ?? false,

      areaOfStudyParent: meta?.areaOfStudy?.parent?.area ?? '',
      areaOfStudyParentSpecific: meta?.areaOfStudy?.parent?.specific ?? '',
      areaOfStudyParent1: meta?.areaOfStudy?.parent?.area1 ?? '',
      areaOfStudyParentSpecific1: meta?.areaOfStudy?.parent?.specific1 ?? '',
      areaOfStudyParent2: meta?.areaOfStudy?.parent?.area2 ?? '',
      areaOfStudyParentSpecific2: meta?.areaOfStudy?.parent?.specific2 ?? '',
      areaOfStudyParent3: meta?.areaOfStudy?.parent?.area3 ?? '',
      areaOfStudyParentSpecific3: meta?.areaOfStudy?.parent?.specific3 ?? '',
      areaOfStudyParent4: meta?.areaOfStudy?.parent?.area4 ?? '',
      areaOfStudyParentSpecific4: meta?.areaOfStudy?.parent?.specific4 ?? '',
      areaOfStudyParentOthers: meta?.areaOfStudy?.parent?.others ?? false,
      areaOfStudyParentOthersField: meta?.areaOfStudy?.parent?.others_type ?? '',
      areaOfStudyParentUnsure: meta?.areaOfStudy?.parent?.unsure ?? false,

      specialRequests: meta?.specialRequests ?? '',

      learningTypeVisual: meta?.learningType?.visual ?? false,
      learningTypeKinaesthetic: meta?.learningType?.kinaesthetic ?? false,
      learningTypeAural: meta?.learningType?.aural ?? false,
      learningTypeSocial: meta?.learningType?.social ?? false,
      learningTypeSolitary: meta?.learningType?.solitary ?? false,
      learningTypeVerbal: meta?.learningType?.verbal ?? false,
      learningTypeLogical: meta?.learningType?.logical ?? false,
      learningTypeUnsure: meta?.learningType?.unsure ?? false,
      learningTypeOthersBox: meta?.learningType?.others ?? false,
      learningTypeOthersField: meta?.learningType?.others_type ?? '',

      personalityTestCheck: meta?.personalityTest?.attempted ?? 'nil',
      personalityTestResult: meta?.personalityTest?.result ?? '',
      personalityTestResultField: meta?.personalityTest?.result_field ?? '',

      scDominanceDirect: meta?.studentCharacter?.dominance?.direct ?? false,
      scDominanceResultsOriented: meta?.studentCharacter?.dominance?.result_oriented ?? false,
      scDominanceFirm: meta?.studentCharacter?.dominance?.firm ?? false,
      scDominanceStrongWilled: meta?.studentCharacter?.dominance?.strong_willed ?? false,
      scDominanceForceful: meta?.studentCharacter?.dominance?.forceful ?? false,
      scInfluenceOutgoing: meta?.studentCharacter?.influence?.outgoing ?? false,
      scInfluenceEnthusiastic: meta?.studentCharacter?.influence?.enthusiastic ?? false,
      scInfluenceOptimistic: meta?.studentCharacter?.influence?.optimistic ?? false,
      scInfluenceHighSpirited: meta?.studentCharacter?.influence?.high_spirited ?? false,
      scInfluenceLively: meta?.studentCharacter?.influence?.lively ?? false,
      scConscientiousnessAnalytical: meta?.studentCharacter?.conscientiousness?.analytical ?? false,
      scConscientiousnessReserved: meta?.studentCharacter?.conscientiousness?.reserved ?? false,
      scConscientiousnessPrecise: meta?.studentCharacter?.conscientiousness?.precise ?? false,
      scConscientiousnessPrivate: meta?.studentCharacter?.conscientiousness?.private ?? false,
      scConscientiousnessSystematic: meta?.studentCharacter?.conscientiousness?.systematic ?? false,
      scSteadinessEvenTempered: meta?.studentCharacter?.steadiness?.even_tempered ?? false,
      scSteadinessAccommodating: meta?.studentCharacter?.steadiness?.accommodating ?? false,
      scSteadinessPatient: meta?.studentCharacter?.steadiness?.patient ?? false,
      scSteadinessHumble: meta?.studentCharacter?.steadiness?.humble ?? false,
      scSteadinessTactful: meta?.studentCharacter?.steadiness?.tactful ?? false,

      healthConditionNA: meta?.healthCondition?.na ?? false,
      healthConditionADHD: meta?.healthCondition?.adhd ?? false,
      healthConditionAutism: meta?.healthCondition?.autism ?? false,
      healthConditionOthersBox: meta?.healthCondition?.others ?? false,
      healthConditionOthersField: meta?.healthCondition?.others_type ?? '',

      preferredTeacherStudentPerspectiveMale: meta?.preferredTeacher?.student?.male ?? false,
      preferredTeacherStudentPerspectiveFemale: meta?.preferredTeacher?.student?.female ?? false,
      preferredTeacherStudentPerspectiveNameBox: meta?.preferredTeacher?.student?.name ?? false,
      preferredTeacherStudentPerspectiveNameField: meta?.preferredTeacher?.student?.name_type ?? '',
      preferredTeacherStudentPerspectiveAuthority: meta?.preferredTeacher?.student?.authority ?? false,
      preferredTeacherStudentPerspectiveDelegator: meta?.preferredTeacher?.student?.delegator ?? false,
      preferredTeacherStudentPerspectiveFacilitator: meta?.preferredTeacher?.student?.facilitator ?? false,
      preferredTeacherStudentPerspectiveDemonstrator: meta?.preferredTeacher?.student?.demonstrator ?? false,
      preferredTeacherStudentPerspectiveHybrid: meta?.preferredTeacher?.student?.hybrid ?? false,
      preferredTeacherStudentPerspectiveOthersBox: meta?.preferredTeacher?.student?.others ?? false,
      preferredTeacherStudentPerspectiveOthersField: meta?.preferredTeacher?.student?.others_type ?? '',

      preferredTeacherParentPerspectiveMale: meta?.preferredTeacher?.parent?.male ?? false,
      preferredTeacherParentPerspectiveFemale: meta?.preferredTeacher?.parent?.female ?? false,
      preferredTeacherParentPerspectiveNameBox: meta?.preferredTeacher?.parent?.name ?? false,
      preferredTeacherParentPerspectiveNameField: meta?.preferredTeacher?.parent?.name_type ?? '',
      preferredTeacherParentPerspectiveAuthority: meta?.preferredTeacher?.parent?.authority ?? false,
      preferredTeacherParentPerspectiveDelegator: meta?.preferredTeacher?.parent?.delegator ?? false,
      preferredTeacherParentPerspectiveFacilitator: meta?.preferredTeacher?.parent?.facilitator ?? false,
      preferredTeacherParentPerspectiveDemonstrator: meta?.preferredTeacher?.parent?.demonstrator ?? false,
      preferredTeacherParentPerspectiveHybrid: meta?.preferredTeacher?.parent?.hybrid ?? false,
      preferredTeacherParentPerspectiveOthersBox: meta?.preferredTeacher?.parent?.others ?? false,
      preferredTeacherParentPerspectiveOthersField: meta?.preferredTeacher?.parent?.others_type ?? '',

      interestedTopicsPublicSpeaking: meta?.interestedTopics?.public_speaking ?? false,
      interestedTopicsDebate: meta?.interestedTopics?.debate ?? false,
      interestedTopicsCoding: meta?.interestedTopics?.coding ?? false,
      interestedTopicsLeadership: meta?.interestedTopics?.leadership ?? false,
      interestedTopicsWriting: meta?.interestedTopics?.writing ?? false,
      interestedTopicsCreativeWriting: meta?.interestedTopics?.creative_writing ?? false,
      interestedTopicsFormalWriting: meta?.interestedTopics?.formal_writing ?? false,
      interestedTopicsReadingComprehension: meta?.interestedTopics?.reading_comprehension ?? false,
      interestedTopicsLiterature: meta?.interestedTopics?.literature ?? false,
      interestedTopicsOthersBox: meta?.interestedTopics?.others ?? false,
      interestedTopicsOthersField: meta?.interestedTopics?.others_type ?? '',

      parentSalute: meta?.parent?.salute ?? 'Mr',
      parentName: meta?.parent?.name ?? '',
      parentEmail: meta?.parent?.email ?? '',
      parentGender: meta?.parent?.gender ?? 'nil',
      parentDOB: meta?.parent?.dob ? moment(meta?.parent?.dob).startOf('day').format('YYYY-MM-DD') : '',
      parentPhone: meta?.parent?.phone ?? '',
      parentContactPreference: meta?.parent?.contact_preference?.contact ?? '',
      parentContactPreferenceOther: meta?.parent?.contact_preference?.others_type ?? '',
      parentFacebook: meta?.parent?.facebook ?? '',
      parentLine: meta?.parent?.line ?? '',

      parentOccupation: meta?.parent?.meta?.occupation?.area ?? '',
      parentOccupationSpecific: meta?.parent?.meta?.occupation?.specific ?? '',
      parentFamilyBackground: meta?.parent?.meta?.family_background ?? '',
      parentNetworkingFB: meta?.parent?.meta?.networking_circle?.fb ?? false,
      parentNetworkingWebsite: meta?.parent?.meta?.networking_circle?.website ?? false,
      parentNetworkingFriends: meta?.parent?.meta?.networking_circle?.friends ?? false,
      parentNetworkingOthersBox: meta?.parent?.meta?.networking_circle?.others ?? false,
      parentNetworkingOthersField: meta?.parent?.meta?.networking_circle?.others_type ?? '',

      pcDominanceDirect: meta?.parent?.meta?.parent_character?.dominance?.direct ?? false,
      pcDominanceResultsOriented: meta?.parent?.meta?.parent_character?.dominance?.result_oriented ?? false,
      pcDominanceFirm: meta?.parent?.meta?.parent_character?.dominance?.firm ?? false,
      pcDominanceStrongWilled: meta?.parent?.meta?.parent_character?.dominance?.strong_willed ?? false,
      pcDominanceForceful: meta?.parent?.meta?.parent_character?.dominance?.forceful ?? false,
      pcInfluenceOutgoing: meta?.parent?.meta?.parent_character?.influence?.outgoing ?? false,
      pcInfluenceEnthusiastic: meta?.parent?.meta?.parent_character?.influence?.enthusiastic ?? false,
      pcInfluenceOptimistic: meta?.parent?.meta?.parent_character?.influence?.optimistic ?? false,
      pcInfluenceHighSpirited: meta?.parent?.meta?.parent_character?.influence?.high_spirited ?? false,
      pcInfluenceLively: meta?.parent?.meta?.parent_character?.influence?.lively ?? false,
      pcConscientiousnessAnalytical: meta?.parent?.meta?.parent_character?.conscientiousness?.analytical ?? false,
      pcConscientiousnessReserved: meta?.parent?.meta?.parent_character?.conscientiousness?.reserved ?? false,
      pcConscientiousnessPrecise: meta?.parent?.meta?.parent_character?.conscientiousness?.precise ?? false,
      pcConscientiousnessPrivate: meta?.parent?.meta?.parent_character?.conscientiousness?.private ?? false,
      pcConscientiousnessSystematic: meta?.parent?.meta?.parent_character?.conscientiousness?.systematic ?? false,
      pcSteadinessEvenTempered: meta?.parent?.meta?.parent_character?.steadiness?.even_tempered ?? false,
      pcSteadinessAccommodating: meta?.parent?.meta?.parent_character?.steadiness?.accommodating ?? false,
      pcSteadinessPatient: meta?.parent?.meta?.parent_character?.steadiness?.patient ?? false,
      pcSteadinessHumble: meta?.parent?.meta?.parent_character?.steadiness?.humble ?? false,
      pcSteadinessTactful: meta?.parent?.meta?.parent_character?.steadiness?.tactful ?? false,
      parentConflict: meta?.parent?.meta?.conflict ?? '',
      parentDecisionFather: meta?.parent?.meta?.decision_maker?.father ?? false,
      parentDecisionMother: meta?.parent?.meta?.decision_maker?.mother ?? false,

      paymentMode: meta?.parent?.meta?.payment_mode ?? 'nil',
      parentInterestedTopicsPublicSpeaking: meta?.parent?.meta?.interested_topics?.public_speaking ?? false,
      parentInterestedTopicsDebate: meta?.parent?.meta?.interested_topics?.debate ?? false,
      parentInterestedTopicsCoding: meta?.parent?.meta?.interested_topics?.coding ?? false,
      parentInterestedTopicsLeadership: meta?.parent?.meta?.interested_topics?.leadership ?? false,
      parentInterestedTopicsWriting: meta?.parent?.meta?.interested_topics?.writing ?? false,
      parentInterestedTopicsCreativeWriting: meta?.parent?.meta?.interested_topics?.creative_writing ?? false,
      parentInterestedTopicsFormalWriting: meta?.parent?.meta?.interested_topics?.formal_writing ?? false,
      parentInterestedTopicsReadingComprehension: meta?.parent?.meta?.interested_topics?.reading_comprehension ?? false,
      parentInterestedTopicsLiterature: meta?.parent?.meta?.interested_topics?.literature ?? false,
      parentInterestedTopicsOthersBox: meta?.parent?.meta?.interested_topics?.others ?? false,
      parentInterestedTopicsOthersField: meta?.parent?.meta?.interested_topics?.others_type ?? '',
      parentDateOfRenewal: meta?.parent?.meta.date_of_renewal
        ? moment(meta?.parent?.meta.date_of_renewal).startOf('day').format('YYYY-MM-DD')
        : ''
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.phoneCountryCode !== this.state.phoneCountryCode || prevState.phone !== this.state.phone) {
      if (this.state.useStudentPhone) {
        this.setState({
          parentPhoneCountryCode: this.state.phoneCountryCode,
          parentPhone: this.state.phone
        })
      }
    }

    // if (prevProps.updateUserInProgress && !this.props.updateUserInProgress) {
    //   this.props.history.push(USER_PROFILE)
    // }
  }

  validateFormElement = (type, customMsg) => {
    let errorMsg

    if (type === 'password') {
      errorMsg = validatePassword(this.state.password)
    } else {
      if (customMsg) {
        errorMsg = validateTextEntry(this.state[type], type, customMsg)
      } else {
        errorMsg = validateTextEntry(this.state[type], type)
      }
    }

    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError[type] = errorMsg
    } else {
      delete tempFormError[type]
    }
    this.setState({ formError: tempFormError })
  }

  onFocusFormElement = e => {
    let type = e.target.name
    let tempFormError = this.state.formError
    delete tempFormError[type]
    this.setState({ formError: tempFormError })
  }

  handleChange = e => {
    let type = e.target.name
    let value = e.target.value
    this.setState({ [type]: value })

    if (type === 'level') {
      let tempFormError = this.state.formError
      delete tempFormError[type]
      this.setState({ formError: tempFormError })
      if (value === 'others') {
        this.setState({ showOthersLevel: true })
      } else {
        this.setState({ showOthersLevel: false })
      }
    }

    if (type === 'typeOfSchool') {
      if (value === 'others') {
        this.setState({ showOthersTypeOfSchool: true })
      } else {
        this.setState({ showOthersTypeOfSchool: false })
      }
    }
  }

  handleClose = (event, reason) => {
    const { isSaved } = this.state
    if (reason === 'clickaway') {
      return
    }

    this.setState({ isSaved: !isSaved })
  }

  handleBlurInput = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAutoCompleteChange = (e, value) => {
    this.setState({ school: value.name })

    if (value.code === 'others') {
      this.setState({ showOthersSchool: true })
    } else {
      this.setState({ showOthersSchool: false })
    }
  }

  handleAutoCompleteStudyAreaChange = (e, value) => {
    this.setState({ areaOfStudy: value })
  }

  handleAutoCompleteStudyAreaSpecificChange = (e, value) => {
    this.setState({ areaOfStudySpecific: value })
  }

  handleAutoCompleteStudyAreaChange1 = (e, value) => {
    this.setState({ areaOfStudy1: value })
  }

  handleAutoCompleteStudyAreaSpecificChange1 = (e, value) => {
    this.setState({ areaOfStudySpecific1: value })
  }

  handleAutoCompleteStudyAreaChange2 = (e, value) => {
    this.setState({ areaOfStudy2: value })
  }

  handleAutoCompleteStudyAreaSpecificChange2 = (e, value) => {
    this.setState({ areaOfStudySpecific2: value })
  }

  handleAutoCompleteStudyAreaChange3 = (e, value) => {
    this.setState({ areaOfStudy3: value })
  }

  handleAutoCompleteStudyAreaSpecificChange3 = (e, value) => {
    this.setState({ areaOfStudySpecific3: value })
  }

  handleAutoCompleteStudyAreaChange4 = (e, value) => {
    this.setState({ areaOfStudy4: value })
  }

  handleAutoCompleteStudyAreaSpecificChange4 = (e, value) => {
    this.setState({ areaOfStudySpecific4: value })
  }

  handleAutoCompleteParentStudyAreaChange = (e, value) => {
    this.setState({ areaOfStudyParent: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange = (e, value) => {
    this.setState({ areaOfStudyParentSpecific: value })
  }

  handleAutoCompleteParentStudyAreaChange1 = (e, value) => {
    this.setState({ areaOfStudyParent1: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange1 = (e, value) => {
    this.setState({ areaOfStudyParentSpecific1: value })
  }

  handleAutoCompleteParentStudyAreaChange2 = (e, value) => {
    this.setState({ areaOfStudyParent2: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange2 = (e, value) => {
    this.setState({ areaOfStudyParentSpecific2: value })
  }

  handleAutoCompleteParentStudyAreaChange3 = (e, value) => {
    this.setState({ areaOfStudyParent3: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange3 = (e, value) => {
    this.setState({ areaOfStudyParentSpecific3: value })
  }

  handleAutoCompleteParentStudyAreaChange4 = (e, value) => {
    this.setState({ areaOfStudyParent4: value })
  }

  handleAutoCompleteParentStudyAreaSpecificChange4 = (e, value) => {
    this.setState({ areaOfStudyParentSpecific4: value })
  }

  handleCheckboxChange = (e, checked) => {
    this.setState({ [e.target.name]: checked })
  }

  submitChanges = () => {
    this.validateFormElement('name')
    this.validateFormElement('phone')
    if (this.state.level === 'others') {
      this.validateFormElement('othersLevel', 'your level')
    } else {
      this.validateFormElement('level')
    }
    this.validateFormElement('school')
    if (_.isEmpty(this.state.formError)) {
      const {
        name,
        email,
        dateOfBirth,
        gender,
        phoneCountryCode,
        phone,
        level,
        othersLevel,
        school,
        othersSchool,
        typeOfSchool,
        othersTypeOfSchool,

        contactPreference,
        contactPreferenceOther,
        facebook,
        line,
        isSaved,

        studyingAtOtherSchool,
        studyingAtOtherSchoolName,
        studyMediumEng,
        studyMediumThai,
        gradeLevel,
        yearLevel,

        gpaEngBox,
        gpaThaiBox,
        gpaMathsBox,
        gpaPhysBox,
        gpaChemBox,
        gpaBioBox,
        gpaGeoBox,
        gpaHistBox,
        gpaLitBox,
        gpaSSBox,
        gpaPolBox,
        gpaEcoBox,
        gpaArtBox,
        gpaFLBox,
        gpaOthersBox,

        gpaEng,
        gpaThai,
        gpaMaths,
        gpaPhys,
        gpaChem,
        gpaBio,
        gpaGeo,
        gpaHist,
        gpaLit,
        gpaSS,
        gpaPol,
        gpaEco,
        gpaArt,
        gpaFL,
        gpaOthersField,

        favSubjEngBox,
        favSubjThaiBox,
        favSubjMathsBox,
        favSubjPhysBox,
        favSubjChemBox,
        favSubjBioBox,
        favSubjGeoBox, //geography
        favSubjHistBox,
        favSubjLitBox, //literature
        favSubjSSBox, //social studies
        favSubjPolBox, //politics
        favSubjEcoBox, //economics
        favSubjArtBox,
        favSubjFLBox, //foreign language,
        favSubjOthersBox,
        favSubjOthersBoxField,

        weakestSubjEngBox,
        weakestSubjThaiBox,
        weakestSubjMathsBox,
        weakestSubjPhysBox,
        weakestSubjChemBox,
        weakestSubjBioBox,
        weakestSubjGeoBox, //geography
        weakestSubjHistBox,
        weakestSubjLitBox, //literature
        weakestSubjSSBox, //social studies
        weakestSubjPolBox, //politics
        weakestSubjEcoBox, //economics
        weakestSubjArtBox,
        weakestSubjFLBox, //foreign ,
        weakestSubjOthersBox,
        weakestSubjOthersBoxField,

        extExamSAT,
        extExamGRE,
        extExamIELTS,
        extExamTOEFL,
        extExamGMAT,
        extExamOthers,
        extExamOthersField,

        otherTCcourseSAT,
        otherTCcourseGRE,
        otherTCcourseIELTS,
        otherTCcourseTOEFL,
        otherTCcourseGMAT,
        otherTCcourseEnglish,
        otherTCcourseThai,
        otherTCcourseMath,
        otherTCcoursePhysics,
        otherTCcourseBiology,
        otherTCcourseChemistry,
        otherTCcourseGeography,
        otherTCcourseHistory,
        otherTCcourseLiterature,
        otherTCcourseSocialStudies,
        otherTCcoursePolitics,
        otherTCcourseEconomics,
        otherTCcourseArt,
        otherTCcourseForeignLanguage,
        otherTCcourseOthers,
        otherTCcourseOthersField,

        studyingAtEPA,
        coursesTakenAtEpaSAT,
        coursesTakenAtEpaGRE,
        coursesTakenAtEpaIELTS,
        coursesTakenAtEpaTOEFL,
        coursesTakenAtEpaGMAT,
        coursesTakenAtEpaEnglish,
        coursesTakenAtEpaThai,
        coursesTakenAtEpaMath,
        coursesTakenAtEpaPhysics,
        coursesTakenAtEpaBiology,
        coursesTakenAtEpaGeography,
        coursesTakenAtEpaChemistry,
        coursesTakenAtEpaHistory,
        coursesTakenAtEpaLiterature,
        coursesTakenAtEpaSocialStudies,
        coursesTakenAtEpaPolitics,
        coursesTakenAtEpaEconomics,
        coursesTakenAtEpaArt,
        coursesTakenAtEpaForeignLanguage,
        coursesTakenAtEpaOthers,
        coursesTakenAtEpaOthersField,

        careerGoalsStudentPerspective,
        careerGoalsParentPerspective,

        studyGoalsStudentPerspectiveEntranceExamBox,
        studyGoalsStudentPerspectiveEntranceExamField,
        studyGoalsStudentPerspectiveSchoolExamBox,
        studyGoalsStudentPerspectiveSATBox,
        studyGoalsStudentPerspectiveGREBox,
        studyGoalsStudentPerspectiveIELTSBox,
        studyGoalsStudentPerspectiveTOEFLBox,
        studyGoalsStudentPerspectiveGMATBox,
        studyGoalsStudentPerspectiveOthersBox,
        studyGoalsStudentPerspectiveOthersField,

        studyGoalsParentPerspectiveEntranceExamBox,
        studyGoalsParentPerspectiveEntranceExamField,
        studyGoalsParentPerspectiveSchoolExamBox,
        studyGoalsParentPerspectiveSATBox,
        studyGoalsParentPerspectiveGREBox,
        studyGoalsParentPerspectiveIELTSBox,
        studyGoalsParentPerspectiveTOEFLBox,
        studyGoalsParentPerspectiveGMATBox,
        studyGoalsParentPerspectiveOthersBox,
        studyGoalsParentPerspectiveOthersField,

        targetCountryStudentUS,
        targetCountryStudentCanada,
        targetCountryStudentUK,
        targetCountryStudentAustralia,
        targetCountryStudentNewZealand,
        targetCountryStudentOthersBox,
        targetCountryStudentOthersField,

        targetCountryParentUS,
        targetCountryParentCanada,
        targetCountryParentUK,
        targetCountryParentAustralia,
        targetCountryParentNewZealand,
        targetCountryParentOthersBox,
        targetCountryParentOthersField,

        areaOfStudy,
        areaOfStudySpecific,
        areaOfStudy1,
        areaOfStudySpecific1,
        areaOfStudy2,
        areaOfStudySpecific2,
        areaOfStudy3,
        areaOfStudySpecific3,
        areaOfStudy4,
        areaOfStudySpecific4,
        areaOfStudyOthers,
        areaOfStudyOthersField,
        areaOfStudyUnsure,

        areaOfStudyParent,
        areaOfStudyParentSpecific,
        areaOfStudyParent1,
        areaOfStudyParentSpecific1,
        areaOfStudyParent2,
        areaOfStudyParentSpecific2,
        areaOfStudyParent3,
        areaOfStudyParentSpecific3,
        areaOfStudyParent4,
        areaOfStudyParentSpecific4,
        areaOfStudyParentOthers,
        areaOfStudyParentOthersField,
        areaOfStudyParentUnsure,

        specialRequests,

        learningTypeVisual,
        learningTypeKinaesthetic,
        learningTypeAural,
        learningTypeSocial,
        learningTypeSolitary,
        learningTypeVerbal,
        learningTypeLogical,
        learningTypeUnsure,
        learningTypeOthersBox,
        learningTypeOthersField,

        personalityTestCheck,
        personalityTestResult,
        personalityTestResultField,

        scDominanceDirect,
        scDominanceResultsOriented,
        scDominanceFirm,
        scDominanceStrongWilled,
        scDominanceForceful,
        scInfluenceOutgoing,
        scInfluenceEnthusiastic,
        scInfluenceOptimistic,
        scInfluenceHighSpirited,
        scInfluenceLively,
        scConscientiousnessAnalytical,
        scConscientiousnessReserved,
        scConscientiousnessPrecise,
        scConscientiousnessPrivate,
        scConscientiousnessSystematic,
        scSteadinessEvenTempered,
        scSteadinessAccommodating,
        scSteadinessPatient,
        scSteadinessHumble,
        scSteadinessTactful,

        healthConditionNA,
        healthConditionADHD,
        healthConditionAutism,
        healthConditionOthersBox,
        healthConditionOthersField,

        preferredTeacherStudentPerspectiveMale,
        preferredTeacherStudentPerspectiveFemale,
        preferredTeacherStudentPerspectiveNameBox,
        preferredTeacherStudentPerspectiveNameField,
        preferredTeacherStudentPerspectiveAuthority,
        preferredTeacherStudentPerspectiveDelegator,
        preferredTeacherStudentPerspectiveFacilitator,
        preferredTeacherStudentPerspectiveDemonstrator,
        preferredTeacherStudentPerspectiveHybrid,
        preferredTeacherStudentPerspectiveOthersBox,
        preferredTeacherStudentPerspectiveOthersField,

        preferredTeacherParentPerspectiveMale,
        preferredTeacherParentPerspectiveFemale,
        preferredTeacherParentPerspectiveNameBox,
        preferredTeacherParentPerspectiveNameField,
        preferredTeacherParentPerspectiveAuthority,
        preferredTeacherParentPerspectiveDelegator,
        preferredTeacherParentPerspectiveFacilitator,
        preferredTeacherParentPerspectiveDemonstrator,
        preferredTeacherParentPerspectiveHybrid,
        preferredTeacherParentPerspectiveOthersBox,
        preferredTeacherParentPerspectiveOthersField,

        interestedTopicsPublicSpeaking,
        interestedTopicsDebate,
        interestedTopicsCoding,
        interestedTopicsLeadership,
        interestedTopicsWriting,
        interestedTopicsCreativeWriting,
        interestedTopicsFormalWriting,
        interestedTopicsReadingComprehension,
        interestedTopicsLiterature,
        interestedTopicsOthersBox,
        interestedTopicsOthersField,

        parentSalute,
        parentName,
        parentEmail,
        parentGender,
        parentDOB,
        parentPhoneCountryCode,
        parentPhone,
        parentContactPreference,
        parentContactPreferenceOther,
        parentFacebook,
        parentLine,

        parentOccupation,
        parentOccupationSpecific,
        parentFamilyBackground,
        parentNetworkingFB,
        parentNetworkingWebsite,
        parentNetworkingFriends,
        parentNetworkingOthersBox,
        parentNetworkingOthersField,

        pcDominanceDirect,
        pcDominanceResultsOriented,
        pcDominanceFirm,
        pcDominanceStrongWilled,
        pcDominanceForceful,
        pcInfluenceOutgoing,
        pcInfluenceEnthusiastic,
        pcInfluenceOptimistic,
        pcInfluenceHighSpirited,
        pcInfluenceLively,
        pcConscientiousnessAnalytical,
        pcConscientiousnessReserved,
        pcConscientiousnessPrecise,
        pcConscientiousnessPrivate,
        pcConscientiousnessSystematic,
        pcSteadinessEvenTempered,
        pcSteadinessAccommodating,
        pcSteadinessPatient,
        pcSteadinessHumble,
        pcSteadinessTactful,
        parentConflict,
        parentDecisionFather,
        parentDecisionMother,

        paymentMode,
        parentInterestedTopicsPublicSpeaking,
        parentInterestedTopicsDebate,
        parentInterestedTopicsCoding,
        parentInterestedTopicsLeadership,
        parentInterestedTopicsWriting,
        parentInterestedTopicsCreativeWriting,
        parentInterestedTopicsFormalWriting,
        parentInterestedTopicsReadingComprehension,
        parentInterestedTopicsLiterature,
        parentInterestedTopicsOthersBox,
        parentInterestedTopicsOthersField,
        parentDateOfRenewal
      } = this.state

      this.setState({ isSaved: !isSaved })

      let details = {
        name,
        email,
        phone,
        gender,
        date_of_birth: moment(dateOfBirth).startOf('day').toISOString(),
        phone_country_code: phoneCountryCode,
        level: level === 'others' ? othersLevel : level,
        school: school === 'others' ? othersSchool : school === 'Others' ? othersSchool : school,
        typeOfSchool: typeOfSchool === 'others' ? othersTypeOfSchool : typeOfSchool,

        contact_preference: {
          contact: contactPreference,
          others_type: contactPreferenceOther
        },
        facebook: facebook,
        line: line,

        gradeYearLevel: {
          grade: gradeLevel,
          year: yearLevel
        },

        grade: {
          english: gpaEngBox,
          thai: gpaThaiBox,
          math: gpaMathsBox,
          physics: gpaPhysBox,
          chemistry: gpaChemBox,
          biology: gpaBioBox,
          geography: gpaGeoBox,
          history: gpaHistBox,
          literature: gpaLitBox,
          social_studies: gpaSSBox,
          politics: gpaPolBox,
          economics: gpaEcoBox,
          art: gpaArtBox,
          foreign_language: gpaFLBox,
          others: gpaOthersBox,

          gpa: {
            english: gpaEng,
            thai: gpaThai,
            math: gpaMaths,
            physics: gpaPhys,
            chemistry: gpaChem,
            biology: gpaBio,
            geography: gpaGeo,
            history: gpaHist,
            literature: gpaLit,
            social_studies: gpaSS,
            politics: gpaPol,
            economics: gpaEco,
            art: gpaArt,
            foreign_language: gpaFL,
            others_type: gpaOthersField
          }
        },

        favoriteSubjects: {
          english: favSubjEngBox,
          thai: favSubjThaiBox,
          math: favSubjMathsBox,
          physics: favSubjPhysBox,
          chemistry: favSubjChemBox,
          biology: favSubjBioBox,
          geography: favSubjGeoBox,
          history: favSubjHistBox,
          literature: favSubjLitBox,
          social_studies: favSubjSSBox,
          politics: favSubjPolBox,
          economics: favSubjEcoBox,
          art: favSubjArtBox,
          foreign_language: favSubjFLBox,
          others: favSubjOthersBox,
          others_type: favSubjOthersBoxField
        },

        weakestSubjects: {
          english: weakestSubjEngBox,
          thai: weakestSubjThaiBox,
          math: weakestSubjMathsBox,
          physics: weakestSubjPhysBox,
          chemistry: weakestSubjChemBox,
          biology: weakestSubjBioBox,
          geography: weakestSubjGeoBox,
          history: weakestSubjHistBox,
          literature: weakestSubjLitBox,
          social_studies: weakestSubjSSBox,
          politics: weakestSubjPolBox,
          economics: weakestSubjEcoBox,
          art: weakestSubjArtBox,
          foreign_language: weakestSubjFLBox,
          others: weakestSubjOthersBox,
          others_type: weakestSubjOthersBoxField
        },

        externalExams: {
          gmat: extExamGMAT,
          gre: extExamGRE,
          ielts: extExamIELTS,
          sat: extExamSAT,
          toefl: extExamTOEFL,
          others: extExamOthers,
          others_type: extExamOthersField
        },

        coursesTuition: {
          gmat: otherTCcourseGMAT,
          gre: otherTCcourseGRE,
          ielts: otherTCcourseIELTS,
          sat: otherTCcourseSAT,
          toefl: otherTCcourseTOEFL,
          english: otherTCcourseEnglish,
          thai: otherTCcourseThai,
          math: otherTCcourseMath,
          physics: otherTCcoursePhysics,
          biology: otherTCcourseBiology,
          chemistry: otherTCcourseChemistry,
          geography: otherTCcourseGeography,
          history: otherTCcourseHistory,
          literature: otherTCcourseLiterature,
          social_studies: otherTCcourseSocialStudies,
          politics: otherTCcoursePolitics,
          economics: otherTCcourseEconomics,
          art: otherTCcourseArt,
          foreign_language: otherTCcourseForeignLanguage,
          others: otherTCcourseOthers,
          others_type: otherTCcourseOthersField
        },

        currentlyAtEPA: {
          studyingAtEPA: studyingAtEPA,
          coursesTakenAtEPA: {
            sat: coursesTakenAtEpaSAT,
            gre: coursesTakenAtEpaGRE,
            ielts: coursesTakenAtEpaIELTS,
            toefl: coursesTakenAtEpaTOEFL,
            gmat: coursesTakenAtEpaGMAT,
            english: coursesTakenAtEpaEnglish,
            thai: coursesTakenAtEpaThai,
            math: coursesTakenAtEpaMath,
            physics: coursesTakenAtEpaPhysics,
            biology: coursesTakenAtEpaBiology,
            geography: coursesTakenAtEpaGeography,
            chemistry: coursesTakenAtEpaChemistry,
            history: coursesTakenAtEpaHistory,
            literature: coursesTakenAtEpaLiterature,
            social_studies: coursesTakenAtEpaSocialStudies,
            politics: coursesTakenAtEpaPolitics,
            economics: coursesTakenAtEpaEconomics,
            art: coursesTakenAtEpaArt,
            foreign_language: coursesTakenAtEpaForeignLanguage,
            others: coursesTakenAtEpaOthers,
            others_type: coursesTakenAtEpaOthersField
          }
        },

        studyingAtOtherSchool: {
          at_other_school: studyingAtOtherSchool,
          at_other_school_name: studyingAtOtherSchoolName
        },

        studyMediumEng: studyMediumEng,
        studyMediumThai: studyMediumThai,

        careerGoals: {
          student: careerGoalsStudentPerspective,
          parent: careerGoalsParentPerspective
        },

        studyGoals: {
          student: {
            entrance: studyGoalsStudentPerspectiveEntranceExamBox,
            entrance_type: studyGoalsStudentPerspectiveEntranceExamField,
            school_exam: studyGoalsStudentPerspectiveSchoolExamBox,
            sat: studyGoalsStudentPerspectiveSATBox,
            gre: studyGoalsStudentPerspectiveGREBox,
            ielts: studyGoalsStudentPerspectiveIELTSBox,
            toefl: studyGoalsStudentPerspectiveTOEFLBox,
            gmat: studyGoalsStudentPerspectiveGMATBox,
            others: studyGoalsStudentPerspectiveOthersBox,
            others_type: studyGoalsStudentPerspectiveOthersField
          },
          parent: {
            entrance: studyGoalsParentPerspectiveEntranceExamBox,
            entrance_type: studyGoalsParentPerspectiveEntranceExamField,
            school_exam: studyGoalsParentPerspectiveSchoolExamBox,
            sat: studyGoalsParentPerspectiveSATBox,
            gre: studyGoalsParentPerspectiveGREBox,
            ielts: studyGoalsParentPerspectiveIELTSBox,
            toefl: studyGoalsParentPerspectiveTOEFLBox,
            gmat: studyGoalsParentPerspectiveGMATBox,
            others: studyGoalsParentPerspectiveOthersBox,
            others_type: studyGoalsParentPerspectiveOthersField
          }
        },

        targetCountry: {
          student: {
            us: targetCountryStudentUS,
            canada: targetCountryStudentCanada,
            uk: targetCountryStudentUK,
            aus: targetCountryStudentAustralia,
            nz: targetCountryStudentNewZealand,
            others: targetCountryStudentOthersBox,
            others_type: targetCountryStudentOthersField
          },
          parent: {
            us: targetCountryParentUS,
            canada: targetCountryParentCanada,
            uk: targetCountryParentUK,
            aus: targetCountryParentAustralia,
            nz: targetCountryParentNewZealand,
            others: targetCountryParentOthersBox,
            others_type: targetCountryParentOthersField
          }
        },

        areaOfStudy: {
          area: areaOfStudy,
          specific: areaOfStudySpecific,
          area1: areaOfStudy1,
          specific1: areaOfStudySpecific1,
          area2: areaOfStudy2,
          specific2: areaOfStudySpecific2,
          area3: areaOfStudy3,
          specific3: areaOfStudySpecific3,
          area4: areaOfStudy4,
          specific4: areaOfStudySpecific4,
          others: areaOfStudyOthers,
          others_type: areaOfStudyOthersField,
          unsure: areaOfStudyUnsure,
          parent: {
            area: areaOfStudyParent,
            specific: areaOfStudyParentSpecific,
            area1: areaOfStudyParent1,
            specific1: areaOfStudyParentSpecific1,
            area2: areaOfStudyParent2,
            specific2: areaOfStudyParentSpecific2,
            area3: areaOfStudyParent3,
            specific3: areaOfStudyParentSpecific3,
            area4: areaOfStudyParent4,
            specific4: areaOfStudyParentSpecific4,
            others: areaOfStudyParentOthers,
            others_type: areaOfStudyParentOthersField,
            unsure: areaOfStudyParentUnsure
          }
        },

        specialRequests: specialRequests,

        learningType: {
          visual: learningTypeVisual,
          kinaesthetic: learningTypeKinaesthetic,
          aural: learningTypeAural,
          social: learningTypeSocial,
          solitary: learningTypeSolitary,
          verbal: learningTypeVerbal,
          logical: learningTypeLogical,
          unsure: learningTypeUnsure,
          others: learningTypeOthersBox,
          others_type: learningTypeOthersField
        },

        studentCharacter: {
          dominance: {
            direct: scDominanceDirect,
            result_oriented: scDominanceResultsOriented,
            firm: scDominanceFirm,
            strong_willed: scDominanceStrongWilled,
            forceful: scDominanceForceful
          },
          influence: {
            outgoing: scInfluenceOutgoing,
            enthusiastic: scInfluenceEnthusiastic,
            optimistic: scInfluenceOptimistic,
            high_spirited: scInfluenceHighSpirited,
            lively: scInfluenceLively
          },
          conscientiousness: {
            analytical: scConscientiousnessAnalytical,
            reserved: scConscientiousnessReserved,
            precise: scConscientiousnessPrecise,
            private: scConscientiousnessPrivate,
            systematic: scConscientiousnessSystematic
          },
          steadiness: {
            even_tempered: scSteadinessEvenTempered,
            accommodating: scSteadinessAccommodating,
            patient: scSteadinessPatient,
            humble: scSteadinessHumble,
            tactful: scSteadinessTactful
          }
        },

        personalityTest: {
          attempted: personalityTestCheck,
          result: personalityTestResult,
          result_field: personalityTestResultField
        },

        healthCondition: {
          na: healthConditionNA,
          adhd: healthConditionADHD,
          autism: healthConditionAutism,
          others: healthConditionOthersBox,
          others_type: healthConditionOthersField
        },

        preferredTeacher: {
          student: {
            male: preferredTeacherStudentPerspectiveMale,
            female: preferredTeacherStudentPerspectiveFemale,
            name: preferredTeacherStudentPerspectiveNameBox,
            name_type: preferredTeacherStudentPerspectiveNameField,
            authority: preferredTeacherStudentPerspectiveAuthority,
            delegator: preferredTeacherStudentPerspectiveDelegator,
            facilitator: preferredTeacherStudentPerspectiveFacilitator,
            demonstrator: preferredTeacherStudentPerspectiveDemonstrator,
            hybrid: preferredTeacherStudentPerspectiveHybrid,
            others: preferredTeacherStudentPerspectiveOthersBox,
            others_type: preferredTeacherStudentPerspectiveOthersField
          },
          parent: {
            male: preferredTeacherParentPerspectiveMale,
            female: preferredTeacherParentPerspectiveFemale,
            name: preferredTeacherParentPerspectiveNameBox,
            name_type: preferredTeacherParentPerspectiveNameField,
            authority: preferredTeacherParentPerspectiveAuthority,
            delegator: preferredTeacherParentPerspectiveDelegator,
            facilitator: preferredTeacherParentPerspectiveFacilitator,
            demonstrator: preferredTeacherParentPerspectiveDemonstrator,
            hybrid: preferredTeacherParentPerspectiveHybrid,
            others: preferredTeacherParentPerspectiveOthersBox,
            others_type: preferredTeacherParentPerspectiveOthersField
          }
        },

        interestedTopics: {
          public_speaking: interestedTopicsPublicSpeaking,
          debate: interestedTopicsDebate,
          coding: interestedTopicsCoding,
          writing: interestedTopicsWriting,
          leadership: interestedTopicsLeadership,
          creative_writing: interestedTopicsCreativeWriting,
          formal_writing: interestedTopicsFormalWriting,
          reading_comprehension: interestedTopicsReadingComprehension,
          literature: interestedTopicsLiterature,
          others: interestedTopicsOthersBox,
          others_type: interestedTopicsOthersField
        },

        parent: {
          _type: 'Parent',
          salute: parentSalute,
          name: parentName,
          // child: ,
          child_email: email,
          child_name: name,
          email: parentEmail,
          gender: parentGender,
          dob: parentDOB,
          phone_country_code: parentPhoneCountryCode,
          phone: parentPhone,
          contact_preference: {
            contact: parentContactPreference,
            others_type: parentContactPreferenceOther
          },
          facebook: parentFacebook,
          line: parentLine,
          meta: {
            occupation: {
              area: parentOccupation,
              specific: parentOccupationSpecific
            },
            family_background: parentFamilyBackground,
            networking_circle: {
              fb: parentNetworkingFB,
              website: parentNetworkingWebsite,
              friends: parentNetworkingFriends,
              others: parentNetworkingOthersBox,
              others_type: parentNetworkingOthersField
            },
            interested_topics: {
              public_speaking: parentInterestedTopicsPublicSpeaking,
              debate: parentInterestedTopicsDebate,
              coding: parentInterestedTopicsCoding,
              writing: parentInterestedTopicsWriting,
              leadership: parentInterestedTopicsLeadership,
              creative_writing: parentInterestedTopicsCreativeWriting,
              formal_writing: parentInterestedTopicsFormalWriting,
              reading_comprehension: parentInterestedTopicsReadingComprehension,
              literature: parentInterestedTopicsLiterature,
              others: parentInterestedTopicsOthersBox,
              others_type: parentInterestedTopicsOthersField
            },
            conflict: parentConflict,
            decision_maker: {
              father: parentDecisionFather,
              mother: parentDecisionMother
            },
            parent_character: {
              dominance: {
                direct: pcDominanceDirect,
                result_oriented: pcDominanceResultsOriented,
                firm: pcDominanceFirm,
                strong_willed: pcDominanceStrongWilled,
                forceful: pcDominanceForceful
              },
              influence: {
                outgoing: pcInfluenceOutgoing,
                enthusiastic: pcInfluenceEnthusiastic,
                optimistic: pcInfluenceOptimistic,
                high_spirited: pcInfluenceHighSpirited,
                lively: pcInfluenceLively
              },
              conscientiousness: {
                analytical: pcConscientiousnessAnalytical,
                reserved: pcConscientiousnessReserved,
                precise: pcConscientiousnessPrecise,
                private: pcConscientiousnessPrivate,
                systematic: pcConscientiousnessSystematic
              },
              steadiness: {
                even_tempered: pcSteadinessEvenTempered,
                accommodating: pcSteadinessAccommodating,
                patient: pcSteadinessPatient,
                humble: pcSteadinessHumble,
                tactful: pcSteadinessTactful
              }
            },
            payment_mode: paymentMode,
            date_of_renewal: parentDateOfRenewal
          }
        }
      }
      //   this.props.updateUser(details)
      this.props.updateStudentProfileFromParent(this.props.currentUser.child, details)
    }
  }

  render() {
    const { updateUserInProgress, meta, child } = this.props
    const {
      email,
      name,
      dateOfBirth,
      gender,
      phoneCountryCode,
      phone,
      level,
      othersLevel,
      showOthersLevel,

      formError,
      parentEmail,
      useStudentEmail,
      parentSalute,
      parentName,
      parentPhoneCountryCode,
      parentPhone,
      useStudentPhone,
      contactPreference,
      contactPreferenceOther,
      facebook,
      line,
      isSaved,

      gradeLevel,
      yearLevel,

      school,
      othersSchool,
      showOthersSchool,
      typeOfSchool,
      othersTypeOfSchool,
      showOthersTypeOfSchool,
      studyingAtOtherSchool,
      studyingAtOtherSchoolName,
      studyMediumEng,
      studyMediumThai,

      gpaEngBox,
      gpaThaiBox,
      gpaMathsBox,
      gpaPhysBox,
      gpaChemBox,
      gpaBioBox,
      gpaGeoBox,
      gpaHistBox,
      gpaLitBox,
      gpaSSBox,
      gpaPolBox,
      gpaEcoBox,
      gpaArtBox,
      gpaFLBox,
      gpaOthersBox,
      gpaEng,
      gpaThai,
      gpaMaths,
      gpaPhys,
      gpaChem,
      gpaBio,
      gpaGeo,
      gpaHist,
      gpaLit,
      gpaSS,
      gpaPol,
      gpaEco,
      gpaArt,
      gpaFL,
      gpaOthersField,
      favSubjEngBox,
      favSubjThaiBox,
      favSubjMathsBox,
      favSubjPhysBox,
      favSubjChemBox,
      favSubjBioBox,
      favSubjGeoBox,
      favSubjHistBox,
      favSubjLitBox,
      favSubjSSBox,
      favSubjPolBox,
      favSubjEcoBox,
      favSubjArtBox,
      favSubjFLBox,
      favSubjOthersBox,
      favSubjOthersBoxField,
      weakestSubjEngBox,
      weakestSubjThaiBox,
      weakestSubjMathsBox,
      weakestSubjPhysBox,
      weakestSubjChemBox,
      weakestSubjBioBox,
      weakestSubjGeoBox,
      weakestSubjHistBox,
      weakestSubjLitBox,
      weakestSubjSSBox,
      weakestSubjPolBox,
      weakestSubjEcoBox,
      weakestSubjArtBox,
      weakestSubjFLBox,
      weakestSubjOthersBox,
      weakestSubjOthersBoxField,
      extExamSAT,
      extExamGRE,
      extExamIELTS,
      extExamTOEFL,
      extExamGMAT,
      extExamOthers,
      extExamOthersField,
      otherTCcourseSAT,
      otherTCcourseGRE,
      otherTCcourseIELTS,
      otherTCcourseTOEFL,
      otherTCcourseGMAT,
      otherTCcourseEnglish,
      otherTCcourseThai,
      otherTCcourseMath,
      otherTCcoursePhysics,
      otherTCcourseBiology,
      otherTCcourseChemistry,
      otherTCcourseGeography,
      otherTCcourseHistory,
      otherTCcourseLiterature,
      otherTCcourseSocialStudies,
      otherTCcoursePolitics,
      otherTCcourseEconomics,
      otherTCcourseArt,
      otherTCcourseForeignLanguage,
      otherTCcourseOthers,
      otherTCcourseOthersField,
      studyingAtEPA,
      coursesTakenAtEpaSAT,
      coursesTakenAtEpaGRE,
      coursesTakenAtEpaIELTS,
      coursesTakenAtEpaTOEFL,
      coursesTakenAtEpaGMAT,
      coursesTakenAtEpaEnglish,
      coursesTakenAtEpaThai,
      coursesTakenAtEpaMath,
      coursesTakenAtEpaPhysics,
      coursesTakenAtEpaBiology,
      coursesTakenAtEpaGeography,
      coursesTakenAtEpaChemistry,
      coursesTakenAtEpaHistory,
      coursesTakenAtEpaLiterature,
      coursesTakenAtEpaSocialStudies,
      coursesTakenAtEpaPolitics,
      coursesTakenAtEpaEconomics,
      coursesTakenAtEpaArt,
      coursesTakenAtEpaForeignLanguage,
      coursesTakenAtEpaOthers,
      coursesTakenAtEpaOthersField,

      careerGoalsStudentPerspective,
      careerGoalsParentPerspective,

      areaOfStudy,
      areaOfStudySpecific,
      areaOfStudy1,
      areaOfStudySpecific1,
      areaOfStudy2,
      areaOfStudySpecific2,
      areaOfStudy3,
      areaOfStudySpecific3,
      areaOfStudy4,
      areaOfStudySpecific4,
      areaOfStudyOthers,
      areaOfStudyOthersField,
      areaOfStudyUnsure,

      areaOfStudyParent,
      areaOfStudyParentSpecific,
      areaOfStudyParent1,
      areaOfStudyParentSpecific1,
      areaOfStudyParent2,
      areaOfStudyParentSpecific2,
      areaOfStudyParent3,
      areaOfStudyParentSpecific3,
      areaOfStudyParent4,
      areaOfStudyParentSpecific4,
      areaOfStudyParentOthers,
      areaOfStudyParentOthersField,
      areaOfStudyParentUnsure,

      specialRequests,

      studyGoalsStudentPerspectiveEntranceExamBox,
      studyGoalsStudentPerspectiveEntranceExamField,
      studyGoalsStudentPerspectiveSchoolExamBox,
      studyGoalsStudentPerspectiveSATBox,
      studyGoalsStudentPerspectiveGREBox,
      studyGoalsStudentPerspectiveIELTSBox,
      studyGoalsStudentPerspectiveTOEFLBox,
      studyGoalsStudentPerspectiveGMATBox,
      studyGoalsStudentPerspectiveOthersBox,
      studyGoalsStudentPerspectiveOthersField,

      studyGoalsParentPerspectiveEntranceExamBox,
      studyGoalsParentPerspectiveEntranceExamField,
      studyGoalsParentPerspectiveSchoolExamBox,
      studyGoalsParentPerspectiveSATBox,
      studyGoalsParentPerspectiveGREBox,
      studyGoalsParentPerspectiveIELTSBox,
      studyGoalsParentPerspectiveTOEFLBox,
      studyGoalsParentPerspectiveGMATBox,
      studyGoalsParentPerspectiveOthersBox,
      studyGoalsParentPerspectiveOthersField,

      targetCountryStudentUS,
      targetCountryStudentCanada,
      targetCountryStudentUK,
      targetCountryStudentAustralia,
      targetCountryStudentNewZealand,
      targetCountryStudentOthersBox,
      targetCountryStudentOthersField,

      targetCountryParentUS,
      targetCountryParentCanada,
      targetCountryParentUK,
      targetCountryParentAustralia,
      targetCountryParentNewZealand,
      targetCountryParentOthersBox,
      targetCountryParentOthersField,

      learningTypeVisual,
      learningTypeKinaesthetic,
      learningTypeAural,
      learningTypeSocial,
      learningTypeSolitary,
      learningTypeVerbal,
      learningTypeLogical,
      learningTypeUnsure,
      learningTypeOthersBox,
      learningTypeOthersField,

      personalityTestCheck,
      personalityTestResult,
      personalityTestResultField,

      scDominanceDirect,
      scDominanceResultsOriented,
      scDominanceFirm,
      scDominanceStrongWilled,
      scDominanceForceful,
      scInfluenceOutgoing,
      scInfluenceEnthusiastic,
      scInfluenceOptimistic,
      scInfluenceHighSpirited,
      scInfluenceLively,
      scConscientiousnessAnalytical,
      scConscientiousnessReserved,
      scConscientiousnessPrecise,
      scConscientiousnessPrivate,
      scConscientiousnessSystematic,
      scSteadinessEvenTempered,
      scSteadinessAccommodating,
      scSteadinessPatient,
      scSteadinessHumble,
      scSteadinessTactful,

      healthConditionNA,
      healthConditionADHD,
      healthConditionAutism,
      healthConditionOthersBox,
      healthConditionOthersField,

      preferredTeacherStudentPerspectiveMale,
      preferredTeacherStudentPerspectiveFemale,
      preferredTeacherStudentPerspectiveNameBox,
      preferredTeacherStudentPerspectiveNameField,
      preferredTeacherStudentPerspectiveAuthority,
      preferredTeacherStudentPerspectiveDelegator,
      preferredTeacherStudentPerspectiveFacilitator,
      preferredTeacherStudentPerspectiveDemonstrator,
      preferredTeacherStudentPerspectiveHybrid,
      preferredTeacherStudentPerspectiveOthersBox,
      preferredTeacherStudentPerspectiveOthersField,

      preferredTeacherParentPerspectiveMale,
      preferredTeacherParentPerspectiveFemale,
      preferredTeacherParentPerspectiveNameBox,
      preferredTeacherParentPerspectiveNameField,
      preferredTeacherParentPerspectiveAuthority,
      preferredTeacherParentPerspectiveDelegator,
      preferredTeacherParentPerspectiveFacilitator,
      preferredTeacherParentPerspectiveDemonstrator,
      preferredTeacherParentPerspectiveHybrid,
      preferredTeacherParentPerspectiveOthersBox,
      preferredTeacherParentPerspectiveOthersField,

      interestedTopicsPublicSpeaking,
      interestedTopicsDebate,
      interestedTopicsCoding,
      interestedTopicsLeadership,
      interestedTopicsWriting,
      interestedTopicsCreativeWriting,
      interestedTopicsFormalWriting,
      interestedTopicsReadingComprehension,
      interestedTopicsLiterature,
      interestedTopicsOthersBox,
      interestedTopicsOthersField
    } = this.state

    return (
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <Paper elevation={2} className="app-wrapper">
          <h2 className="mb-0">Update Details</h2>
        </Paper>
        <div className="app-wrapper">
          <div className="container">
            <StepperProfile
              handleChange={this.handleChange}
              validateFormElement={this.validateFormElement}
              onFocusFormElement={this.onFocusFormElement}
              submitChanges={this.submitChanges}
              currentUser={this.props.currentUser}
              email={email}
              name={name}
              dateOfBirth={dateOfBirth}
              gender={gender}
              phoneCountryCode={phoneCountryCode}
              phone={phone}
              level={level}
              othersLevel={othersLevel}
              showOthersLevel={showOthersLevel}
              school={school}
              othersSchool={othersSchool}
              showOthersSchool={showOthersSchool}
              typeOfSchool={typeOfSchool}
              othersTypeOfSchool={othersTypeOfSchool}
              showOthersTypeOfSchool={showOthersTypeOfSchool}
              studyMediumEng={studyMediumEng}
              studyMediumThai={studyMediumThai}
              studyingAtOtherSchool={studyingAtOtherSchool}
              studyingAtOtherSchoolName={studyingAtOtherSchoolName}
              formError={formError}
              parentEmail={parentEmail}
              useStudentEmail={useStudentEmail}
              parentSalute={parentSalute}
              parentName={parentName}
              parentPhoneCountryCode={parentPhoneCountryCode}
              parentPhone={parentPhone}
              useStudentPhone={useStudentPhone}
              contactPreference={contactPreference}
              contactPreferenceOther={contactPreferenceOther}
              facebook={facebook}
              line={line}
              isSaved={isSaved}
              handleClose={this.handleClose}
              meta={meta}
              handleCheckboxChange={this.handleCheckboxChange}
              handleAutoCompleteChange={this.handleAutoCompleteChange}
              handleBlurInput={this.handleBlurInput}
              gradeLevel={gradeLevel}
              yearLevel={yearLevel}
              gpaEngBox={gpaEngBox}
              gpaThaiBox={gpaThaiBox}
              gpaMathsBox={gpaMathsBox}
              gpaPhysBox={gpaPhysBox}
              gpaChemBox={gpaChemBox}
              gpaBioBox={gpaBioBox}
              gpaGeoBox={gpaGeoBox}
              gpaHistBox={gpaHistBox}
              gpaLitBox={gpaLitBox}
              gpaSSBox={gpaSSBox}
              gpaPolBox={gpaPolBox}
              gpaEcoBox={gpaEcoBox}
              gpaArtBox={gpaArtBox}
              gpaFLBox={gpaFLBox}
              gpaOthersBox={gpaOthersBox}
              gpaEng={gpaEng}
              gpaThai={gpaThai}
              gpaMaths={gpaMaths}
              gpaPhys={gpaPhys}
              gpaChem={gpaChem}
              gpaBio={gpaBio}
              gpaGeo={gpaGeo}
              gpaHist={gpaHist}
              gpaLit={gpaLit}
              gpaSS={gpaSS}
              gpaPol={gpaPol}
              gpaEco={gpaEco}
              gpaArt={gpaArt}
              gpaFL={gpaFL}
              gpaOthersField={gpaOthersField}
              favSubjEngBox={favSubjEngBox}
              favSubjThaiBox={favSubjThaiBox}
              favSubjMathsBox={favSubjMathsBox}
              favSubjPhysBox={favSubjPhysBox}
              favSubjChemBox={favSubjChemBox}
              favSubjBioBox={favSubjBioBox}
              favSubjGeoBox={favSubjGeoBox}
              favSubjHistBox={favSubjHistBox}
              favSubjLitBox={favSubjLitBox}
              favSubjSSBox={favSubjSSBox}
              favSubjPolBox={favSubjPolBox}
              favSubjEcoBox={favSubjEcoBox}
              favSubjArtBox={favSubjArtBox}
              favSubjFLBox={favSubjFLBox}
              favSubjOthersBox={favSubjOthersBox}
              favSubjOthersBoxField={favSubjOthersBoxField}
              weakestSubjEngBox={weakestSubjEngBox}
              weakestSubjThaiBox={weakestSubjThaiBox}
              weakestSubjMathsBox={weakestSubjMathsBox}
              weakestSubjPhysBox={weakestSubjPhysBox}
              weakestSubjChemBox={weakestSubjChemBox}
              weakestSubjBioBox={weakestSubjBioBox}
              weakestSubjGeoBox={weakestSubjGeoBox}
              weakestSubjHistBox={weakestSubjHistBox}
              weakestSubjLitBox={weakestSubjLitBox}
              weakestSubjSSBox={weakestSubjSSBox}
              weakestSubjPolBox={weakestSubjPolBox}
              weakestSubjEcoBox={weakestSubjEcoBox}
              weakestSubjArtBox={weakestSubjArtBox}
              weakestSubjFLBox={weakestSubjFLBox}
              weakestSubjOthersBox={weakestSubjOthersBox}
              weakestSubjOthersBoxField={weakestSubjOthersBoxField}
              extExamSAT={extExamSAT}
              extExamGRE={extExamGRE}
              extExamIELTS={extExamIELTS}
              extExamTOEFL={extExamTOEFL}
              extExamGMAT={extExamGMAT}
              extExamOthers={extExamOthers}
              extExamOthersField={extExamOthersField}
              otherTCcourseSAT={otherTCcourseSAT}
              otherTCcourseGRE={otherTCcourseGRE}
              otherTCcourseIELTS={otherTCcourseIELTS}
              otherTCcourseTOEFL={otherTCcourseTOEFL}
              otherTCcourseGMAT={otherTCcourseGMAT}
              otherTCcourseEnglish={otherTCcourseEnglish}
              otherTCcourseThai={otherTCcourseThai}
              otherTCcourseMath={otherTCcourseMath}
              otherTCcoursePhysics={otherTCcoursePhysics}
              otherTCcourseBiology={otherTCcourseBiology}
              otherTCcourseChemistry={otherTCcourseChemistry}
              otherTCcourseGeography={otherTCcourseGeography}
              otherTCcourseHistory={otherTCcourseHistory}
              otherTCcourseLiterature={otherTCcourseLiterature}
              otherTCcourseSocialStudies={otherTCcourseSocialStudies}
              otherTCcoursePolitics={otherTCcoursePolitics}
              otherTCcourseEconomics={otherTCcourseEconomics}
              otherTCcourseArt={otherTCcourseArt}
              otherTCcourseForeignLanguage={otherTCcourseForeignLanguage}
              otherTCcourseOthers={otherTCcourseOthers}
              otherTCcourseOthersField={otherTCcourseOthersField}
              studyingAtEPA={studyingAtEPA}
              coursesTakenAtEpaSAT={coursesTakenAtEpaSAT}
              coursesTakenAtEpaGRE={coursesTakenAtEpaGRE}
              coursesTakenAtEpaIELTS={coursesTakenAtEpaIELTS}
              coursesTakenAtEpaTOEFL={coursesTakenAtEpaTOEFL}
              coursesTakenAtEpaGMAT={coursesTakenAtEpaGMAT}
              coursesTakenAtEpaEnglish={coursesTakenAtEpaEnglish}
              coursesTakenAtEpaThai={coursesTakenAtEpaThai}
              coursesTakenAtEpaMath={coursesTakenAtEpaMath}
              coursesTakenAtEpaPhysics={coursesTakenAtEpaPhysics}
              coursesTakenAtEpaBiology={coursesTakenAtEpaBiology}
              coursesTakenAtEpaGeography={coursesTakenAtEpaGeography}
              coursesTakenAtEpaChemistry={coursesTakenAtEpaChemistry}
              coursesTakenAtEpaHistory={coursesTakenAtEpaHistory}
              coursesTakenAtEpaLiterature={coursesTakenAtEpaLiterature}
              coursesTakenAtEpaSocialStudies={coursesTakenAtEpaSocialStudies}
              coursesTakenAtEpaPolitics={coursesTakenAtEpaPolitics}
              coursesTakenAtEpaEconomics={coursesTakenAtEpaEconomics}
              coursesTakenAtEpaArt={coursesTakenAtEpaArt}
              coursesTakenAtEpaForeignLanguage={coursesTakenAtEpaForeignLanguage}
              coursesTakenAtEpaOthers={coursesTakenAtEpaOthers}
              coursesTakenAtEpaOthersField={coursesTakenAtEpaOthersField}
              handleAutoCompleteStudyAreaChange={this.handleAutoCompleteStudyAreaChange}
              handleAutoCompleteStudyAreaSpecificChange={this.handleAutoCompleteStudyAreaSpecificChange}
              handleAutoCompleteStudyAreaChange1={this.handleAutoCompleteStudyAreaChange1}
              handleAutoCompleteStudyAreaSpecificChange1={this.handleAutoCompleteStudyAreaSpecificChange1}
              handleAutoCompleteStudyAreaChange2={this.handleAutoCompleteStudyAreaChange2}
              handleAutoCompleteStudyAreaSpecificChange2={this.handleAutoCompleteStudyAreaSpecificChange2}
              handleAutoCompleteStudyAreaChange3={this.handleAutoCompleteStudyAreaChange3}
              handleAutoCompleteStudyAreaSpecificChange3={this.handleAutoCompleteStudyAreaSpecificChange3}
              handleAutoCompleteStudyAreaChange4={this.handleAutoCompleteStudyAreaChange4}
              handleAutoCompleteStudyAreaSpecificChange4={this.handleAutoCompleteStudyAreaSpecificChange4}
              handleAutoCompleteParentStudyAreaChange={this.handleAutoCompleteParentStudyAreaChange}
              handleAutoCompleteParentStudyAreaSpecificChange={this.handleAutoCompleteParentStudyAreaSpecificChange}
              handleAutoCompleteParentStudyAreaChange1={this.handleAutoCompleteParentStudyAreaChange1}
              handleAutoCompleteParentStudyAreaSpecificChange1={this.handleAutoCompleteParentStudyAreaSpecificChange1}
              handleAutoCompleteParentStudyAreaChange2={this.handleAutoCompleteParentStudyAreaChange2}
              handleAutoCompleteParentStudyAreaSpecificChange2={this.handleAutoCompleteParentStudyAreaSpecificChange2}
              handleAutoCompleteParentStudyAreaChange3={this.handleAutoCompleteParentStudyAreaChange3}
              handleAutoCompleteParentStudyAreaSpecificChange3={this.handleAutoCompleteParentStudyAreaSpecificChange3}
              handleAutoCompleteParentStudyAreaChange4={this.handleAutoCompleteParentStudyAreaChange4}
              handleAutoCompleteParentStudyAreaSpecificChange4={this.handleAutoCompleteParentStudyAreaSpecificChange4}
              careerGoalsStudentPerspective={careerGoalsStudentPerspective}
              careerGoalsParentPerspective={careerGoalsParentPerspective}
              areaOfStudy={areaOfStudy}
              areaOfStudySpecific={areaOfStudySpecific}
              areaOfStudy1={areaOfStudy1}
              areaOfStudySpecific1={areaOfStudySpecific1}
              areaOfStudy2={areaOfStudy2}
              areaOfStudySpecific2={areaOfStudySpecific2}
              areaOfStudy3={areaOfStudy3}
              areaOfStudySpecific3={areaOfStudySpecific3}
              areaOfStudy4={areaOfStudy4}
              areaOfStudySpecific4={areaOfStudySpecific4}
              areaOfStudyOthers={areaOfStudyOthers}
              areaOfStudyOthersField={areaOfStudyOthersField}
              areaOfStudyUnsure={areaOfStudyUnsure}
              areaOfStudyParent={areaOfStudyParent}
              areaOfStudyParentSpecific={areaOfStudyParentSpecific}
              areaOfStudyParent1={areaOfStudyParent1}
              areaOfStudyParentSpecific1={areaOfStudyParentSpecific1}
              areaOfStudyParent2={areaOfStudyParent2}
              areaOfStudyParentSpecific2={areaOfStudyParentSpecific2}
              areaOfStudyParent3={areaOfStudyParent3}
              areaOfStudyParentSpecific3={areaOfStudyParentSpecific3}
              areaOfStudyParent4={areaOfStudyParent4}
              areaOfStudyParentSpecific4={areaOfStudyParentSpecific4}
              areaOfStudyParentOthers={areaOfStudyParentOthers}
              areaOfStudyParentOthersField={areaOfStudyParentOthersField}
              areaOfStudyParentUnsure={areaOfStudyParentUnsure}
              studyGoalsStudentPerspectiveEntranceExamBox={studyGoalsStudentPerspectiveEntranceExamBox}
              studyGoalsStudentPerspectiveEntranceExamField={studyGoalsStudentPerspectiveEntranceExamField}
              studyGoalsStudentPerspectiveSchoolExamBox={studyGoalsStudentPerspectiveSchoolExamBox}
              studyGoalsStudentPerspectiveSATBox={studyGoalsStudentPerspectiveSATBox}
              studyGoalsStudentPerspectiveGREBox={studyGoalsStudentPerspectiveGREBox}
              studyGoalsStudentPerspectiveIELTSBox={studyGoalsStudentPerspectiveIELTSBox}
              studyGoalsStudentPerspectiveTOEFLBox={studyGoalsStudentPerspectiveTOEFLBox}
              studyGoalsStudentPerspectiveGMATBox={studyGoalsStudentPerspectiveGMATBox}
              studyGoalsStudentPerspectiveOthersBox={studyGoalsStudentPerspectiveOthersBox}
              studyGoalsStudentPerspectiveOthersField={studyGoalsStudentPerspectiveOthersField}
              studyGoalsParentPerspectiveEntranceExamBox={studyGoalsParentPerspectiveEntranceExamBox}
              studyGoalsParentPerspectiveEntranceExamField={studyGoalsParentPerspectiveEntranceExamField}
              studyGoalsParentPerspectiveSchoolExamBox={studyGoalsParentPerspectiveSchoolExamBox}
              studyGoalsParentPerspectiveSATBox={studyGoalsParentPerspectiveSATBox}
              studyGoalsParentPerspectiveGREBox={studyGoalsParentPerspectiveGREBox}
              studyGoalsParentPerspectiveIELTSBox={studyGoalsParentPerspectiveIELTSBox}
              studyGoalsParentPerspectiveTOEFLBox={studyGoalsParentPerspectiveTOEFLBox}
              studyGoalsParentPerspectiveGMATBox={studyGoalsParentPerspectiveGMATBox}
              studyGoalsParentPerspectiveOthersBox={studyGoalsParentPerspectiveOthersBox}
              studyGoalsParentPerspectiveOthersField={studyGoalsParentPerspectiveOthersField}
              targetCountryStudentUS={targetCountryStudentUS}
              targetCountryStudentCanada={targetCountryStudentCanada}
              targetCountryStudentUK={targetCountryStudentUK}
              targetCountryStudentAustralia={targetCountryStudentAustralia}
              targetCountryStudentNewZealand={targetCountryStudentNewZealand}
              targetCountryStudentOthersBox={targetCountryStudentOthersBox}
              targetCountryStudentOthersField={targetCountryStudentOthersField}
              targetCountryParentUS={targetCountryParentUS}
              targetCountryParentCanada={targetCountryParentCanada}
              targetCountryParentUK={targetCountryParentUK}
              targetCountryParentAustralia={targetCountryParentAustralia}
              targetCountryParentNewZealand={targetCountryParentNewZealand}
              targetCountryParentOthersBox={targetCountryParentOthersBox}
              targetCountryParentOthersField={targetCountryParentOthersField}
              specialRequests={specialRequests}
              learningTypeVisual={learningTypeVisual}
              learningTypeKinaesthetic={learningTypeKinaesthetic}
              learningTypeAural={learningTypeAural}
              learningTypeSocial={learningTypeSocial}
              learningTypeSolitary={learningTypeSolitary}
              learningTypeVerbal={learningTypeVerbal}
              learningTypeLogical={learningTypeLogical}
              learningTypeUnsure={learningTypeUnsure}
              learningTypeOthersBox={learningTypeOthersBox}
              learningTypeOthersField={learningTypeOthersField}
              personalityTestCheck={personalityTestCheck}
              personalityTestResult={personalityTestResult}
              personalityTestResultField={personalityTestResultField}
              scDominanceDirect={scDominanceDirect}
              scDominanceResultsOriented={scDominanceResultsOriented}
              scDominanceFirm={scDominanceFirm}
              scDominanceStrongWilled={scDominanceStrongWilled}
              scDominanceForceful={scDominanceForceful}
              scInfluenceOutgoing={scInfluenceOutgoing}
              scInfluenceEnthusiastic={scInfluenceEnthusiastic}
              scInfluenceOptimistic={scInfluenceOptimistic}
              scInfluenceHighSpirited={scInfluenceHighSpirited}
              scInfluenceLively={scInfluenceLively}
              scConscientiousnessAnalytical={scConscientiousnessAnalytical}
              scConscientiousnessReserved={scConscientiousnessReserved}
              scConscientiousnessPrecise={scConscientiousnessPrecise}
              scConscientiousnessPrivate={scConscientiousnessPrivate}
              scConscientiousnessSystematic={scConscientiousnessSystematic}
              scSteadinessEvenTempered={scSteadinessEvenTempered}
              scSteadinessAccommodating={scSteadinessAccommodating}
              scSteadinessPatient={scSteadinessPatient}
              scSteadinessHumble={scSteadinessHumble}
              scSteadinessTactful={scSteadinessTactful}
              healthConditionNA={healthConditionNA}
              healthConditionADHD={healthConditionADHD}
              healthConditionAutism={healthConditionAutism}
              healthConditionOthersBox={healthConditionOthersBox}
              healthConditionOthersField={healthConditionOthersField}
              preferredTeacherStudentPerspectiveMale={preferredTeacherStudentPerspectiveMale}
              preferredTeacherStudentPerspectiveFemale={preferredTeacherStudentPerspectiveFemale}
              preferredTeacherStudentPerspectiveNameBox={preferredTeacherStudentPerspectiveNameBox}
              preferredTeacherStudentPerspectiveNameField={preferredTeacherStudentPerspectiveNameField}
              preferredTeacherStudentPerspectiveAuthority={preferredTeacherStudentPerspectiveAuthority}
              preferredTeacherStudentPerspectiveDelegator={preferredTeacherStudentPerspectiveDelegator}
              preferredTeacherStudentPerspectiveFacilitator={preferredTeacherStudentPerspectiveFacilitator}
              preferredTeacherStudentPerspectiveDemonstrator={preferredTeacherStudentPerspectiveDemonstrator}
              preferredTeacherStudentPerspectiveHybrid={preferredTeacherStudentPerspectiveHybrid}
              preferredTeacherStudentPerspectiveOthersBox={preferredTeacherStudentPerspectiveOthersBox}
              preferredTeacherStudentPerspectiveOthersField={preferredTeacherStudentPerspectiveOthersField}
              preferredTeacherParentPerspectiveMale={preferredTeacherParentPerspectiveMale}
              preferredTeacherParentPerspectiveFemale={preferredTeacherParentPerspectiveFemale}
              preferredTeacherParentPerspectiveNameBox={preferredTeacherParentPerspectiveNameBox}
              preferredTeacherParentPerspectiveNameField={preferredTeacherParentPerspectiveNameField}
              preferredTeacherParentPerspectiveAuthority={preferredTeacherParentPerspectiveAuthority}
              preferredTeacherParentPerspectiveDelegator={preferredTeacherParentPerspectiveDelegator}
              preferredTeacherParentPerspectiveFacilitator={preferredTeacherParentPerspectiveFacilitator}
              preferredTeacherParentPerspectiveDemonstrator={preferredTeacherParentPerspectiveDemonstrator}
              preferredTeacherParentPerspectiveHybrid={preferredTeacherParentPerspectiveHybrid}
              preferredTeacherParentPerspectiveOthersBox={preferredTeacherParentPerspectiveOthersBox}
              preferredTeacherParentPerspectiveOthersField={preferredTeacherParentPerspectiveOthersField}
              interestedTopicsPublicSpeaking={interestedTopicsPublicSpeaking}
              interestedTopicsDebate={interestedTopicsDebate}
              interestedTopicsCoding={interestedTopicsCoding}
              interestedTopicsLeadership={interestedTopicsLeadership}
              interestedTopicsWriting={interestedTopicsWriting}
              interestedTopicsCreativeWriting={interestedTopicsCreativeWriting}
              interestedTopicsFormalWriting={interestedTopicsFormalWriting}
              interestedTopicsReadingComprehension={interestedTopicsReadingComprehension}
              interestedTopicsLiterature={interestedTopicsLiterature}
              interestedTopicsOthersBox={interestedTopicsOthersBox}
              interestedTopicsOthersField={interestedTopicsOthersField}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Edit))
