export const CLASS_TYPE_WORKSHOP = 'Workshop'
export const CLASS_TYPE_REGULAR_CLASSES = 'Regular Classes'
export const TIMEZONE_ABBR = {
  '-0700': 'PST'
}

export const subjectColors = {
  Mathematics: '#F44336',
  Science: '#00b96c',
  other: '#2196F3'
}
