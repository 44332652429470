import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip
} from '@material-ui/core'
import _ from 'lodash'
import Proptypes from 'prop-types'
import agent from 'agent'
import { GET_LESSON_RESOURCES_2, UPLOAD_RESOURCE } from 'constants/actionTypes'
import { validateTextEntry } from 'utils/formValidation'
import { STUDENT } from 'constants/userTypes'
const mapStateToProps = state => ({
  uploading: state.resources.uploading,
  uploadSuccess: state.resources.uploadSuccess,
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  //uploadFile: formData => dispatch({ type: UPLOAD_RESOURCE, payload: agent.Lessons.uploadLessonResource(formData) }),
  uploadFile: (lessonId, reqPayload) =>
    dispatch({ type: UPLOAD_RESOURCE, payload: agent.Lessons.uploadMaterial(lessonId, reqPayload) }),
  getLessonResource: lessonId =>
    dispatch({ type: GET_LESSON_RESOURCES_2, payload: agent.Lessons.getMaterial(lessonId), lessonId })
})

class UploadResourceDialog extends React.Component {
  state = {
    name: '',
    category: '',
    files: null,
    formError: {},
    addWatermark: true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({ files: null, file_preview: null, name: '', category: '', formError: {} })
    }

    if (prevProps.uploading && !this.props.uploading) {
      if (this.props.uploadSuccess) {
        const { lessonId } = this.props
        this.props.getLessonResource(lessonId)
      }
      this.props.close()
    }
  }

  addFile = e => {
    let file = e.target.files[0]
    let fileName = file.name || ''
    fileName = fileName.replace(/\.[^/.]+$/, '')

    this.setState({ files: file, name: fileName })
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  handleCheckboxChange = name => (event, checked) => {
    this.setState({ [name]: checked })
  }

  validateName = () => {
    let errorMsg = validateTextEntry(this.state.name, 'file name')
    let tempFormError = this.state.formError
    if (errorMsg) {
      tempFormError.name = errorMsg
    } else {
      delete tempFormError.name
    }
    this.setState({ formError: tempFormError })
  }

  uploadFile = () => {
    this.validateName()
    const { lessonId } = this.props
    const { files, name, category, addWatermark, formError } = this.state

    if (_.isEmpty(formError)) {
      let data = new FormData()
      data.append('resource', files)
      data.append('name', name)
      data.append('type', category)
      data.append('lesson', lessonId)
      data.append('skip_watermark', !addWatermark)
      this.props.uploadFile(lessonId, data)
    }
  }

  render() {
    const { isOpen, uploading, currentUser } = this.props
    const { files, name, category, addWatermark, formError } = this.state

    return (
      <Dialog open={isOpen} onClose={this.props.close}>
        <DialogTitle className="h2 font-weight-semibold">Upload a resource</DialogTitle>
        <DialogContent>
          <div className="form-group">
            <input type="file" className="form-control" onChange={this.addFile} accept={'.jpg,.jpeg,.png,.pdf,.pptx'} />
          </div>

          <TextField
            type="text"
            label="File Name / Description"
            name="name"
            onChange={this.handleChange}
            error={!!formError.name}
            helperText={formError.name}
            fullWidth
            value={name}
            margin="normal"
            className="mt-0 mb-2"
          />

          <FormControl component="fieldset" required>
            <FormLabel component="legend">Category</FormLabel>
            <RadioGroup aria-label="category" name="category" value={category} onChange={this.handleChange}>
              <FormControlLabel value="notes" control={<Radio color="primary" />} label="Notes" />
              <FormControlLabel value="optional" control={<Radio color="primary" />} label="Optional" />
              <FormControlLabel value="homework" control={<Radio color="primary" />} label="Homework" />
              {currentUser._type !== STUDENT && (
                <FormControlLabel
                  value="teaching"
                  control={<Radio color="primary" />}
                  label="Teaching Materials (Not visible to students)"
                />
              )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.close} color="primary">
            Close
          </Button>

          <Button
            variant="contained"
            onClick={this.uploadFile}
            color="primary"
            disabled={uploading || !name || !files || !category}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

UploadResourceDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  lessonId: Proptypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadResourceDialog)
