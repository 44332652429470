import React from 'react'
import Proptypes from 'prop-types'
import { CircularProgress, Dialog, Slide } from '@material-ui/core'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
// NOTE: this play dialog video only for video that is not protected, means not including lesson playback
const VideoPlayerDialog = props => {
  return (
    <Dialog fullWidth={true} maxWidth="lg" open={props.isOpen} onClose={props.close} TransitionComponent={Transition}>
      {props.videoURL ? (
        <video
          autoPlay
          className="w-100"
          controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
          controlsList={'nodownload'}>
          <source src={props.videoURL} type="video/mp4" />
        </video>
      ) : (
        <div className="p-5">
          <CircularProgress size={25} color="primary" />
        </div>
      )}
    </Dialog>
  )
}
VideoPlayerDialog.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  close: Proptypes.func.isRequired,
  videoURL: Proptypes.string
}
export default VideoPlayerDialog
