import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { LOGOUT } from 'constants/actionTypes'
import { USER_NAVIGATION_ACCESS, STUDENT, TUTOR, PARENT } from 'constants/userTypes'
import { APP, REST_OF_CLASSES, FAQ, USER_PROFILE } from 'routes/routes'
import { MY_PAID_CLASSES, MY_ARCHIVED_CLASSES } from 'routes/studentRoutes'
import { NavItem, NavSubMenu, NavSubmenuItem } from './menu-components'

const mapStateToProps = state => ({
  currentUser: state.common.currentUser
})

const mapDispatchToProps = dispatch => ({
  userSignOut: () => dispatch({ type: LOGOUT })
})

class SidenavContent extends Component {
  goToPage = location => () => {
    this.props.history.push(location)
  }

  render() {
    const { currentUser } = this.props
    const userType = currentUser?._type ?? ''
    const userAccess = userType ? USER_NAVIGATION_ACCESS[userType] : []

    return (
      <Scrollbars style={{ height: '100%' }}>
        <ul className="nav-menu">
          {userAccess.includes('my-class') && (
            <NavSubMenu
              title="My Classes"
              icon="zmdi zmdi-graduation-cap zmdi-hc-fw mr-2"
              keyword="my-class"
              location={this.props.location.pathname}
              goToPage={this.goToPage(`${APP}${MY_PAID_CLASSES}`)}>
              <NavSubmenuItem to={`${APP}${MY_PAID_CLASSES}`}>
                <span className="nav-text">Ongoing</span>
              </NavSubmenuItem>

              <NavSubmenuItem to={`${APP}${MY_ARCHIVED_CLASSES}`}>
                <span className="nav-text">All Classes</span>
              </NavSubmenuItem>
            </NavSubMenu>
          )}

          {userType === TUTOR && (
            <NavItem to={REST_OF_CLASSES}>
              <i className="zmdi zmdi-local-mall zmdi-hc-fw mr-2" />
              <span className="nav-text">Previous classes</span>
            </NavItem>
          )}

          {userType === PARENT && (
            <>
              <NavItem to={REST_OF_CLASSES}>
                <i className="zmdi zmdi-home zmdi-hc-fw mr-2" />
                <span className="nav-text">Child Class</span>
              </NavItem>

              <NavItem to={`${APP}`}>
                <i className="zmdi zmdi-home zmdi-hc-fw mr-2" />
                <span className="nav-text">Child Profile</span>
              </NavItem>
            </>
          )}

          <li className="nav-header">System</li>

          {userAccess.includes('profile') && (
            <NavItem to={USER_PROFILE}>
              <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
              <span className="nav-text">Profile</span>
            </NavItem>
          )}

          <NavItem
            to="#"
            onClick={() => {
              this.props.userSignOut()
            }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <span className="nav-text">Logout</span>
          </NavItem>
        </ul>
      </Scrollbars>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidenavContent))
