import React from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import Lightbox from 'react-images'
import { Avatar, Paper } from '@material-ui/core'

class AboutUs extends React.Component {
  state = {
    isLightBoxOpen: false,
    currentImageIndex: 0,
    isVideoDialogOpen: false
  }

  openLightbox = index => {
    this.setState({
      currentImageIndex: index,
      isLightBoxOpen: true
    })
  }

  closeLightbox = () => {
    this.setState({ isLightBoxOpen: false })
  }

  goToPrevious = () => {
    this.setState({ currentImageIndex: this.state.currentImageIndex - 1 })
  }

  goToNext = () => {
    this.setState({ currentImageIndex: this.state.currentImageIndex + 1 })
  }

  toggleVideoDialog = () => {
    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen
    })
  }

  render() {
    return (
      <div className="landing">
        <Helmet>
          <title>About Us | EPA</title>
          <meta
            name="description"
            content="Enabling students to unleash their potential while embracing the future with confidence and wisdom."
          />
          <meta
            name="keywords"
            content="Attaining Excellence, Unleashing your potential,Bringing out the best in you, Your dream, our mission 
            Nurturing uniqueness, Igniting your passion"
          />
        </Helmet>

        <section>
          <div className="container">
            <div className="align-items-center" style={{ marginBottom: '5em' }}>
              <div className="text-center">
                <div className="section__header_landing_title">
                  <div
                    className="title mt-5 text-center"
                    style={{
                      fontSize: '2.3rem',
                      fontWeight: '400',
                      fontFamily: "'Libre Baskerville', sans-serif",
                      color: '#000000'
                    }}>
                    Founder
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-12 col-md-4 justify-content-center">
                {isMobile ? (
                  <div className="col-12">
                    <div className="d-flex flex-row justify-content-center mb-3">
                      <Avatar
                        className="size-120 avatar-shadow mr-3"
                        alt="Kru A"
                        src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/image7c.jpg"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-auto align-self-end">
                    <img
                      src="https://epa-assets.s3.ap-southeast-1.amazonaws.com/landing/image7c.jpg"
                      alt="Kru A"
                      width="85%"
                    />
                  </div>
                )}
              </div>
              <div className="col-12 col-md-8">
                <div className="col align-self-end">
                  <p className="text-justify">
                    With over 25 years of teaching, mentoring, and advising tens of thousands of students in Thailand,
                    Kru A is recognized in Thailand's educational space as one of the most experienced educators with a
                    specialty in U.S. and U.K. higher education. With a highly customized teaching method to best suit
                    each student’s learning style and a detail-oriented approach, Kru A specializes in individual and
                    small-group classes in order to customise each student’s learning journey.{' '}
                  </p>
                  <p className="text-justify mb-2">
                    With over 2 decades of experience, Kru A has an unmatched ability to understand students deeply,
                    know how to push them to further improvement, help them build a strong foundation required for
                    future successes, and guide them towards their goals beyond just academics. Kru A believes education
                    is not simply about passing a test in the classroom, but in order to provide true education, we have
                    to constantly work with and guide students in the right direction, with patience and understanding
                    in order to build a strong foundation for life.{' '}
                  </p>
                  <p className="text-justify mb-2">
                    Kru A focuses on uncovering undiscovered talents and nurturing them as well as helping students
                    become the best versions of themselves. He dedicates himself to advising students beyond the
                    traditional classroom, from university life planning to career path discovery, across the entire
                    educational journey.
                  </p>
                  <p className="text-justify mb-2">
                    As a testament to Kru A’s dedication, Kru A has over 10,000 alumni from 13-40 years old, who became
                    successors of major business families in Thailand, are graduates of leading universities including
                    Ivy League and OxBridge, and others are successful working professionals in leading corporations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default AboutUs
